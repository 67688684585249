import './App.css';
import './hover.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Home from './Screens/Home';
import Privacy from './Screens/Privacy';
import Terms from './Screens/Terms';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    
  },
  {
    path: "privacy",
    element: <Privacy/>,
  },
  {
    path: "terms",
    element: <Terms/>,
  },
]);

function App() {

  return (
    <div>
      <RouterProvider router={router} />
    </div>
    // <Home />
  );
}

export default App;

