import validator from 'validator';


export var topProcessList = [
    {
        image: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-90.jpg',
        text: 'Your Wish'
    },
    {
        image: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-94.jpg',
        text: 'Chef Prepare'
    },
    {
        image: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-99.jpg',
        text: 'Your Dish'
    },
]
export var OrderFromOurKitchenSchedule = [
    {
        image: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-108.jpg',
        desc: 'Chef Prepares his/her schedule and upload the dishes and quantity for the next 5 days',
        next: true
    },
    {
        image: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-1122.jpg',
        desc: 'Chef publishes his schedule and shares the links and QR code with customers',
        next: true
    },
    {
        image: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-117.jpg',
        desc: 'Browse your dish from our Kitchen Schedule',
        next: true
    },
    {
        image: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-122.png',
        desc: 'User orders the food from the app and then it gets delivered.',
        next: false
    },
]

export var superChefList = [
    {
        chefImg: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-52.jpg',
        foodImage: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-62.jpg',
        name: 'chef1'
    },
    {
        chefImg: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-52.jpg',
        foodImage: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-62.jpg',
        name: 'chef1'
    },
    {
        chefImg: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-52.jpg',
        foodImage: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-62.jpg',
        name: 'chef1'
    },
    {
        chefImg: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-52.jpg',
        foodImage: 'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-62.jpg',
        name: 'chef1'
    },
    // {
    //     chefImg: 'https://plus.unsplash.com/premium_photo-1661778029158-e1a758c26bf7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjV8fGNoZWZ8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
    //     foodImage: 'https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
    //     name: 'chef1'
    // },
    // {
    //     chefImg: 'https://images.unsplash.com/photo-1583394293214-28ded15ee548?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGNoZWZ8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
    //     foodImage: 'https://images.unsplash.com/photo-1565958011703-44f9829ba187?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
    //     name: 'chef1'
    // },
]

export var cheflist = [
    {
        name: "Krishna",
        image: 'https://images.unsplash.com/photo-1581299894007-aaa50297cf16?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGNoZWZ8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
        desciption: 'Cooking is one of my favourite hobbies, loves to cook delicious Indian Cuisine and expert in cooking famous Hyderabad Dum Biryani (Chicken and Lamb). I spend my free time to learn and cook new food varieties. As I enjoy cooking, I love to showcase my talent. Finally I am Super Chef at Chefs Nearby. Thanks to Chefs Nearby for this wonderful opportunity.',
        sugnatureDish: [
            {
                name: 'feugiat',
                imgUrl: 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',

            },
            {
                name: 'feugiat',
                imgUrl: 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',

            },
            {
                name: 'feugiat',
                imgUrl: 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',

            },
            {
                name: 'feugiat',
                imgUrl: 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',

            },
        ]
    },
    {
        name: "Ashwini",
        image: 'https://images.unsplash.com/photo-1577219491135-ce391730fb2c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y2hlZnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
        desciption: 'I just love cooking, It all started as hobby and now I am expert in some dishes. Chef nearby is a wonderful platform to showcase our cooking skills and make people satisfy their taste buds. It boosts my day when I get to see compliments on my dish as reviews. Overall cooking is keeping my soul happy and brings a smile when I receive a notification of amount credited to my account.',
        sugnatureDish: [
            {
                name: 'feugiat',
                imgUrl: 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',

            },
            {
                name: 'feugiat',
                imgUrl: 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',

            },
            {
                name: 'feugiat',
                imgUrl: 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',

            },
            {
                name: 'feugiat',
                imgUrl: 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',

            },
        ]
    },

]

export const isCookingYourHobby = "Cooking is like a science experiment. You never know what you’ll get unless you experiment and learn to adapt. Food is always better with friends! What's your favorite dish that you learnt to cook or create over time? Share it with us using #brandname A cook can be an artist or a craftsman, improvising with the materials of his art to create something new and beautiful. He can be a scientist, studying the effects of heat and other elements on food."


export var socialList = [
    // {
    //     url: "https://images.unsplash.com/photo-1478145046317-39f10e56b5e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fGZvb2R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"

    // }, {
    //     url: "https://images.unsplash.com/photo-1484980972926-edee96e0960d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGZvb2R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
    // },
    {
        url: "https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/98-389.jpg"
    },
    {
        url: "https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/98-389.jpg"

    }, {
        url: "https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/98-391.jpg"
    },
    {
        url: "https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/98-389.jpg"
    }
]

export var customerFeedback = [
    {
        profilePic: "https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-378.png",
        name: "Priya R",
        place: "London, UK",
        feedback: "I have ordered “Chicken Biryani” on a lazy day from Chefs Nearby, to my surprise the biryani was just authentic as Hyderabadi dum biryani. Never expected that we had so many local chefs who can cook deliciously. Will keep trying more dishes going forward."

    },
    {
        profilePic: "https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-378.png",
        name: "riya R 1",
        place: "London, UK",
        feedback: "Thanks to my friend who recommended about chefs nearby. Was carving to eat Butter Paneer Masala and ordered the same from Chefs Nearby. The complete process was hassle free. App was user friendly with detailing about the dish and pricing. No issues with packaging and delivery. If you are looking food from local chefs, do give a try and I am sure you won’t regret."

    },
    {
        profilePic: "https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-378.png",
        name: "rahul R 2",
        place: "London, UK",
        feedback: "Over the years, I've tried a lot of cooking apps. But in my opinion, chefs Nearby is hands down the best. The recipes are concise and easy to follow with great dialogues that keep the process fun and exciting. The food is always delicious and I'm always excited to cook up something new when it pops up on my phone!"

    },
    {
        profilePic: "https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-378.png",
        name: "aftab R 3",
        place: "London, UK",
        feedback: "I was looking for a food delivery in my area and chefs nearby popped up. I had never heard of them before, but the reviews were pretty good. So, I decided to give it a shot and voila, I got food delivered for just £5! It turns out that chefs nearby is the best food delivery in all the apps available."

    },
    {
        profilePic: "https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-378.png",
        name: "aftab R 3",
        place: "London, UK",
        feedback: "I absolutely love this app. I'm a stay-at-home mom and my son has a school lunch policy requiring hot food. It can be hard to find time to go grocery shopping, but with the chefs nearby app, I am able to order food from all over town and have it delivered within a couple hours! Plus, their customer service is fantastic."

    }
]

export var frequentlyAskedQuestion = [
    {
        question: "Where can I download the app?",
        answer: "Click on the playstore and appstore icons to go to the respective download links."
    },
    {
        question: "How do I create a Chefs Nearby Account?",
        answer: `
        Download the Chefs Nearby app from the Appstore and Play Store links.\n 
	Android: https://play.google.com/store/apps/details?id=com.chefsnearby_chef\n
	iOS: https://apps.apple.com/us/app/chefsnearby-chef/id1589289063\n
	Register using your mobile number & email.\n 
	Add your address/location.\n 
	Upload required documents\n 
	You are good to start with your first order!\n
    `
    },
    {
        question: "Can I change my address after registering?",
        answer: "You can change your address from 'My Addresses' in the 'My Account' section of the app."
    },
    {
        question: "How to order food from Chefs Nearby?",
        answer: `
        Download the chefs nearby User app from\n
	Android: https://play.google.com/store/apps/details?id=com.nearbychef_user\n
	iOS: https://apps.apple.com/app/chefsnearby-user/id1582404488\n
	Register using Mobile number & Email\n
	Add your Address/location\n
	You are good to start with your first order!\n`
    },
    {
        question: "How can I know about food allergies?",
        answer: "Kindly read the description of the ingredients used for the dish before placing your order."
    },
    {
        question: "How do I leave a review if I am not happy with the order?",
        answer: "We are sorry as the food you received did not meet your expectations. You can go ahead and rate them every time you order and please do share the detailed feedback about each order. So that we can go ahead and take it up with our team. Your detailed feedback helps us to improve our quality. "
    },
    {
        question: "I found a foreign object in my food. What do I do?",
        answer: `
        
        Please contact us through order help or by sending an email to support@chefsnearby.co.uk and provide us with answers to the following questions so our team can assist you immediately: 
	What was the unexpected object you found?\n
	Where did you find the object?\n
	Did you eat/drink the affected item(s)?\n
	Clear photographs of the object and affected item(s)\n
	If you could also please let us know if you give consent to pass your contact details (name, phone number, and email) on to the restaurant for further investigation. 
        `
    },
    {
        question: "Do I have to register a business with Companies House if I am starting a Home kitchen?",
        answer: "Whether or not you need to register with Companies House depends on the legal structure you've chosen for your business. Sole traders do not need to register with Companies House. However, if you’re starting a limited company or limited liability partnership (LLP), you are legally required to register with Companies House."
    },
    {
        question: "Do I need to have my Food Hygiene certification in place to join Chefs nearby?",
        answer: "Yes, you need to have Food Hygiene Level 2 certification to join Chefs Nearby."
    },

    {
        question: "Do I need to take insurance for my Home Kitchen business?",
        answer: "Taking insurance is not legally mandatory, but we highly recommend getting the business insured to protect yourself and your customers. Even the most stringent health and safety measures can’t prevent the occasional accident – that’s the nature of working with food. Having solid insurance in place lets you get on with what’s important, without the worry. After all, you have enough on your plate without worrying about slips and spills."
    },
    {
        question: "How long it will take for me to onboarded on the Chefs Nearby platform?",
        answer: "As long as you send us all the details and documents we ask for (you can do all this through our mobile app) and usually go online within 24 hours."
    },
    {
        question: "How much does it cost to join and how much commission I will pay?",
        answer: "Our commission is 10% on every order you take. This will subject to change and vary. So, if you get an order worth £20, our commission would be £2.00."
    },
    {
        question: "I want to join Chefs Nearby but I don’t have any drivers. Can you help?",
        answer: "Yes, we do have our own delivery system in place known as Deliver-Nearby. Delivery-nearby is integrated with ChefsNearby to deliver food and help our users."
    },
    {
        question: "Can I place orders in advance?",
        answer: "Yes, you can place the order in advance, you can check chef’s schedule for the week and place the order accordingly for future dates."
    },
    {
        question: "Can I collect my order?",
        answer: "Sorry, we do not have the option to collect the order, your order will be delivered to your address by our Deliver-nearby drivers."
    },
    {
        question: "What if I want to cancel my order?",
        answer: "If you want to cancel your order, please contact our customer service from the app. If the chef had started preparing your food, unfortunately, you cannot cancel your order."
    },
    {
        question: "What if I’m not around when my rider arrives?",
        answer: "The driver will contact you once he reaches your destination. You can request him to leave the order in your safe place at your home."
    },
    {
        question: "Is there a minimum spend?",
        answer: "There is no minimum spend on your orders."
    },
    {
        question: "How to check the status of my order?",
        answer: "You can always check the status of your order from the 'My Orders' section in the App."
    }
];



export var frequentlyAskedQuestionOld = [
    {
        question: "Where can I download the app?",
        answer: "Click on the playstore and appstore icons to go to the respective download links."
    },
    {
        question: "How do I create a Chefs Nearby Account?",
        answer: "Download the Chefs Nearby app from the Appstore and Play Store links. Register using your mobile number & email. Add your address/location. You are good to start with your first order!"
    },
    {
        question: "Can I change my address after registering?",
        answer: "You can change your address from 'My Addresses' in the 'My Account' section of the app."
    },
    {
        question: "How can I know about food allergies?",
        answer: "Kindly read the description of the ingredients used for the dish before placing your order."
    },
    {
        question: "How do I leave a review if I am not happy with the order?",
        answer: "We are sorry as the food you received did not meet your expectations. You can go ahead and rate them every time you order and please do share the detailed feedback about each order. So that we can go ahead and take it up with our team. Your detailed feedback helps us to improve our quality. "
    },
    {
        question: "Do I have to register a business with Companies House if I am starting a Home kitchen?",
        answer: "Whether or not you need to register with Companies House depends on the legal structure you've chosen for your business. Sole traders do not need to register with Companies House. However, if you’re starting a limited company or limited liability partnership (LLP), you are legally required to register with Companies House."
    },
    {
        question: "Do I need to have my Food Hygiene certification in place to join Chefs nearby?",
        answer: "Yes, you need to have Food Hygiene Level 2 certification to join Chefs Nearby."
    },
    {
        question: 'How do I create a Chefs Nearby Account?',
        answer: "You can create a Chefs Nearby account by clicking here or join using your Google or Facebook account."
    },
    // {
    //     question:'How do I change my password?',
    //     answer:"Click on the forgot password link to change your password. You should be getting a unique link to create a new password. Our team will never ask your passwords"
    // },
    {
        question: 'If you have food allergies – what can I do?',
        answer: "Kindly read the description and ingredients used for the dish before ordering"
    },
    {
        question: 'How do I leave a review?',
        answer: "Not happy with the quality/quantity. We are sorry as the food you received did not meet your expectations. You can go ahead and rate them every time you  order and please do share the detailed feedback about each order. So that we can go ahead and take it up with our team. Your detailed feedback helps us to improve our quality."
    },
    {
        question: 'I found a foreign object in my food. What do I do?',
        answer: "Please contact us through order help or by sending an email to support@deliver-nearby.co.uk and provide us with answers to the following questions so our team can assist you immediately: •	What was the unexpected object you found? •	Where did you find the object? •	Did you eat/drink the affected item(s)? •	Clear photographs of the object and affected item(s) If you could also please let us know if you give consent to pass your contact details (name, phone number, and email) on to the restaurant for further investigation.  "
    },
    {
        question: 'Do I have to register business with companies house, if I am starting Home kitchen?',
        answer: "Whether or not you need to register with Companies House depends on the legal structure you've chosen for your business. Sole traders do not need to register with Companies House. Instead, they must simply register with HMRC and complete an annual Self-Assessment tax return. However, if you’re starting a limited company or limited liability partnership (LLP), you are legally required to register with Companies House."
    },
    {
        question: 'Do I need to have my FSA Food Hygiene rating in place to join Chefs nearby?',
        answer: "You don’t need to have your FSA rating before you join CMG, but you do need to register your business with your local council, so your home kitchen can be inspected and given a hygiene rating as soon as possible. However, we will be offering a free Hygiene Level 2 and Allergen certification training to all our chefs and will encourage everyone to retake the same every year. This will be good for our chefs as well as our customers."
    },
    {
        question: 'Do I need to take insurance for my Home Kitchen business?',
        answer: "Taking insurance is not legally mandatory, but we highly recommend getting the business insured to protect yourself and your customers. Even the most stringent health and safety measures can’t prevent the occasional accident – that’s the nature of working with food. Having solid insurance in place lets you get on with what’s important, without the worry. After all, you have enough on your plate without worrying about slips and spills."
    },
    {
        question: 'How long it will take for me to onboarded on the Chefs Nearby platform',
        answer: "As long as you send us all the details and documents we ask for (you can do all this through our mobile app) and you’ve signed your contract, complete the mandatory training your home kitchen can usually go online with CMG within 24 hours."
    },
    {
        question: 'How much does it cost to join and how much commission I will pay?',
        answer: "Our commission is 10% (excluding VAT) on every order you take. So, if you get an order worth £20, for example, our commission would be £2.40 (that’s £2.00 commission plus 40p VAT)."
    },
    {
        question: 'I want to join Chefs Nearby but I don’t have any drivers. Can you help?',
        answer: "Yes, we do have our own delivery system in place known as deliver Nearby"
    },
    {
        question: 'What Insurance do I need for my home kitchen business',
        answer: "Public Liability Insurance is often a very important cover for food businesses, as it can protect you if someone is injured or their property is damaged because of your business. Many insurance providers offer between £1 million and £5 million in cover, to protect you against this cost. If you have employees, contractors, casual workers or temporary staff, you’re required by law to take out employer’s liability insurance. It’ll cover claims from employees who’ve been injured or become seriously ill as a result of working for you."
    },

]

export var contactUs = {
    "name": '',
    "email": '',
    "phone": '',
    "message": ''
}

export var alertmessage = ''
export var alertActive = false
export var sucessAlertActive = false
export var successmessage = 'Successfully Submitted'

export const submitContactUs = async () => {
    if (validator.isEmpty(contactUs.name) == true) {
        alertmessage = 'Add Your Name'
        alertActive = true
        sucessAlertActive = false
        return;
    }
    if (validator.isEmail(contactUs.email) == false) {
        alertmessage = 'Add Your Email/ Invalid Email'
        alertActive = true
        sucessAlertActive = false
        return;
    }
    if (validator.isMobilePhone(contactUs.phone) == false) {
        alertmessage = 'Add Your Phone/ Invalid Phone'
        alertActive = true
        sucessAlertActive = false
        return;
    }
    if (validator.isEmpty(contactUs.message) == true) {
        alertmessage = 'Add Message that you Want to Send'
        alertActive = true
        sucessAlertActive = false
        return;
    }
    // alert('submited successfully'+JSON.stringify(contactUs))
    alertActive = false
    // sucessAlertActive = false
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(contactUs);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch("https://api.chefsnearby.co.uk/ms/api/contactus", requestOptions)//https://api.chefsnearby.co.uk/ms/api/contactus
        .then(response => response.text())
        .then(result => { sucessAlertActive = true })
        .catch(error => { alertmessage = 'There is something wrong, Try again'; alertActive = true; sucessAlertActive = false });


    var fields = ["name-input1", "98:404", "98:406", "98:408", "161:291", "161:289", "161:293", "161:295", "173:283", "173:285", "173:287", "173:289", "157:2089", "157:2091", "157:2093", "157:2095"]
    fields.forEach(f => {
        document.getElementById(f).value = ""; // Clear the input value

    })
}

export const onAlertClose = () => {
    alertActive = false
}


export var topNav = [
    "Favourite Dish",
    "Super Chef",
    "Make a wish",
    "Pre Order",
    "How it works",
    "About us",
    "Contact us"
]

//"Pertner with us"