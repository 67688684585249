import { alertActive, alertmessage, cheflist, contactUs, customerFeedback, frequentlyAskedQuestion, isCookingYourHobby, onAlertClose, seucessAlertActive, socialList, submitContactUs, successmessage, sucessAlertActive, topNav } from "../DataLayer/data"
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useEffect, useRef, useState } from "react";
import { InView, useInView } from 'react-intersection-observer';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert'
import AppStore from '../Image/App_store.png'
import Google_play from '../Image/Google_play.png'
import Instagram from '../Image/Instagram-Negative.png'
import Twitter from '../Image/Twitter-Negative.png'
import Facebook from '../Image/Vector.png'
import { height } from "@mui/system";
import Cross from '../Image/close.png'
import { Routes, Route, useNavigate } from 'react-router-dom';

var revealEffectConfig = {
    // "90:90": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "90:94": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "90:99": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "90:108": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "90:112": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "90:117": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "90:122": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "90:130": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "94:49": [
    //     "animate__animated",
    //     "animate__rollIn"
    // ],
    // "97:77": [
    //     "animate__animated",
    //     "animate__fadeInBottomRight"
    // ],
    // "94:74": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "97:79": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "97:125": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "97:130": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "97:140": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "97:135": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "97:149": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "97:143": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "97:153": [
    //     "animate__animated",
    //     "animate__flip"
    // ],
    // // "97:168": [
    // //     "animate__animated",
    // //     "animate__rollIn"
    // // ],
    // "97:170": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "98:401": [
    //     "animate__animated",
    //     "animate__rollIn"
    // ],
    // "97:388": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ]
}
const View1 = (props) => {

    const navigate = useNavigate();

    const navigateToPrivacy = () => {
        // 👇️ navigate to /contacts
        // navigate('/privacy');
        window.open(
            "/privacy", "_blank");

    };

    const navigateToTerms = () => {
        // 👇️ navigate to /contacts
        // navigate('/terms');
        window.open(
            "/terms", "_blank");
    };

    const [active, setactive] = useState("Favourite Dish")
    const [percent, setpercent] = useState(100)
    const [triggerOnce, settriggerOnce] = useState(false)
    const [revealDelay, setrevealDelay] = useState(300)
    const [revealThreshold, setrevealThreshold] = useState(0)
    const [alert, setAlert] = useState(false)
    const [sucessAlert, setSucessAlert] = useState(false)

    const [howKitchenWorkPopup, setHowKitchenWorkPopup] = useState(false)
    const [howMakeWishVid, setHowMakeWishvid] = useState(false)
    const [header, setHeader] = useState(true)

    const socialHSref = useRef()
    const feedbackHSref = useRef()
    const chefHSref = useRef()

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {

        var width = document.body.clientWidth
        var designWidth = 1440

        var percent = width * 100 / designWidth

        setpercent(percent)


    }, []);

    const updateVisibility = (id, visible) => {
        var el = document.getElementById(id)
        // alert(visible)


        if (visible) {
            el.classList.add("animate__animated")

            el.classList.add(revealEffectConfig[id][1])
            el.classList.remove("hidden")

        } else {
            el.classList.remove("animate__animated")

            el.classList.remove(revealEffectConfig[id][1])
            el.classList.add("hidden")



        }
    }

    const goToNext = (scrollRef) => {
        var nextItem = scrollRef.current.getNextItem()
        // alert(JSON.stringify(nextItem))
        if (scrollRef.current.isLastItemVisible) {

            nextItem = scrollRef.current.getItemByIndex(0)
        }
        scrollRef.current.scrollToItem(
            nextItem,
            // OR if you not sure about id for first item
            // scrollRef.current.getItemById(scrollRef.current.items.toItems()[nextItem.index]),
            "smooth",
            "start"
        );
    }


    const goToPrev = (scrollRef) => {
        var prevItem = scrollRef.current.getPrevItem()

        scrollRef.current.scrollToItem(
            prevItem,
            // OR if you not sure about id for first item
            // scrollRef.current.getItemById(scrollRef.current.items.toItems()[nextItem.index]),
            "smooth",
            "start"
        );
    }

    const contactUsform = (id, val) => {
        contactUs[id] = val
    }

    const HeaderAction = (val) => {
        setHeader(val)

    }

    return (
        <>

            <div>
                <div
                    id="85:48"



                    style={{
                        "height": 12435, "width": 1440, "backgroundColor": "rgba(255,255,255,1)", "flexDirection": "column",
                        "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 20
                    }}


                >

                    <div>
                        <div
                            id="96:49"



                            style={{
                                "flexDirection": "row", "alignItems": "flex-end",
                                "justifyContent": "center", "display": "flex", "paddingLeft": 0, "paddingRight": 0,
                                "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, position: 'fixed', backgroundColor: "#fff", paddingBottom: 20, paddingTop: 20,
                                borderBottom: '2px solid #ccc', zIndex: header ? 10000 : 0
                            }}


                        >

                            <div>
                                <div
                                    id="90:136"



                                    style={{ "width": 260, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 0, "marginLeft": 0 }}


                                >

                                    <div>
                                        <div
                                            id="85:49"



                                            style={{ "height": 97, "width": 259, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/85-49.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="96:48"



                                    style={{ "flexDirection": "column", "alignItems": "flex-end", "justifyContent": "flex-end", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginRight": 0 }}


                                >

                                    <div>
                                        <div
                                            id="90:63"



                                            style={{ "width": 1100, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-end", "display": "flex", "itemSpacing": 90, "paddingLeft": 20, "paddingRight": 40, "paddingTop": 10, "paddingBottom": 10, "marginTop": 0, "marginBottom": 5 }}


                                        >

                                            <div>
                                                <div
                                                    id="90:61"



                                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 15, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 45 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="90:60"



                                                            style={{ "height": 24, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginLeft": 0, "marginRight": 7.5 }}


                                                        >

                                                            <span
                                                                id="90:58"


                                                                style={{ "fontFamily": "Lato", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left" }}



                                                            >
                                                                Download app from

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="90:54"



                                                            style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 7.5, "marginRight": 0 }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="90:49"



                                                                    style={{ "height": 50, "width": 138, "borderRadius": null, "backgroundImage": `url(${Google_play})`, "backgroundSize": 'cover', "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 5, cursor: 'pointer' }}


                                                                >

                                                                    {/* <img src={Google_play} style={{height:'100%', width:'100%', }} */}

                                                                </div>
                                                            </div><div>
                                                                <div
                                                                    id="90:50"



                                                                    style={{ "height": 50, "width": 138, "borderRadius": null, "backgroundImage": `url(${AppStore})`, "backgroundSize": 'cover', "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 5, "marginRight": 0, cursor: 'pointer' }}


                                                                >



                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="90:62"



                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 55, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 45, "marginRight": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="90:51"

                                                            onClick={() => { window.open("https://www.facebook.com/profile.php?id=100090737110736&mibextid=ZbWKwL"); }}

                                                            style={{ "height": 28, "width": 29, "borderRadius": null, "backgroundImage": `url(${Facebook})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 27.5, cursor: 'pointer' }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="90:52"


                                                            onClick={() => { window.open("https://twitter.com/chefs_nearby"); }}
                                                            style={{ "height": 28, "width": 28, "borderRadius": null, "backgroundImage": `url(${Twitter})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 27.5, "marginRight": 27.5, cursor: 'pointer' }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="90:53"

                                                            onClick={() => { window.open("https://instagram.com/chefs.nearby?igshid=ZDdkNTZiNTM="); }}

                                                            style={{ "height": 28, "width": 28, "borderRadius": null, "backgroundImage": `url(${Instagram})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 27.5, "marginRight": 0, cursor: 'pointer' }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="90:137"



                                            style={{ "width": 1160, "flexDirection": "row", "alignItems": "flex-end", "justifyContent": "center", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="90:84"



                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                                >
                                                    {topNav.map((item, index) => {
                                                        return <div>
                                                            <div
                                                                id="90:69"

                                                                className="pointer"
                                                                onClick={() => {
                                                                    // alert(percent)
                                                                    setactive(item)
                                                                    var element = document.getElementById(item);
                                                                    var headerOffset = 180;
                                                                    var elementPosition = element.getBoundingClientRect().top;
                                                                    elementPosition = elementPosition * props.percent / 100
                                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                                    // offsetPosition = offsetPosition * percent / 100
                                                                    // alert(elementPosition)
                                                                    window.scrollTo({
                                                                        top: offsetPosition,
                                                                        behavior: "smooth"
                                                                    });
                                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                                }}
                                                                style={{
                                                                    "height": 18, "width": 126, "flexDirection": "row",
                                                                    "alignItems": "center", "justifyContent": "center",
                                                                    "display": "flex", "itemSpacing": 10, "paddingLeft": 10,
                                                                    "paddingRight": 5, "paddingTop": 10, "paddingBottom": 10,
                                                                    "marginLeft": 0, "marginRight": 15, "backgroundColor": active == item ? "rgba(227,31,14,1)" : "#fff0",
                                                                    "borderRadius": active == item ? 10 : 0
                                                                }}


                                                            >

                                                                <span
                                                                    id="90:65"


                                                                    style={{
                                                                        "fontFamily": "Open Sans", "fontSize": 17, "fontWeight": 600, "color": active == item ? "#fff" : "rgba(0,0,0,1)",
                                                                        "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left"
                                                                    }}



                                                                >
                                                                    {item}

                                                                </span>

                                                            </div>
                                                        </div>
                                                    })}


                                                    {/* <div>
                                                        <div
                                                            id="90:78"



                                                            style={{ "height": 44, "width": 44, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-78.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 15, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div> */}

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div
                            id="Favourite Dish"



                            style={{
                                "height": 18, "flexDirection": "row",
                                "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10,
                                "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, marginTop: 110
                            }}


                        >



                        </div>
                    </div><div>
                        <div
                            id="90:103"



                            style={{ "width": 1440, "backgroundColor": "rgba(224,66,52,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 100, "paddingBottom": 100 }}


                        >

                            <div>
                                <div
                                    id="90:92"



                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 20 }}


                                >

                                    <div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("90:90", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="90:90"

                                                className="hidden"

                                                style={{ "height": 319, "width": 348, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-90.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 5 }}


                                            >



                                            </div>
                                        </InView>
                                    </div><span
                                        id="90:91"


                                        style={{ "fontFamily": "Open Sans", "fontSize": 32, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                    >
                                        Your Wish

                                    </span>

                                </div>
                            </div><div>
                                <div
                                    id="90:93"



                                    style={{ "height": 53, "width": 35, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-93.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 20, "marginRight": 20 }}


                                >



                                </div>
                            </div>
                            <div >
                                <div
                                    id="90:97"



                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 20, "marginRight": 20 }}


                                >

                                    <div>
                                        <div
                                            id="90:95"



                                            style={{ "height": 299, "width": 328, "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 0, "marginBottom": 5 }}


                                        >

                                            <div>
                                                <InView
                                                    triggerOnce={triggerOnce}
                                                    delay={revealDelay}

                                                    onChange={(inView, entry) => {
                                                        updateVisibility("90:94", inView)
                                                    }}

                                                    threshold={revealThreshold}

                                                >
                                                    <div
                                                        id="90:94"


                                                        className="hidden"
                                                        style={{ "height": 310, "width": 376, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-94.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                    >



                                                    </div>
                                                </InView>
                                            </div>

                                        </div>
                                    </div><span
                                        id="90:96"


                                        style={{ "fontFamily": "Open Sans", "fontSize": 32, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                    >
                                        Chef Prepare

                                    </span>

                                </div>
                            </div>
                            <div>
                                <div
                                    id="90:98"



                                    style={{ "height": 53, "width": 35, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-98.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 20, "marginRight": 20 }}


                                >



                                </div>
                            </div><div>
                                <div
                                    id="90:102"



                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 20, "marginRight": 0 }}


                                >

                                    <div>
                                        <div
                                            id="90:100"



                                            style={{ "height": 299, "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 0, "marginBottom": 5 }}


                                        >

                                            <div>
                                                <InView
                                                    triggerOnce={triggerOnce}
                                                    delay={revealDelay}

                                                    onChange={(inView, entry) => {
                                                        updateVisibility("90:99", inView)
                                                    }}

                                                    threshold={revealThreshold}

                                                >
                                                    <div
                                                        id="90:99"
                                                        className="hidden"


                                                        style={{ "height": 310, "width": 330, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-99.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                    >



                                                    </div>
                                                </InView>
                                            </div>

                                        </div>
                                    </div><span
                                        id="90:101"


                                        style={{ "fontFamily": "Open Sans", "fontSize": 32, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                    >
                                        Your Dish

                                    </span>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="90:127"



                            style={{ "width": 1440, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 45, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 70, "paddingBottom": 70 }}


                        >

                            <span
                                id="90:104"


                                style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "center", "marginTop": 0, "marginBottom": 22.5 }}



                            >
                                Order from Our Kitchen schedule

                            </span><div>
                                <div
                                    id="90:126"



                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": -12, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 22.5, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="90:110"



                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": -6 }}


                                        >

                                            <div>
                                                <InView
                                                    triggerOnce={triggerOnce}
                                                    delay={revealDelay}

                                                    onChange={(inView, entry) => {
                                                        updateVisibility("90:108", inView)
                                                    }}

                                                    threshold={revealThreshold}

                                                >
                                                    <div
                                                        id="90:108"

                                                        className="hidden"

                                                        style={{ "height": 275, "width": 275, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-108.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 5 }}


                                                    >



                                                    </div>
                                                </InView>
                                            </div><div>
                                                <div
                                                    id="90:128"



                                                    style={{ "height": 156, "width": 265, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                >

                                                    <span
                                                        id="90:109"


                                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(74,74,74,1)", "letterSpacing": 0, "lineHeight": "34px", "textAlign": "center" }}



                                                    >
                                                        Chef Prepares his/her schedule and upload the dishes and quantity for the next 5 days

                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="90:111"



                                            style={{ "height": 57, "width": 127, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-111.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": -6, "marginRight": -6, marginBottom: '80px' }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="90:115"



                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": -6, "marginRight": -6 }}


                                        >

                                            <div>
                                                <div
                                                    id="90:113"



                                                    style={{ "height": 275, "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 5 }}


                                                >

                                                    <div>
                                                        <InView
                                                            triggerOnce={triggerOnce}
                                                            delay={revealDelay}

                                                            onChange={(inView, entry) => {
                                                                updateVisibility("90:112", inView)
                                                            }}

                                                            threshold={revealThreshold}

                                                        >
                                                            <div
                                                                id="90:112"

                                                                className="hidden"

                                                                style={{ "height": 256, "width": 276, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-1122.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                            >



                                                            </div>
                                                        </InView>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="90:134"



                                                    style={{ "width": 261, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                >

                                                    <span
                                                        id="90:114"


                                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(74,74,74,1)", "letterSpacing": 0, "lineHeight": "34px", "textAlign": "center" }}



                                                    >
                                                        Chef publishes his schedule and shares the links and QR code with customers

                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="90:116"



                                            style={{ "height": 23, "width": 124, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-116.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": -6, "marginRight": -6, marginBottom: '80px' }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="90:120"



                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": -6, "marginRight": -6 }}


                                        >

                                            <div>
                                                <div
                                                    id="90:118"



                                                    style={{ "height": 275, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 5 }}


                                                >

                                                    <div>
                                                        <InView
                                                            triggerOnce={triggerOnce}
                                                            delay={revealDelay}

                                                            onChange={(inView, entry) => {
                                                                updateVisibility("90:117", inView)
                                                            }}

                                                            threshold={revealThreshold}

                                                        >
                                                            <div
                                                                id="90:117"

                                                                className="hidden"

                                                                style={{ "height": 214, "width": 199, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-117.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                            >



                                                            </div>
                                                        </InView>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="90:129"



                                                    style={{ "width": 214, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                >

                                                    <span
                                                        id="90:119"


                                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(74,74,74,1)", "letterSpacing": 0, "lineHeight": "34px", "textAlign": "center" }}



                                                    >
                                                        Browse your dish from our Kitchen Schedule

                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="90:121"



                                            style={{ "height": 35, "width": 141, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-121.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": -6, "marginRight": -6, marginBottom: '80px' }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="90:125"



                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": -6, "marginRight": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="90:123"



                                                    style={{ "height": 275, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 5 }}


                                                >

                                                    <div>
                                                        <InView
                                                            triggerOnce={triggerOnce}
                                                            delay={revealDelay}

                                                            onChange={(inView, entry) => {
                                                                updateVisibility("90:122", inView)
                                                            }}

                                                            threshold={revealThreshold}

                                                        >
                                                            <div
                                                                id="90:122"

                                                                className="hidden"

                                                                style={{ "height": 217, "width": 207, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-122.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                            >



                                                            </div>
                                                        </InView>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="90:135"



                                                    style={{ "height": 156, "width": 265, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                >

                                                    <span
                                                        id="90:124"


                                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(74,74,74,1)", "letterSpacing": 0, "lineHeight": "34px", "textAlign": "center" }}



                                                    >
                                                        User orders the food from the app and then it gets delivered.

                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="90:133"



                            style={{ "width": 1440, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 60, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 40, "paddingBottom": 120 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("90:130", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="90:130"
                                    className="hidden"

                                    style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 0, "marginBottom": 30, display: "inline-block" }}



                                >
                                    How Kitchen schedule works

                                </span>
                            </InView>
                            <div style={{ "height": 572, "width": 1035, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/kitchen+shedule.png)", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{}}
                                    onClick={() => { setHowKitchenWorkPopup(true); HeaderAction(false) }}
                                >
                                    <img style={{ height: 130, width: 130, cursor: 'pointer' }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/play+button.png'} />
                                </div>
                            </div>
                            {
                                howKitchenWorkPopup == true ?
                                    <div style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', top: 0, zIndex: 5000 }}>
                                        <div style={{ display: 'flex' }}>
                                            <video
                                                id="90:132"



                                                style={{
                                                    "height": 572, "width": 1035,
                                                    "flexDirection": "column", "alignItems": "center", "justifyContent": "center",
                                                    "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10,
                                                    "paddingTop": 10, "paddingBottom": 10, "marginTop": 30, "marginBottom": 0, backgroundColor: 'grey'
                                                }}
                                                autoPlay
                                                controls

                                            >


                                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/kitchenSchedule.mp4" type="video/mp4" />
                                                <div>
                                                    <div
                                                        id="90:131"



                                                        style={{ "height": 144, "width": 150, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/6620/f4ff/cad63dd24161ccfcd705204c8cf5309b?Expires=1667779200&Signature=AQCfaAEtxv0l0zQ99xQH8-wOd~SiRaSMkHVxj97Ye2Kg7nulZLfPkiMVVhtrzEYWVvvdzYrkNsSwqOLycIadd7PVAlovV55El~nhyRNbpKTeV5dn-DTnhATXXEGO~OphfB-Us0IXAIQkbxe40wURWlhFk16Wjlx2rwEVJrYxsYPaBdpZq3Zkc76FYarcoapaSBQybxYWDyiO7UOhwhSL5h0PN-ujSdtmyFsaPtcvZAj3hRr4JrDxxLIEW2a3JdNR2lFRXwk3Eo5pDraLShDDgXnY499Kf30ZNRHMirlgukKmi8dsEXXEMPKOvye4p9RnZ0R-E-dYW-i7GnlU4EL24g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                    >



                                                    </div>
                                                </div>

                                            </video>
                                            <div onClick={() => { setHowKitchenWorkPopup(false); HeaderAction(true) }} style={{ marginLeft: '-45px', marginTop: 15, cursor: 'pointer' }}>
                                                <img style={{ width: 20, height: 20 }} src={Cross} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {/* <div>

                                <video
                                    id="90:132"



                                    style={{
                                        "height": 572, "width": 1035,
                                        "flexDirection": "column", "alignItems": "center", "justifyContent": "center",
                                        "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10,
                                        "paddingTop": 10, "paddingBottom": 10, "marginTop": 30, "marginBottom": 0
                                    }}
                                    // autoPlay
                                    controls

                                >


                                    <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/kitchenSchedule.mp4" type="video/mp4" />
                                    {/* <div>
                                        <div
                                            id="90:131"



                                            style={{ "height": 144, "width": 150, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/6620/f4ff/cad63dd24161ccfcd705204c8cf5309b?Expires=1667779200&Signature=AQCfaAEtxv0l0zQ99xQH8-wOd~SiRaSMkHVxj97Ye2Kg7nulZLfPkiMVVhtrzEYWVvvdzYrkNsSwqOLycIadd7PVAlovV55El~nhyRNbpKTeV5dn-DTnhATXXEGO~OphfB-Us0IXAIQkbxe40wURWlhFk16Wjlx2rwEVJrYxsYPaBdpZq3Zkc76FYarcoapaSBQybxYWDyiO7UOhwhSL5h0PN-ujSdtmyFsaPtcvZAj3hRr4JrDxxLIEW2a3JdNR2lFRXwk3Eo5pDraLShDDgXnY499Kf30ZNRHMirlgukKmi8dsEXXEMPKOvye4p9RnZ0R-E-dYW-i7GnlU4EL24g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                        >



                                        </div>
                                    </div> 

                                </video>
                            </div> */}

                        </div>
                    </div><div>
                        <div
                            id="94:69"



                            style={{ "width": 1440, "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 70, "paddingBottom": 70 }}


                        >

                            <span
                                id="Super Chef"


                                style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 0, "marginBottom": 25 }}



                            >
                                Super Chef

                            </span><div>
                                <div
                                    id="94:68"



                                    style={{ "flexDirection": "row", "alignItems": "flex-end", "justifyContent": "center", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="94:67"



                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 75, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 25 }}


                                        >

                                            <div>
                                                <InView
                                                    triggerOnce={triggerOnce}
                                                    delay={revealDelay}

                                                    onChange={(inView, entry) => {
                                                        updateVisibility("94:49", inView)
                                                    }}

                                                    threshold={revealThreshold}

                                                >
                                                    <div
                                                        id="94:49"

                                                        className="hidden"

                                                        style={{ "height": 367, "width": 736, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-49.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 37.5 }}


                                                    >



                                                    </div>
                                                </InView>
                                            </div><div>
                                                <div
                                                    id="94:51"



                                                    style={{ "height": 68, "width": 422, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 37.5, "marginBottom": 0 }}


                                                >

                                                    <span
                                                        id="94:50"


                                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left" }}



                                                    >
                                                        Download Our App Now

                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="94:66"



                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 25, "marginRight": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="94:61"



                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 45, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="94:54"



                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 6, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 22.5 }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="94:52"



                                                                    style={{ "height": 165, "width": 136, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-52.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 3 }}


                                                                >



                                                                </div>
                                                            </div><span
                                                                id="94:53"


                                                                style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(123,119,119,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "justified", "marginTop": 3, "marginBottom": 0 }}



                                                            >
                                                                Chef Name

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="94:55"



                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 6, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 22.5, "marginRight": 22.5 }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="94:56"



                                                                    style={{ "height": 165, "width": 136, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-52.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 3 }}


                                                                >



                                                                </div>
                                                            </div><span
                                                                id="94:57"


                                                                style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(123,119,119,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "justified", "marginTop": 3, "marginBottom": 0 }}



                                                            >
                                                                Chef Name

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="94:58"



                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 6, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 22.5, "marginRight": 0 }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="94:59"



                                                                    style={{ "height": 165, "width": 136, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-52.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 3 }}


                                                                >



                                                                </div>
                                                            </div><span
                                                                id="94:60"


                                                                style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(123,119,119,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "justified", "marginTop": 3, "marginBottom": 0 }}



                                                            >
                                                                Chef Name

                                                            </span>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="94:65"



                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="94:62"



                                                            style={{ "height": 160, "width": 161, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-62.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 15 }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="94:63"



                                                            style={{ "height": 149, "width": 146, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-63.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 15, "marginRight": 15 }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="94:64"



                                                            style={{ "height": 151, "width": 150, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-64.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 15, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="97:78"



                            style={{ "width": 1440, "backgroundColor": "rgba(244,244,244,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 110, "paddingBottom": 110 }}


                        >

                            <div>
                                <div
                                    id="94:75"



                                    style={{ "width": 1003, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 100, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                >

                                    <div>
                                        <div
                                            id="Make a wish"



                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 50 }}


                                        >

                                            <span
                                                id="94:70"


                                                style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 0, "marginBottom": 10 }}



                                            >
                                                Make a wish

                                            </span><div>
                                                <div
                                                    id="94:72"



                                                    style={{ "width": 550, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 10, "marginBottom": 0 }}


                                                >

                                                    <span
                                                        id="94:71"


                                                        style={{ "fontFamily": "Open Sans", "fontSize": 13, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": 'left' }}



                                                    >
                                                        Have you been craving to eat your favourite dish from long?? <br />You don’t know how to make it? <br />You are not getting the desired taste when you do it yourself?? <br /><br /> Here is the solution to satisfy your tastebuds... <br />You can make a wish and our expert chefs from our list would prepare it and <br /> deliver it to you.

                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("94:74", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="94:74"
                                                className="hidden"


                                                style={{ "height": 336, "width": 353, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/94-74.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 50, "marginRight": 0 }}


                                            >



                                            </div>
                                        </InView>
                                    </div>

                                </div>
                            </div><div style={{ display: 'flex', justifyContent: 'left' }}>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("97:77", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="97:77"
                                        className="hidden"


                                        style={{ "height": 733, "width": 884, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-77.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginBottom": 0, marginLeft: '-100px' }}


                                    >



                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="How it works"



                            style={{ "width": 1440, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 90, "paddingBottom": 110 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("97:79", inView)
                                }}

                                threshold={revealThreshold}

                            >

                                <span
                                    id="97:79"
                                    className="hidden"

                                    style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 0, "marginBottom": 25, display: "inline-block" }}



                                >
                                    How make a wish works

                                </span>
                            </InView>
                            <div style={{ "height": 572, "width": 1035, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/make+a+wish.png)", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{}}
                                    onClick={() => { setHowMakeWishvid(true); HeaderAction(false) }}
                                >
                                    <img style={{ height: 130, width: 130, cursor: 'pointer' }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/play+button.png'} />
                                </div>
                            </div>
                            {
                                howMakeWishVid == true ?
                                    <div style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', top: 0, zIndex: 5000 }}>
                                        <div style={{ display: 'flex' }}>
                                            <video
                                                id="90:132"



                                                style={{
                                                    "height": 572, "width": 1035,
                                                    "flexDirection": "column", "alignItems": "center", "justifyContent": "center",
                                                    "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10,
                                                    "paddingTop": 10, "paddingBottom": 10, "marginTop": 30, "marginBottom": 0, backgroundColor: 'grey'
                                                }}
                                                autoPlay
                                                controls

                                            >


                                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/makeAwish.mp4" type="video/mp4" />
                                                <div>
                                                    <div
                                                        id="90:131"



                                                        style={{ "height": 144, "width": 150, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/6620/f4ff/cad63dd24161ccfcd705204c8cf5309b?Expires=1667779200&Signature=AQCfaAEtxv0l0zQ99xQH8-wOd~SiRaSMkHVxj97Ye2Kg7nulZLfPkiMVVhtrzEYWVvvdzYrkNsSwqOLycIadd7PVAlovV55El~nhyRNbpKTeV5dn-DTnhATXXEGO~OphfB-Us0IXAIQkbxe40wURWlhFk16Wjlx2rwEVJrYxsYPaBdpZq3Zkc76FYarcoapaSBQybxYWDyiO7UOhwhSL5h0PN-ujSdtmyFsaPtcvZAj3hRr4JrDxxLIEW2a3JdNR2lFRXwk3Eo5pDraLShDDgXnY499Kf30ZNRHMirlgukKmi8dsEXXEMPKOvye4p9RnZ0R-E-dYW-i7GnlU4EL24g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                    >



                                                    </div>
                                                </div>

                                            </video>
                                            <div onClick={() => { setHowMakeWishvid(false); HeaderAction(true) }} style={{ marginLeft: '-45px', marginTop: 15, cursor: 'pointer' }}>
                                                <img style={{ width: 20, height: 20 }} src={Cross} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {/* <div>
                                <video
                                    id="97:81"



                                    style={{
                                        "height": 553, "width": 1019,
                                        "flexDirection": "column", "alignItems": "center", "justifyContent": "center",
                                        "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10,
                                        "paddingBottom": 10, "marginTop": 25, "marginBottom": 0
                                    }}
                                    // autoPlay
                                    controls

                                >
                                    <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/makeAwish.mp4" type="video/mp4" />


                                    {/* <div>
                                        <div
                                            id="97:80"



                                            style={{ "height": 144, "width": 150, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/6620/f4ff/cad63dd24161ccfcd705204c8cf5309b?Expires=1667779200&Signature=AQCfaAEtxv0l0zQ99xQH8-wOd~SiRaSMkHVxj97Ye2Kg7nulZLfPkiMVVhtrzEYWVvvdzYrkNsSwqOLycIadd7PVAlovV55El~nhyRNbpKTeV5dn-DTnhATXXEGO~OphfB-Us0IXAIQkbxe40wURWlhFk16Wjlx2rwEVJrYxsYPaBdpZq3Zkc76FYarcoapaSBQybxYWDyiO7UOhwhSL5h0PN-ujSdtmyFsaPtcvZAj3hRr4JrDxxLIEW2a3JdNR2lFRXwk3Eo5pDraLShDDgXnY499Kf30ZNRHMirlgukKmi8dsEXXEMPKOvye4p9RnZ0R-E-dYW-i7GnlU4EL24g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                        >



                                        </div>
                                    </div> 

                                </video>
                            </div> */}

                        </div>
                    </div>

                    <div>
                        <div
                            id="97:134"



                            style={{ "width": 1440, "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 70, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 130, "paddingBottom": 130 }}


                        >

                            <div>
                                <div
                                    id="97:129"
                                    className='pointer'
                                    onClick={() => { goToPrev((chefHSref)) }}

                                    style={{ "height": 80, "width": 80, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-129.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 35 }}


                                >



                                </div>
                            </div>


                            <div style={{ width: '80%', }}>
                                <ScrollMenu
                                    apiRef={chefHSref}
                                    scrollContainerClassName="scrollmenu"

                                >
                                    {
                                        cheflist.map((item, index) => {
                                            return <>
                                                <div
                                                    id="97:133"



                                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 150, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 25, "marginLeft": 35, "marginRight": 35, }}


                                                >

                                                    <div>
                                                        <div
                                                            id="97:128"



                                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 75 }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="97:127"



                                                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 15, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 20 }}


                                                                >

                                                                    <span
                                                                        id="97:83"


                                                                        style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 0, "marginBottom": 7.5 }}



                                                                    >
                                                                        Meet the Chef

                                                                    </span><div>
                                                                        <div
                                                                            id="97:126"



                                                                            style={{ "height": 50, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 7.5, "marginBottom": 7.5 }}


                                                                        >

                                                                            <span
                                                                                id="97:84"


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 26, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left" }}



                                                                            >
                                                                                {item.name}

                                                                            </span>

                                                                        </div>
                                                                    </div><div>
                                                                        <div
                                                                            id="97:146"



                                                                            style={{ "width": 530, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 10, "marginTop": 7.5, "marginBottom": 7.5 }}


                                                                        >

                                                                            <span
                                                                                id="97:85"


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                                            >
                                                                                {item.desciption}

                                                                            </span>

                                                                        </div>
                                                                    </div><div>
                                                                        <div
                                                                            id="97:122"



                                                                            style={{ "width": 505, "borderRadius": 20, "backgroundColor": "rgba(231,231,231,1)", "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 15, "paddingTop": 6, "paddingBottom": 8, "marginTop": 7.5, "marginBottom": 0 }}


                                                                        >

                                                                            <div>
                                                                                <div
                                                                                    id="97:123"



                                                                                    style={{ "height": 20, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 0, "marginBottom": 5 }}


                                                                                >

                                                                                    <span
                                                                                        id="97:86"


                                                                                        style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left" }}



                                                                                    >
                                                                                        Siginature dishes

                                                                                    </span>

                                                                                </div>
                                                                            </div><div>
                                                                                <div
                                                                                    id="97:121"



                                                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                                                >

                                                                                    <div>
                                                                                        <div
                                                                                            id="97:106"



                                                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 10 }}


                                                                                        >

                                                                                            <div>
                                                                                                <div
                                                                                                    id="97:113"



                                                                                                    style={{ "height": 110, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                                                                                >

                                                                                                    <div>
                                                                                                        <div
                                                                                                            id="97:104"



                                                                                                            style={{ "height": 115, "width": 116, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-104.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                                                                        >



                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div><span
                                                                                                id="97:105"


                                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginBottom": 0 }}



                                                                                            >
                                                                                                feugiat

                                                                                            </span>

                                                                                        </div>
                                                                                    </div><div>
                                                                                        <div
                                                                                            id="97:115"



                                                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 10, "marginRight": 10 }}


                                                                                        >

                                                                                            <div>
                                                                                                <div
                                                                                                    id="97:114"



                                                                                                    style={{ "height": 110, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                                                                                >

                                                                                                    <div>
                                                                                                        <div
                                                                                                            id="97:107"



                                                                                                            style={{ "height": 109, "width": 108, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-107.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                                                                        >



                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div><span
                                                                                                id="97:108"


                                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginBottom": 0 }}



                                                                                            >
                                                                                                feugiat

                                                                                            </span>

                                                                                        </div>
                                                                                    </div><div>
                                                                                        <div
                                                                                            id="97:118"



                                                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 10, "marginRight": 10 }}


                                                                                        >

                                                                                            <div>
                                                                                                <div
                                                                                                    id="97:116"



                                                                                                    style={{ "height": 110, "flexDirection": "column", "alignItems": "flex-end", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                                                                                >

                                                                                                    <div>
                                                                                                        <div
                                                                                                            id="97:109"



                                                                                                            style={{ "height": 115, "width": 117, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-109.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                                                                        >



                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div><span
                                                                                                id="97:110"


                                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginBottom": 0 }}



                                                                                            >
                                                                                                feugiat

                                                                                            </span>

                                                                                        </div>
                                                                                    </div><div>
                                                                                        <div
                                                                                            id="97:120"



                                                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 10, "marginRight": 0 }}


                                                                                        >

                                                                                            <div>
                                                                                                <div
                                                                                                    id="97:119"



                                                                                                    style={{ "height": 110, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                                                                                >

                                                                                                    <div>
                                                                                                        <div
                                                                                                            id="97:111"



                                                                                                            style={{ "height": 108, "width": 106, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-111.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                                                                        >



                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div><span
                                                                                                id="97:112"


                                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginBottom": 0 }}



                                                                                            >
                                                                                                feugiat

                                                                                            </span>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div><div>
                                                        {/* <InView
                                                            triggerOnce={triggerOnce}
                                                            delay={revealDelay}

                                                            onChange={(inView, entry) => {
                                                                updateVisibility("97:130", inView)
                                                            }}

                                                            threshold={revealThreshold}

                                                        > */}
                                                        <div
                                                            id="97:130"

                                                            // className="hidden"

                                                            style={{ "height": 520, "width": 416, "borderRadius": null, "backgroundImage": `url(${item.image})`, "backgroundSize": "cover", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 75, "marginRight": 0, marginTop: '80px' }}


                                                        >



                                                        </div>
                                                        {/* </InView> */}
                                                    </div>

                                                </div>
                                            </>
                                        })
                                    }
                                </ScrollMenu>
                                <div style={{ marginLeft: '40px' }}>
                                    {/* <InView
                                                                    triggerOnce={triggerOnce}
                                                                    delay={revealDelay}

                                                                    onChange={(inView, entry) => {
                                                                        updateVisibility("97:125", inView)
                                                                    }}

                                                                    threshold={revealThreshold}

                                                                > */}
                                    <div
                                        id="97:125"

                                        // className="hidden"

                                        style={{ "height": 48, "width": 510, "borderRadius": 15, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 20, "marginBottom": 0 }}


                                    >

                                        <span
                                            id="97:124"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left" }}



                                        >
                                            Become a Chef

                                        </span>

                                    </div>
                                    {/* </InView> */}
                                </div>
                            </div>

                            <div>
                                <div
                                    id="97:131"
                                    className='pointer'
                                    onClick={() => { goToNext(chefHSref) }}

                                    style={{ "height": 80, "width": 100, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-131.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 35, "marginRight": 0 }}


                                >



                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="97:142"



                            style={{ "width": 1440, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 100, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 155, "paddingBottom": 155 }}


                        >

                            <div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("97:135", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="97:135"

                                        className="hidden"

                                        style={{ "height": 249, "width": 613, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-135.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 50 }}


                                    >



                                    </div>
                                </InView>
                            </div>

                            <div
                                id="Pertner with us"
                            >
                                <div
                                    id="97:141"



                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 18, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 50, "marginRight": 0 }}


                                >

                                    <span
                                        id="97:136"


                                        style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 0, "marginBottom": 9 }}



                                    >
                                        Is Cooking you Hoby

                                    </span><div>
                                        <div
                                            id="97:145"



                                            style={{ "width": 398, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 9, "marginBottom": 9 }}


                                        >

                                            <span
                                                id="97:137"


                                                style={{ "fontFamily": "Roboto", "fontSize": 17, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": 'left' }}



                                            >
                                                {isCookingYourHobby}

                                            </span>

                                        </div>
                                    </div><div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("97:140", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="97:140"

                                                className="hidden"

                                                style={{ "height": 48, "width": 422, "borderRadius": 15, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 9, "marginBottom": 0 }}


                                            >

                                                <span
                                                    id="97:139"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left" }}



                                                >
                                                    Join Now

                                                </span>

                                            </div>
                                        </InView>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="Pre Order"




                            style={{ "width": 1440, "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 150, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 190, "paddingBottom": 190 }}


                        >

                            <div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("97:143", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="97:143"

                                        className="hidden"

                                        style={{ "height": 259, "width": 317, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-143.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 75 }}


                                    >



                                    </div>
                                </InView>
                            </div><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("97:149", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="97:149"

                                        className="hidden"

                                        style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 75, "marginRight": 0 }}


                                    >

                                        <span
                                            id="97:144"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 0, "marginBottom": 5 }}



                                        >
                                            Pre order

                                        </span><div>
                                            <div
                                                id="99:971"



                                                style={{ "width": 530, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 5, "marginBottom": 0 }}


                                            >

                                                <span
                                                    id="97:148"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                >
                                                    Would you like to surprise your special one with their favourite food? Not able to <br />cook many items? Not able to plan your office work and party menu?? <br /><br />Here we are.... <br />We will let you pre-order your favourite food and plan for special day a week<br /> ahead.

                                                </span>

                                            </div>
                                        </div>

                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="About us"



                            style={{ "width": 1440, "flexDirection": "row", "alignItems": "flex-end", "justifyContent": "center", "display": "flex", "itemSpacing": 250, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 80, "paddingBottom": 80 }}


                        >

                            <div>
                                <div
                                    id="97:154"



                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 15, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 125 }}


                                >

                                    <span
                                        id="97:151"


                                        style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 0, "marginBottom": 7.5 }}



                                    >
                                        About

                                    </span><div>
                                        <div
                                            id="97:156"



                                            style={{ "width": 530, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 7.5, "marginBottom": 0 }}


                                        >

                                            <span
                                                id="97:152"


                                                style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                            >
                                                I am a foodie by heart and techie by profession. I love cooking and
                                                experimenting with new recipes. I also love to explore the world of food
                                                through my travels.
                                                <br></br>
                                                <br></br>
                                                I started this company because I wanted to bring the authentic homemade
                                                food to your door step. We are a team of passionate chefs who want to share
                                                their culinary skills with you.

                                            </span>



                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("97:153", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="97:153"

                                        className="hidden"

                                        style={{ "height": 212, "width": 144, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-153.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 125, "marginRight": 0 }}


                                    >



                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="97:169"



                            style={{ "width": 1420, "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 60, "paddingBottom": 60 }}


                        >

                            <div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("97:168", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="97:168"

                                        // className="hidden"

                                        style={{ "height": 390, "width": 1098, "borderRadius": 59, "backgroundColor": "rgba(224,66,52,1)", "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 20, "paddingLeft": 100, "paddingRight": 100, "paddingTop": 0, "paddingBottom": 0 }}


                                    >

                                        <span
                                            id="97:157"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 26, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 0, "marginBottom": 10 }}



                                        >
                                            Why Chefs near by ?

                                        </span><div>
                                            <div
                                                id="97:167"



                                                style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 100, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 10, "marginBottom": 0 }}


                                            >

                                                <div>
                                                    <div
                                                        id="97:160"



                                                        style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 50 }}


                                                    >

                                                        <span
                                                            id="97:158"


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                        >
                                                            Support local chefs

                                                        </span><span
                                                            id="97:159"


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                                        >
                                                            Choose a local chef of your choice and help local chef and their business to grow.

                                                        </span>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="97:163"



                                                        style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 50, "marginRight": 50 }}


                                                    >

                                                        <span
                                                            id="97:161"


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                        >
                                                            Enjoy homemade food

                                                        </span><span
                                                            id="97:162"


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                                        >
                                                            Enjoy authentic homemade food from different cuisines especially made for you at reasonable price.

                                                        </span>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="97:166"



                                                        style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 50, "marginRight": 0 }}


                                                    >

                                                        <span
                                                            id="97:164"


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                        >
                                                            Get customized food

                                                        </span><span
                                                            id="97:165"


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": 'left', "marginTop": 5, "marginBottom": 0 }}



                                                        >
                                                            Make a wish and get the food delivered as per your choice.

                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="97:386"



                            style={{ "width": 1440, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 100, "paddingBottom": 100 }}


                        >

                            <div>
                                <div
                                    id="97:387"



                                    style={{ "width": 1260, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 170, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 15 }}


                                >
                                    <InView
                                        triggerOnce={triggerOnce}
                                        delay={revealDelay}

                                        onChange={(inView, entry) => {
                                            updateVisibility("97:170", inView)
                                        }}

                                        threshold={revealThreshold}

                                    >
                                        <span
                                            id="97:170"

                                            className="hidden"
                                            style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", display: 'inline-block' }}



                                        >
                                            Our customer feedbacks

                                        </span>
                                    </InView>
                                </div>
                            </div>

                            <div>
                                <div
                                    id="97:385"



                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 90, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="97:383"

                                            onClick={() => {
                                                goToPrev(feedbackHSref)
                                            }}
                                            className='pointer'
                                            style={{ "height": 80, "width": 80, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-383.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 45 }}


                                        >



                                        </div>
                                    </div>
                                    <div style={{ width: 1080 }}>
                                        <ScrollMenu
                                            apiRef={feedbackHSref}
                                            scrollContainerClassName="scrollmenu"

                                        >

                                            {customerFeedback.map((item, index) => {

                                                return <>
                                                    <div>
                                                        <div
                                                            id="97:182"



                                                            style={{ "flexDirection": "column", height: 300, "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 45, "marginRight": 45 }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="97:181"



                                                                    style={{ "height": 197, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 6, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 5 }}


                                                                >

                                                                    <div>
                                                                        <div
                                                                            id="97:180"



                                                                            style={{ "height": 40, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 3 }}


                                                                        >

                                                                            <span
                                                                                id="97:171"


                                                                                style={{ "fontFamily": "Playfair Display", "fontSize": 96, "fontWeight": 400, "color": "rgba(224,224,224,1)", "letterSpacing": 0, "lineHeight": "80px", "textAlign": "left" }}



                                                                            >
                                                                                “

                                                                            </span>

                                                                        </div>
                                                                    </div><div>
                                                                        <div
                                                                            id="99:988"



                                                                            style={{ "width": 460, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 3, "marginBottom": 0 }}


                                                                        >

                                                                            <span
                                                                                id="97:172"


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                                            >
                                                                                {item.feedback}
                                                                            </span>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div><div>
                                                                <div
                                                                    id="97:179"



                                                                    style={{ "width": 440, "flexDirection": "row", "alignItems": "flex-end", "justifyContent": "flex-end", "display": "flex", "itemSpacing": 7, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                                >

                                                                    <div>
                                                                        <div
                                                                            id="97:174"



                                                                            style={{
                                                                                "height": 59, "width": 59, "borderRadius": null,
                                                                                "backgroundImage": `url(${item.profilePic})`, "backgroundSize": "contain",
                                                                                "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 3.5
                                                                            }}


                                                                        >



                                                                        </div>
                                                                    </div><div>
                                                                        <div
                                                                            id="97:178"



                                                                            style={{ "height": 45, "flexDirection": "column", "alignItems": "flex-end", "justifyContent": "center", "display": "flex", "itemSpacing": 6, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 3.5, "marginRight": 0 }}


                                                                        >

                                                                            <div>
                                                                                <div
                                                                                    id="97:177"



                                                                                    style={{ "height": 30, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 3 }}


                                                                                >

                                                                                    <span
                                                                                        id="97:175"


                                                                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "right" }}



                                                                                    >
                                                                                        {item.name}

                                                                                    </span>

                                                                                </div>
                                                                            </div><span
                                                                                id="97:176"


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "10px", "textAlign": "right", "marginTop": 3, "marginBottom": 0 }}



                                                                            >
                                                                                {item.place}

                                                                            </span>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>

                                            })}
                                        </ScrollMenu>

                                    </div>




                                    <div>
                                        <div
                                            id="97:384"
                                            className='pointer'

                                            onClick={() => {
                                                goToNext(feedbackHSref)
                                            }}

                                            style={{ "height": 80, "width": 80, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/97-384.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 45, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="98:399"



                            style={{ "width": 1280, "backgroundColor": "rgba(246,241,236,1)", "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 60, "paddingLeft": 160, "paddingRight": 0, "paddingTop": 120, "paddingBottom": 120 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("97:388", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="97:388"
                                    className="hidden"

                                    style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 0, "marginBottom": 30, display: 'inline-block' }}



                                >
                                    Let it be social

                                </span>
                            </InView>
                            <div>
                                <div
                                    id="98:394"



                                    style={{
                                        "width": 1275, "flexDirection": "row", "alignItems": "flex-start",
                                        "itemSpacing": 70, "paddingLeft": 0,
                                        "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 30, "marginBottom": 30
                                    }}


                                >
                                    <ScrollMenu
                                        apiRef={socialHSref}
                                        scrollContainerClassName="scrollmenu"


                                    >

                                        {socialList.map((item, index) => {

                                            return <>
                                                <div>
                                                    <div
                                                        id="98:389"



                                                        style={{ "height": 314, "width": 314, "borderRadius": null, "backgroundImage": `url(${item.url})`, "backgroundSize": "cover", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 35 }}


                                                    >



                                                    </div>
                                                </div>
                                            </>

                                        })}
                                    </ScrollMenu>



                                </div>
                            </div>
                            <div>
                                <div
                                    id="98:398"



                                    style={{ "width": 1182, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 800, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 30, "marginBottom": 0 }}


                                >

                                    <span
                                        id="98:393"


                                        style={{ "fontFamily": "Open Sans", "fontSize": 36, "fontWeight": 300, "color": "rgba(38,50,56,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginLeft": 0, "marginRight": 400 }}



                                    >
                                        #chefsnearby

                                    </span><div>
                                        <div
                                            id="98:397"



                                            style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 400, "marginRight": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="98:395"

                                                    className="pointer"
                                                    onClick={() => {
                                                        goToPrev(socialHSref)
                                                    }}

                                                    style={{ "height": 80, "width": 80, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/98-395.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0 }}


                                                >



                                                </div>
                                            </div><div>
                                                <div
                                                    id="98:396"

                                                    className="pointer"
                                                    onClick={() => {
                                                        goToNext(socialHSref)
                                                    }}

                                                    style={{ "height": 80, "width": 80, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/98-396.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="Contact us"



                            style={{ "width": 1340, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 100, "paddingRight": 0, "paddingTop": 100, "paddingBottom": 100 }}


                        >

                            <span
                                id="98:400"


                                style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "75px", "textAlign": "left", "marginTop": 0 }}



                            >
                                Contact us

                            </span><div>
                                <div
                                    id="99:413"



                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 60, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginBottom": 0 }}


                                >

                                    <div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("98:401", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="98:401"

                                                className="hidden"

                                                style={{ "height": 459, "width": 688, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/98-401.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 30 }}


                                            >



                                            </div>
                                        </InView>
                                    </div><div>
                                        <div
                                            id="98:412"



                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 30, "marginRight": 0 }}


                                        >

                                            <div>
                                                <input
                                                    id="name-input1"

                                                    onChange={(event) => { contactUsform("name", event.target.value) }}
                                                    style={{
                                                        "height": 36, "width": 500, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 20,
                                                        "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(210,210,210,1)",
                                                        "borderWidth": 1, "borderStyle": "solid", "marginTop": 15, "marginBottom": 15,


                                                        "fontFamily": "Open Sans", "fontSize": 20,
                                                        "fontWeight": 300, "color": "rgba(142,142,142,1)",
                                                        "letterSpacing": 0, "lineHeight": "15px",
                                                        "textAlign": "left"
                                                    }}

                                                    placeholder="Name"

                                                >



                                                </input>
                                            </div><div>
                                                <input
                                                    id="98:404"

                                                    onChange={(event) => { contactUsform("email", event.target.value) }}

                                                    style={{
                                                        "height": 36, "width": 500, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 20,
                                                        "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(210,210,210,1)",
                                                        "borderWidth": 1, "borderStyle": "solid", "marginTop": 15, "marginBottom": 15,


                                                        "fontFamily": "Open Sans", "fontSize": 20,
                                                        "fontWeight": 300, "color": "rgba(142,142,142,1)",
                                                        "letterSpacing": 0, "lineHeight": "15px",
                                                        "textAlign": "left"
                                                    }}

                                                    placeholder="Email"

                                                >



                                                </input>
                                            </div><div>
                                                <input
                                                    id="98:406"



                                                    style={{
                                                        "height": 36, "width": 500, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 20,
                                                        "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(210,210,210,1)",
                                                        "borderWidth": 1, "borderStyle": "solid", "marginTop": 15, "marginBottom": 15,


                                                        "fontFamily": "Open Sans", "fontSize": 20,
                                                        "fontWeight": 300, "color": "rgba(142,142,142,1)",
                                                        "letterSpacing": 0, "lineHeight": "15px",
                                                        "textAlign": "left"
                                                    }}

                                                    placeholder="Phone"
                                                    onChange={(event) => { contactUsform("phone", event.target.value) }}
                                                >


                                                </input>
                                            </div><div>
                                                <textarea
                                                    id="98:408"



                                                    style={{
                                                        "height": 123, "width": 500, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 20,
                                                        "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(210,210,210,1)",
                                                        "borderWidth": 1, "borderStyle": "solid", "marginTop": 15, "marginBottom": 15,


                                                        "fontFamily": "Open Sans", "fontSize": 20,
                                                        "fontWeight": 300, "color": "rgba(142,142,142,1)",
                                                        "letterSpacing": 0, "lineHeight": "25px",
                                                        "textAlign": "left"
                                                    }}

                                                    placeholder="Message"

                                                    onChange={(event) => { contactUsform("message", event.target.value) }}
                                                >



                                                </textarea>
                                            </div><div>
                                                <div
                                                    id="98:411"

                                                    onClick={() => { submitContactUs(); alertActive == true ? setAlert(true) : setAlert(false); sucessAlertActive == true ? setSucessAlert(true) : setSucessAlert(false) }}

                                                    style={{ "height": 36, "width": 510, "borderRadius": 8, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 15, "marginBottom": 0, cursor: 'pointer' }}


                                                >

                                                    <span
                                                        id="98:410"


                                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "15px", "textAlign": "left" }}



                                                    >
                                                        SUBMIT

                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        id="FAQ"
                    >
                        <div
                            id="99:943"



                            style={{ "width": 1440, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 100, "paddingBottom": 150 }}


                        >

                            <span
                                id="99:415"


                                style={{ "fontFamily": "Open Sans", "fontSize": 48, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 20 }}



                            >
                                Frequently asked questions

                            </span><div>
                                <div
                                    id="99:942"



                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 45, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 20, "marginBottom": 0 }}


                                >
                                    {
                                        frequentlyAskedQuestion.map((item, index) => {
                                            return <>
                                                <Accordion sx={{ boxShadow: 'none' }} style={{ "width": 882, "backgroundColor": "rgba(255,255,255,1)", "paddingLeft": 20, "paddingRight": 50, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(0,0,0,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 0, "marginBottom": 45 }}
                                                    expanded={expanded === 'panel' + String(index)} onChange={handleChange('panel' + String(index))}>
                                                    <AccordionSummary
                                                        expandIcon={<div style={{ "height": 17, "width": 23, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)" }}></div>}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                        <span

                                                            className=""


                                                            style={{ "fontFamily": "Montserrat", "fontSize": 24, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.36, "lineHeight": "30px", "textAlign": "left" }}

                                                        >
                                                            {item.question}

                                                        </span>
                                                        {/* <Typography sx={{ fontSize flexShrink: 0 }}>
                                                                    What are the requirements for being a chef ?
                                                                </Typography> */}

                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <p

                                                            className=""


                                                            style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "lineHeight": "30px", "marginLeft": 0, textAlign: 'left' }}



                                                        >
                                                            {item.answer}

                                                        </p>

                                                    </AccordionDetails>
                                                </Accordion>
                                            </>
                                        })
                                    }
                                    {/* <div>
                                        <div
                                            id="99:416"



                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 45, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 22.5 }}


                                        >

                                            <div>
                                                <div
                                                    id="99:417"



                                                    style={{ "height": 30, "width": 882, "backgroundColor": "rgba(255,255,255,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 300, "paddingLeft": 20, "paddingRight": 50, "paddingTop": 20, "paddingBottom": 20, "borderColor": "rgba(0,0,0,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 0, "marginBottom": 22.5 }}


                                                >
                                                    

                                                    <div>
                                                        <div
                                                            id="99:979"



                                                            style={{ "width": 543, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 150 }}


                                                        >

                                                            <span
                                                                id="99:418"


                                                                style={{ "fontFamily": "Montserrat", "fontSize": 24, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.36, "lineHeight": "30px", "textAlign": "left" }}



                                                            >
                                                                What are the requirements for being a driver ?

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="99:419"



                                                            style={{ "height": 17, "width": 24, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-419.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 150, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="99:420"



                                                    style={{ "height": 30, "width": 882, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 516, "paddingLeft": 20, "paddingRight": 50, "paddingTop": 20, "paddingBottom": 20, "borderColor": "rgba(0,0,0,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 22.5, "marginBottom": 22.5 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="99:980"



                                                            style={{ "width": 341, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 258 }}


                                                        >

                                                            <span
                                                                id="99:421"


                                                                style={{ "fontFamily": "Montserrat", "fontSize": 24, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.36, "lineHeight": "30px", "textAlign": "left" }}



                                                            >
                                                                What documents do i need ?

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="99:422"



                                                            style={{ "height": 17, "width": 24, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-419.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 258, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="99:423"



                                                    style={{ "height": 30, "width": 882, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 533, "paddingLeft": 20, "paddingRight": 50, "paddingTop": 20, "paddingBottom": 20, "borderColor": "rgba(0,0,0,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 22.5, "marginBottom": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="99:981"



                                                            style={{ "width": 318, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 266.5 }}


                                                        >

                                                            <span
                                                                id="99:424"


                                                                style={{ "fontFamily": "Montserrat", "fontSize": 24, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.36, "lineHeight": "30px", "textAlign": "left" }}



                                                            >
                                                                What insuranse do i need ?

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="99:425"



                                                            style={{ "height": 17, "width": 24, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-419.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 266.5, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="99:669"



                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 45, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 22.5, "marginBottom": 22.5 }}


                                        >

                                            <div>
                                                <div
                                                    id="99:670"



                                                    style={{ "width": 882, "backgroundColor": "rgba(255,255,255,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 300, "paddingLeft": 20, "paddingRight": 50, "paddingTop": 20, "paddingBottom": 20, "borderColor": "rgba(0,0,0,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 0, "marginBottom": 22.5 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="99:982"



                                                            style={{ "width": 543, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 150 }}


                                                        >

                                                            <span
                                                                id="99:671"


                                                                style={{ "fontFamily": "Montserrat", "fontSize": 24, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.36, "lineHeight": "30px", "textAlign": "left" }}



                                                            >
                                                                What are the requirements for being a driver ?

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="99:672"



                                                            style={{ "height": 17, "width": 24, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-419.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 150, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="99:673"



                                                    style={{ "width": 882, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 516, "paddingLeft": 20, "paddingRight": 50, "paddingTop": 20, "paddingBottom": 20, "borderColor": "rgba(0,0,0,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 22.5, "marginBottom": 22.5 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="99:983"



                                                            style={{ "width": 341, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 258 }}


                                                        >

                                                            <span
                                                                id="99:674"


                                                                style={{ "fontFamily": "Montserrat", "fontSize": 24, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.36, "lineHeight": "30px", "textAlign": "left" }}



                                                            >
                                                                What documents do i need ?

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="99:675"



                                                            style={{ "height": 17, "width": 24, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-419.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 258, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="99:676"



                                                    style={{ "width": 882, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 533, "paddingLeft": 20, "paddingRight": 50, "paddingTop": 20, "paddingBottom": 20, "borderColor": "rgba(0,0,0,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 22.5, "marginBottom": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="99:984"



                                                            style={{ "width": 318, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 266.5 }}


                                                        >

                                                            <span
                                                                id="99:677"


                                                                style={{ "fontFamily": "Montserrat", "fontSize": 24, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.36, "lineHeight": "30px", "textAlign": "left" }}



                                                            >
                                                                What insuranse do i need ?

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="99:678"



                                                            style={{ "height": 17, "width": 24, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-419.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 266.5, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div> */}
                                    {/* <div>
                                        <div
                                            id="99:932"



                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 45, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 22.5, "marginBottom": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="99:933"



                                                    style={{ "width": 882, "backgroundColor": "rgba(255,255,255,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 300, "paddingLeft": 20, "paddingRight": 50, "paddingTop": 20, "paddingBottom": 20, "borderColor": "rgba(0,0,0,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 0, "marginBottom": 22.5 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="99:985"



                                                            style={{ "width": 543, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 150 }}


                                                        >

                                                            <span
                                                                id="99:934"


                                                                style={{ "fontFamily": "Montserrat", "fontSize": 24, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.36, "lineHeight": "30px", "textAlign": "left" }}



                                                            >
                                                                What are the requirements for being a driver ?

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="99:935"



                                                            style={{ "height": 17, "width": 24, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-419.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 150, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="99:936"



                                                    style={{ "width": 882, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 516, "paddingLeft": 20, "paddingRight": 50, "paddingTop": 20, "paddingBottom": 20, "borderColor": "rgba(0,0,0,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 22.5, "marginBottom": 22.5 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="99:986"



                                                            style={{ "width": 341, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 258 }}


                                                        >

                                                            <span
                                                                id="99:937"


                                                                style={{ "fontFamily": "Montserrat", "fontSize": 24, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.36, "lineHeight": "30px", "textAlign": "left" }}



                                                            >
                                                                What documents do i need ?

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="99:938"



                                                            style={{ "height": 17, "width": 24, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-419.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 258, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="99:939"



                                                    style={{ "width": 882, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 533, "paddingLeft": 20, "paddingRight": 50, "paddingTop": 20, "paddingBottom": 20, "borderColor": "rgba(0,0,0,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 22.5, "marginBottom": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="99:987"



                                                            style={{ "width": 318, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 266.5 }}


                                                        >

                                                            <span
                                                                id="99:940"


                                                                style={{ "fontFamily": "Montserrat", "fontSize": 24, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.36, "lineHeight": "30px", "textAlign": "left" }}



                                                            >
                                                                What insuranse do i need ?

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="99:941"



                                                            style={{ "height": 17, "width": 24, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-419.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 266.5, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div> */}

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="99:976"



                            style={{ "width": 1440, "backgroundColor": "rgba(184,50,38,1)", "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 120, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 100, "paddingBottom": 80 }}


                        >

                            <div>
                                <div
                                    id="99:944"



                                    style={{ "height": 149, "width": 101, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-944.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 60, opacity: 1 }}


                                >



                                </div>
                            </div><div>
                                <div
                                    id="99:974"



                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 60, "marginRight": 60 }}


                                >
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // alert(percent)
                                            setactive("Favourite Dish")
                                            var element = document.getElementById("Favourite Dish");
                                            var headerOffset = 180;
                                            var elementPosition = element.getBoundingClientRect().top;
                                            elementPosition = elementPosition * props.percent / 100
                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                            // offsetPosition = offsetPosition * percent / 100
                                            // alert(elementPosition)
                                            window.scrollTo({
                                                top: offsetPosition,
                                                behavior: "smooth"
                                            });
                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                        }}
                                    >
                                        <span
                                            id="99:945"

                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left", "marginTop": 0 }}



                                        >
                                            Favourite Dish

                                        </span>

                                    </div>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // alert(percent)
                                            setactive("Super Chef")
                                            var element = document.getElementById("Super Chef");
                                            var headerOffset = 180;
                                            var elementPosition = element.getBoundingClientRect().top;
                                            elementPosition = elementPosition * props.percent / 100
                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                            // offsetPosition = offsetPosition * percent / 100
                                            // alert(elementPosition)
                                            window.scrollTo({
                                                top: offsetPosition,
                                                behavior: "smooth"
                                            });
                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                        }}
                                    >
                                        <span
                                            id="99:946"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left" }}



                                        >
                                            Super Chef

                                        </span>
                                    </div>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // alert(percent)
                                            setactive("Make a wish")
                                            var element = document.getElementById("Make a wish");
                                            var headerOffset = 180;
                                            var elementPosition = element.getBoundingClientRect().top;
                                            elementPosition = elementPosition * props.percent / 100
                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                            // offsetPosition = offsetPosition * percent / 100
                                            // alert(elementPosition)
                                            window.scrollTo({
                                                top: offsetPosition,
                                                behavior: "smooth"
                                            });
                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                        }}
                                    >
                                        <span
                                            id="99:947"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left" }}



                                        >
                                            Make a wish

                                        </span>
                                    </div>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // alert(percent)
                                            setactive("Pre Order")
                                            var element = document.getElementById("Pre Order");
                                            var headerOffset = 180;
                                            var elementPosition = element.getBoundingClientRect().top;
                                            elementPosition = elementPosition * props.percent / 100
                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                            // offsetPosition = offsetPosition * percent / 100
                                            // alert(elementPosition)
                                            window.scrollTo({
                                                top: offsetPosition,
                                                behavior: "smooth"
                                            });
                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                        }}
                                    >
                                        <span
                                            id="99:948"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left" }}



                                        >
                                            Pre Order

                                        </span>
                                    </div>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // alert(percent)
                                            setactive("How it works")
                                            var element = document.getElementById("How it works");
                                            var headerOffset = 180;
                                            var elementPosition = element.getBoundingClientRect().top;
                                            elementPosition = elementPosition * props.percent / 100
                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                            // offsetPosition = offsetPosition * percent / 100
                                            // alert(elementPosition)
                                            window.scrollTo({
                                                top: offsetPosition,
                                                behavior: "smooth"
                                            });
                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                        }}
                                    >
                                        <span
                                            id="99:949"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left" }}



                                        >
                                            How it works

                                        </span>
                                    </div>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // alert(percent)
                                            setactive("About us")
                                            var element = document.getElementById("About us");
                                            var headerOffset = 180;
                                            var elementPosition = element.getBoundingClientRect().top;
                                            elementPosition = elementPosition * props.percent / 100
                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                            // offsetPosition = offsetPosition * percent / 100
                                            // alert(elementPosition)
                                            window.scrollTo({
                                                top: offsetPosition,
                                                behavior: "smooth"
                                            });
                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                        }}
                                    >
                                        <span
                                            id="99:951"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left", "marginBottom": 0 }}



                                        >
                                            About us

                                        </span>
                                    </div>
                                </div>
                            </div><div>
                                <div
                                    id="99:975"



                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 60, "marginRight": 60 }}


                                >
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { navigateToTerms(); }}
                                    >
                                        <span
                                            id="99:954"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left", "marginTop": 0 }}



                                        >
                                            Terms & Conditions

                                        </span>
                                    </div>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { navigateToPrivacy(); }}
                                    >
                                        <span
                                            id="99:955"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left" }}



                                        >
                                            Privacy Policy

                                        </span>
                                    </div>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // alert(percent)
                                            setactive("Pertner with us")
                                            var element = document.getElementById("Pertner with us");
                                            var headerOffset = 180;
                                            var elementPosition = element.getBoundingClientRect().top;
                                            elementPosition = elementPosition * props.percent / 100
                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                            // offsetPosition = offsetPosition * percent / 100
                                            // alert(elementPosition)
                                            window.scrollTo({
                                                top: offsetPosition,
                                                behavior: "smooth"
                                            });
                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                        }}
                                    >
                                        <span
                                            id="99:956"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left" }}



                                        >
                                            Partner with us

                                        </span>
                                    </div>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // alert(percent)
                                            setactive("FAQ")
                                            var element = document.getElementById("FAQ");
                                            var headerOffset = 180;
                                            var elementPosition = element.getBoundingClientRect().top;
                                            elementPosition = elementPosition * props.percent / 100
                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                            // offsetPosition = offsetPosition * percent / 100
                                            // alert(elementPosition)
                                            window.scrollTo({
                                                top: offsetPosition,
                                                behavior: "smooth"
                                            });
                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                        }}
                                    >
                                        <span
                                            id="99:957"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left" }}



                                        >
                                            FAQ

                                        </span>
                                    </div>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // alert(percent)
                                            setactive("Contact us")
                                            var element = document.getElementById("Contact us");
                                            var headerOffset = 180;
                                            var elementPosition = element.getBoundingClientRect().top;
                                            elementPosition = elementPosition * props.percent / 100
                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                            // offsetPosition = offsetPosition * percent / 100
                                            // alert(elementPosition)
                                            window.scrollTo({
                                                top: offsetPosition,
                                                behavior: "smooth"
                                            });
                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                        }}
                                    >
                                        <span
                                            id="99:958"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left", "marginBottom": 0 }}



                                        >
                                            Contact us

                                        </span>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="99:973"



                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 60, "marginRight": 0 }}


                                >

                                    <div>
                                        <div
                                            id="99:972"



                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                        >

                                            <span
                                                id="99:960"


                                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "50px", "textAlign": "left", "marginTop": 0, "marginBottom": 5 }}



                                            >
                                                Connect with us

                                            </span><div>
                                                <div
                                                    id="99:965"



                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="99:961"

                                                            onClick={() => { window.open("https://www.facebook.com/profile.php?id=100090737110736&mibextid=ZbWKwL"); }}

                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-961.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 10, cursor: "pointer" }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="99:962"

                                                            onClick={() => { window.open("https://twitter.com/chefs_nearby"); }}

                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-962.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 10, cursor: "pointer" }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="99:963"


                                                            onClick={() => { window.open("https://instagram.com/chefs.nearby?igshid=ZDdkNTZiNTM="); }}
                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-963.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 0, cursor: "pointer" }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <div
                                            id="99:970"



                                            style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                        >

                                            <div>
                                                <div style={{ backgroundColor: "#fff", width: 120, height: 45, position: 'relative', marginTop: 5 }}>

                                                    <div
                                                        id="99:968"



                                                        style={{
                                                            "height": 52, "width": 148, "borderRadius": null,
                                                            "backgroundImage": `url(${AppStore})`, "backgroundSize": "contain",
                                                            "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": -5,
                                                            "marginRight": 0, position: 'absolute', top: -5
                                                        }}


                                                    >



                                                    </div>
                                                </div>
                                            </div><div>
                                                <div
                                                    id="99:969"



                                                    style={{ "height": 52, "width": 176, "borderRadius": null, "backgroundImage": `url(${Google_play})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 20, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="99:978"



                            style={{ "height": 115, "width": 1420, "backgroundColor": "rgba(112,32,25,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginBottom": 0 }}


                        >

                            <div>
                                <div
                                    id="102:1031"



                                    style={{ "width": 742, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10 }}


                                >

                                    <span
                                        id="99:977"


                                        style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "center" }}



                                    >
                                        By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy and Content Policies. All trademarks are properties of their respective owners. 2021 © Chefs near by . All rights reserved

                                    </span>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            {
                alert == true ?
                    <div style={{ position: 'fixed', bottom: 80, width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Alert sx={{ width: '90%' }} onClose={() => { setAlert(false) }} variant="filled" severity="error">{alertmessage}</Alert>
                    </div>
                    :
                    null
            }
            {
                sucessAlert == true ?
                    <div style={{ position: 'fixed', bottom: 80, width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Alert sx={{ width: '90%' }} onClose={() => { setSucessAlert(false) }} variant="filled" severity="success">{successmessage}</Alert>
                    </div>
                    :
                    null
            }


        </>
    )
}

export default View1