import React from 'react'


function Privacy() {
    return (



        <div style={{ backgroundColor: '#eee', justifyContent: 'center', display: 'flex' }}>

            <div style={{ width: '800px', }}>
                <iframe style={{ width: '100%', height: '100vh', border: 0 }} src="https://docs.google.com/document/d/e/2PACX-1vQu-0AbTaV3z3qficA_D7bmhv7ky3woNJjp-vNBrk_wmGcH_OPcarvZU3xNGZAJWw/pub?embedded=true"></iframe>
            </div>
        </div>
    )
}

export default Privacy