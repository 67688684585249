import React from 'react'


function Terms() {
    return (
        <div style={{ backgroundColor: '#eee', justifyContent: 'center', display: 'flex' }}>

            <div style={{ width: '800px', }}>
                <iframe style={{ width: '100%', height: '100vh', border: 0 }} src="https://docs.google.com/document/d/e/2PACX-1vQHdm58JV-o8Q0p5HBdMxg3hjOf_A2zJ7o4SvJksExI_O5EwonsWJCGtFsTuw6Nvg/pub?embedded=true"></iframe>
            </div>
        </div>
    )
}

export default Terms