import { alertActive, alertmessage, cheflist, contactUs, customerFeedback, frequentlyAskedQuestion, isCookingYourHobby, OrderFromOurKitchenSchedule, socialList, submitContactUs, successmessage, sucessAlertActive, superChef, superChefList, topNav } from "../DataLayer/data"
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useRef, useState } from "react";
import '../hover.css';
import validator from 'validator';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { InView, useInView } from 'react-intersection-observer';
import Alert from '@mui/material/Alert'
import AppStore from '../Image/App_store.png'
import Google_play from '../Image/Google_play.png'
import Instagram from '../Image/Instagram-Negative.png'
import Twitter from '../Image/Twitter-Negative.png'
import Facebook from '../Image/Vector.png'
import Cross from '../Image/close.png'
import { Routes, Route, useNavigate } from 'react-router-dom';

var revealEffectConfig = {
    // "161:362": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "161:367": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "161:372": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "161:376": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "161:159": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "161:165": [
    //     "animate__animated",
    //     "animate__rollIn"
    // ],
    // "164:429": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "161:193": [
    //     "animate__animated",
    //     "animate__fadeInBottomRight"
    // ],
    // "161:195": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "161:228": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "161:231": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "161:235": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "161:237": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "161:241": [
    //     "animate__animated",
    //     "animate__flip"
    // ],
    // "161:245": [
    //     "animate__animated",
    //     "animate__rollIn"
    // ],
    // "161:260": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "161:275": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "167:462": [
    //     "animate__animated",
    //     "animate__rollIn"
    // ]
}
const View2 = (props) => {

    const navigate = useNavigate();

    const navigateToPrivacy = () => {
        // 👇️ navigate to /contacts
        window.open(
            "/privacy", "_blank");
    };

    const navigateToTerms = () => {
        // 👇️ navigate to /contacts
        window.open(
            "/terms", "_blank");
    };

    const [active, setactive] = useState("Favourite Dish")
    const [drawer, setDrawer] = useState(false)
    const [triggerOnce, settriggerOnce] = useState(false)
    const [revealDelay, setrevealDelay] = useState(300)
    const [revealThreshold, setrevealThreshold] = useState(0)
    const [alert, setAlert] = useState(false)
    const [sucessAlert, setSucessAlert] = useState(false)
    const socialHSref = useRef()
    const feedbackHSref = useRef()
    const chefHSref = useRef()
    const kitchenScheduleHSref = useRef()
    const superChef = useRef();
    const [expanded, setExpanded] = useState(false);

    const [howKitchenWorkPopup, setHowKitchenWorkPopup] = useState(false)
    const [howMakeWishVid, setHowMakeWishvid] = useState(false)
    const [header, setHeader] = useState(true)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const updateVisibility = (id, visible) => {
        var el = document.getElementById(id)
        // alert(visible)


        if (visible) {
            el.classList.add("animate__animated")

            el.classList.add(revealEffectConfig[id][1])
            el.classList.remove("hidden")

        } else {
            el.classList.remove("animate__animated")

            el.classList.remove(revealEffectConfig[id][1])
            el.classList.add("hidden")



        }
    }

    const goToNext = (scrollRef) => {
        var nextItem = scrollRef.current.getNextItem()
        // alert(JSON.stringify(nextItem))
        if (scrollRef.current.isLastItemVisible) {

            nextItem = scrollRef.current.getItemByIndex(0)
        }
        scrollRef.current.scrollToItem(
            nextItem,
            // OR if you not sure about id for first item
            // scrollRef.current.getItemById(scrollRef.current.items.toItems()[nextItem.index]),
            "smooth",
            "start"
        );
    }

    const goToPrev = (scrollRef) => {
        var prevItem = scrollRef.current.getPrevItem()

        scrollRef.current.scrollToItem(
            prevItem,
            // OR if you not sure about id for first item
            // scrollRef.current.getItemById(scrollRef.current.items.toItems()[nextItem.index]),
            "smooth",
            "start"
        );
    }

    const contactUsform = (id, val) => {
        contactUs[id] = val
    }

    const HeaderAction = (val) => {
        setHeader(val)

    }


    return (
        <>

            <div>
                <div
                    id="161:129"
                    className=""

                    style={{ "height": 13863, "width": 900, "backgroundColor": "rgba(255,255,255,1)", "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                >

                    <div>
                        <div
                            id="161:130"
                            className=""

                            style={{
                                "width": 900, "flexDirection": "row", "alignItems": "center",
                                "justifyContent": "center", "display": "flex", "itemSpacing": 420, "paddingLeft": 0,
                                "paddingRight": 0, "paddingTop": 30, "paddingBottom": 20, "marginTop": 0,
                                position: header ? 'fixed' : null, backgroundColor: "#fff", zIndex: drawer ? 0 : 100000, borderBottom: '2px solid #ccc',
                            }}


                        >

                            <div>
                                <div
                                    id="161:131"
                                    className=""

                                    style={{ "height": 66, "width": 171.5999755859375, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-131.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 210 }}


                                >



                                </div>
                            </div><div>
                                <div
                                    id="161:132"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 210, "marginRight": 0 }}


                                >

                                    <div>
                                        <div
                                            id="161:133"
                                            className=""
                                            onClick={() => { window.open("https://www.facebook.com/profile.php?id=100090737110736&mibextid=ZbWKwL"); }}
                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": `url(${Facebook})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 10 }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="161:134"
                                            className=""
                                            onClick={() => { window.open("https://twitter.com/chefs_nearby"); }}
                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": `url(${Twitter})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 10 }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="161:135"
                                            className=""
                                            onClick={() => { window.open("https://instagram.com/chefs.nearby?igshid=ZDdkNTZiNTM="); }}
                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": `url(${Instagram})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 10 }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="161:136"
                                            className=""
                                            onClick={() => { setDrawer(true) }}
                                            style={{ "height": 49, "width": 49, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-136.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 0 }}
                                        >

                                        </div>
                                        <div >
                                            <Drawer
                                                // sx={{position:'fixed'}}
                                                anchor={'right'}
                                                open={drawer}
                                                onClose={() => { setDrawer(false); }}
                                            >
                                                <div style={{}}>
                                                    <Box
                                                        sx={{ width: 250, }}
                                                        role="presentation"
                                                    // onClick={props?.item?.toggleDrawer}
                                                    // onKeyDown={props?.item?.toggleDrawer}
                                                    >
                                                        <div style={{ textAlign: "center" }}>
                                                            <img style={{ width: 150, height: 60, marginTop: 20, marginBottom: 20 }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-131.png'} />
                                                        </div>
                                                        <Divider />
                                                        <List>
                                                            {topNav.map((item, index) => (
                                                                <div
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        // alert(percent)
                                                                        setactive(item)
                                                                        var element = document.getElementById(item);
                                                                        var headerOffset = 106;
                                                                        var elementPosition = element.getBoundingClientRect().top;
                                                                        elementPosition = elementPosition * props.percent / 100
                                                                        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                                        // offsetPosition = offsetPosition * percent / 100
                                                                        // alert(elementPosition)
                                                                        window.scrollTo({
                                                                            top: offsetPosition,
                                                                            behavior: "smooth"
                                                                        });
                                                                        // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
                                                                        setDrawer(false)
                                                                    }}
                                                                >
                                                                    <a style={{ textDecoration: "none", color: "#000", fontWeight: "bold" }}>
                                                                        <ListItem onClick={() => { }} button key={item}>
                                                                            <ListItemText primary={item} />
                                                                        </ListItem>
                                                                    </a>
                                                                </div>
                                                            ))}
                                                        </List>

                                                    </Box>
                                                </div>
                                            </Drawer>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="161:137"
                            className=""

                            style={{
                                "width": 900, "flexDirection": "row", "alignItems": "center",
                                "justifyContent": "center", "display": "flex", "itemSpacing": 20, "paddingLeft": 0,
                                "paddingRight": 0, "paddingTop": 30, "paddingBottom": 30, marginTop: 106
                            }}


                        >

                            <div>
                                <div
                                    id="161:138"
                                    className=""

                                    style={{ "height": 60, "width": 160, "borderRadius": null, "backgroundImage": `url(${Google_play})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 10, "cursor": "" }}


                                >



                                </div>
                            </div><div>
                                <div
                                    id="161:139"
                                    className=""

                                    style={{ "height": 60, "width": 160, "borderRadius": null, "backgroundImage": `url(${AppStore})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 0, "cursor": "pointer" }}


                                >



                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        id="Favourite Dish"
                    >
                        <div
                            id="161:360"
                            className=""

                            style={{ "height": 178.5, "width": 900, "backgroundColor": "rgba(224,66,52,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 100, "paddingBottom": 100 }}


                        >

                            <div>
                                <div
                                    id="161:361"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 10 }}


                                >

                                    <div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("161:362", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="161:362"
                                                className="hidden"

                                                style={{ "height": 196, "width": 213.8182373046875, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/36686bae78cae1a10f5f3f78987440cb.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 5 }}


                                            >



                                            </div>
                                        </InView>
                                    </div><span
                                        id="161:363"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                    >
                                        Your Wish

                                    </span>

                                </div>
                            </div><div>
                                <div
                                    id="161:364"
                                    className=""

                                    style={{ "height": 34.5703125, "width": 22.8299560546875, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-364.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 10 }}


                                >



                                </div>
                            </div><div>
                                <div
                                    id="161:365"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 10, "marginRight": 10 }}


                                >

                                    <div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("161:367", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="161:367"
                                                className="hidden"

                                                style={{ "height": 196, "width": 237.7291259765625, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/f4a7cbfa7ddf925a7f454a78f3750289.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 5 }}


                                            >



                                            </div>
                                        </InView>
                                    </div><span
                                        id="161:368"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                    >
                                        Chef Prepare

                                    </span>

                                </div>
                            </div><div>
                                <div
                                    id="161:369"
                                    className=""

                                    style={{ "height": 34.5703125, "width": 22.830078125, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-369.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 10 }}


                                >



                                </div>
                            </div><div>
                                <div
                                    id="161:370"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 10, "marginRight": 0 }}


                                >

                                    <div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("161:372", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="161:372"
                                                className="hidden"

                                                style={{ "height": 196.408203125, "width": 209.080078125, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/ecf3aeb887ef0bd4e673a5443430f9b5.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 5 }}


                                            >



                                            </div>
                                        </InView>
                                    </div><span
                                        id="161:373"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                    >
                                        Your Dish

                                    </span>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="161:375"
                            className=""

                            style={{ "width": 900, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 45, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 50, "paddingBottom": 50 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("161:376", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="161:376"
                                    className="hidden"


                                    style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "40px", "textAlign": "center", "marginTop": 0, "marginBottom": 22.5, display: 'inline-block' }}



                                >
                                    Order from Our Kitchen schedule

                                </span>
                            </InView>
                            <div>
                                <div
                                    id="161:402"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 22.5, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="161:400"
                                            className=""
                                            onClick={() => { goToPrev(kitchenScheduleHSref) }}
                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-168.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 15 }}


                                        >



                                        </div>
                                    </div>

                                    <div style={{ width: '750px' }} >
                                        <ScrollMenu
                                            apiRef={kitchenScheduleHSref}
                                            scrollContainerClassName="scrollmenu"
                                        >
                                            {
                                                OrderFromOurKitchenSchedule.map((item, index) => {
                                                    return <>
                                                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                                            <div
                                                                id="161:378"
                                                                className=""

                                                                style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0 }}


                                                            >

                                                                <div>
                                                                    <div
                                                                        id="161:379"
                                                                        className=""

                                                                        style={{ "height": 285.279296875, "width": 285.280029296875, "borderRadius": null, "backgroundImage": `url(${item.image})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 5 }}


                                                                    >



                                                                    </div>
                                                                </div><div>
                                                                    <div
                                                                        id="161:380"
                                                                        className=""

                                                                        style={{ "height": 86, "width": 295, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                                    >

                                                                        <span
                                                                            id="161:381"
                                                                            className=""


                                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 600, "color": "rgba(74,74,74,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "center" }}



                                                                        >
                                                                            {item.desc}

                                                                        </span>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {
                                                                item.next == true ?
                                                                    <div>
                                                                        <div
                                                                            id="161:382"
                                                                            className=""

                                                                            style={{ "height": 57, "width": 126.9998779296875, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/00b6/b1ba/920e563c183be78b0bee424544008246?Expires=1668384000&Signature=RcIyXRX3GAPhQptEvtRqGthrA1BZqLeWEjBGqHt3VJC24ahd-tRw216Stp264YdRDZGX~0wwct9YOAYX47MoL7JGmDsROyBFyKNzlVGkhrBQJEO8cq~8gKYZzcRI0sYVorSCN-WxCGrradJDVNx2ujGWHj1HZ5dBnQXecH74n~dw1hMJ94HaQFLo8oC9nEW6IfyMi8o5aAAwlTWwaxSPCUseddwTYx7CYylKm-Rm1ON8JIfM~o1njJPYk76s-~ODzXKxP9vBTIGnoGBW2oMiPnFkDbT4ihKH4xCB22O-zLoczrgdBDL2eP03gzMLqrua~SzFhsDqiRWCxQh1Z9RbgA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                                        >



                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                            {index == 0 && <div
                                                                id="90:111"



                                                                style={{ "height": 52, "width": 125, marginLeft: '-15px', "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-111.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginRight": -6, marginBottom: '100px', marginLeft: '-120px' }}


                                                            >



                                                            </div>
                                                            }
                                                            {
                                                                index == 1 && <div
                                                                    id="90:111"



                                                                    style={{ "height": 52, "width": 125, marginLeft: '-15px', "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-116.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginRight": -6, marginBottom: '100px', marginLeft: '-120px' }}


                                                                >



                                                                </div>
                                                            }
                                                            {
                                                                index == 2 && <div
                                                                    id="90:111"



                                                                    style={{ "height": 52, "width": 125, marginLeft: '-15px', "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-121.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginRight": -6, marginBottom: '100px', marginLeft: '-120px' }}


                                                                >



                                                                </div>
                                                            }

                                                        </div>
                                                    </>
                                                })
                                            }
                                        </ScrollMenu>
                                        {/* <div
                                            id="161:377"
                                            className=""

                                            style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 15, "marginRight": 15 }}


                                        >

                                            <div>
                                                <div
                                                    id="161:378"
                                                    className=""

                                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="161:379"
                                                            className=""

                                                            style={{ "height": 285.279296875, "width": 285.280029296875, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/d6986b24a0829de3870e0d56a5de7428.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 5 }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="161:380"
                                                            className=""

                                                            style={{ "height": 86, "width": 295, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                        >

                                                            <span
                                                                id="161:381"
                                                                className=""


                                                                style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 600, "color": "rgba(74,74,74,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "center" }}



                                                            >
                                                                Chef Prepares his/her schedule and upload the dishes and quantity for the next 5 days

                                                            </span>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="161:382"
                                                    className=""

                                                    style={{ "height": 57, "width": 126.9998779296875, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-382.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                >



                                                </div>
                                            </div><div>
                                                <div
                                                    id="161:383"
                                                    className=""

                                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginRight": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="161:384"
                                                            className=""

                                                            style={{ "height": 275, "width": 265.280029296875, "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 5 }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="161:385"
                                                                    className=""

                                                                    style={{ "height": 256, "width": 276, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/9cac9ef8e742c254a7952f43a2095683.jpg)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                                >



                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="161:386"
                                                            className=""

                                                            style={{ "width": 245, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                        >

                                                            <span
                                                                id="161:387"
                                                                className=""


                                                                style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 600, "color": "rgba(74,74,74,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "center" }}



                                                            >
                                                                Chef publishes his schedule and shares the links and QR code with customers

                                                            </span>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>*/}
                                    </div>
                                    <div>
                                        <div
                                            id="161:401"
                                            className=""
                                            onClick={() => { goToNext(kitchenScheduleHSref) }}
                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-172.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 15, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="161:157"
                            className=""

                            style={{ "width": 900, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 15, "paddingBottom": 40 }}


                        >

                            <div>
                                <div
                                    id="161:158"
                                    className=""

                                    style={{ "width": 416, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                >
                                    <InView
                                        triggerOnce={triggerOnce}
                                        delay={revealDelay}

                                        onChange={(inView, entry) => {
                                            updateVisibility("161:159", inView)
                                        }}

                                        threshold={revealThreshold}

                                    >
                                        <span
                                            id="161:159"
                                            className="hidden"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "34px", "textAlign": "left", display: 'inline-block' }}



                                        >
                                            How Kitchen schedule works

                                        </span>
                                    </InView>
                                </div>
                            </div>
                            <div style={{ "height": 330, "width": 570, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/kitchen+shedule.png)", display: 'flex', alignItems: 'center', justifyContent: 'center', objectFit: "fill", backgroundSize: 'cover' }}>
                                <div style={{}}
                                    onClick={() => { setHowKitchenWorkPopup(true); HeaderAction(false) }}
                                >
                                    <img style={{ height: 130, width: 130, cursor: 'pointer' }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/play+button.png'} />
                                </div>
                            </div>
                            {
                                howKitchenWorkPopup == true ?
                                    <div style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', top: 0, zIndex: 5000 }}>
                                        <div style={{ display: 'flex' }}>
                                            <video
                                                id="90:132"



                                                style={{
                                                    "height": 'auto', "width": 570,
                                                    "flexDirection": "column", "alignItems": "center", "justifyContent": "center",
                                                    "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10,
                                                    "paddingTop": 10, "paddingBottom": 10, "marginTop": 30, "marginBottom": 0, backgroundColor: 'gray'
                                                }}
                                                autoPlay
                                                controls

                                            >


                                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/kitchenSchedule.mp4" type="video/mp4" />
                                                Your browser does not support HTML video.
                                                <div>
                                                    <div
                                                        id="90:131"



                                                        style={{ "height": 144, "width": 150, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/6620/f4ff/cad63dd24161ccfcd705204c8cf5309b?Expires=1667779200&Signature=AQCfaAEtxv0l0zQ99xQH8-wOd~SiRaSMkHVxj97Ye2Kg7nulZLfPkiMVVhtrzEYWVvvdzYrkNsSwqOLycIadd7PVAlovV55El~nhyRNbpKTeV5dn-DTnhATXXEGO~OphfB-Us0IXAIQkbxe40wURWlhFk16Wjlx2rwEVJrYxsYPaBdpZq3Zkc76FYarcoapaSBQybxYWDyiO7UOhwhSL5h0PN-ujSdtmyFsaPtcvZAj3hRr4JrDxxLIEW2a3JdNR2lFRXwk3Eo5pDraLShDDgXnY499Kf30ZNRHMirlgukKmi8dsEXXEMPKOvye4p9RnZ0R-E-dYW-i7GnlU4EL24g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                    >



                                                    </div>
                                                </div>

                                            </video>
                                            <div onClick={() => { setHowKitchenWorkPopup(false); HeaderAction(true) }} style={{ marginLeft: '-45px', marginTop: 15, cursor: 'pointer' }}>
                                                <img style={{ width: 20, height: 20 }} src={Cross} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {/* <video
                                id="161:160"

                                className=""




                                width="570"

                                controls>
                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/kitchenSchedule.mp4" type="video/mp4" />
                                Your browser does not support HTML video.
                            </video> */}

                        </div>
                    </div>
                    <div
                        id="Super Chef"
                    >
                        <div
                            id="163:416"
                            className=""

                            style={{ "width": 900, "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 60, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 60, "paddingBottom": 60 }}


                        >

                            <div>
                                <div
                                    id="163:415"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 30 }}


                                >

                                    <span
                                        id="161:163"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 20 }}



                                    >
                                        Super Chef

                                    </span><div>
                                        <div
                                            id="163:414"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 25, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 20, "marginBottom": 0 }}


                                        >

                                            <div>
                                                <InView
                                                    triggerOnce={triggerOnce}
                                                    delay={revealDelay}

                                                    onChange={(inView, entry) => {
                                                        updateVisibility("161:165", inView)
                                                    }}

                                                    threshold={revealThreshold}

                                                >
                                                    <div
                                                        id="161:165"
                                                        className="hidden"

                                                        style={{ "height": 330, "width": 500, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-360.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 12.5 }}


                                                    >



                                                    </div>
                                                </InView>
                                            </div><div>
                                                <div
                                                    id="161:410"
                                                    className=""

                                                    style={{ "flexDirection": "row", "alignItems": "flex-end", "justifyContent": "center", "display": "flex", "itemSpacing": 45, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 12.5, "marginBottom": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="161:168"
                                                            className=""
                                                            onClick={() => { goToPrev(superChef) }}
                                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-168.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 22.5, "marginBottom": 150 }}


                                                        >



                                                        </div>
                                                    </div>
                                                    <div style={{ width: 540 }}>
                                                        <ScrollMenu
                                                            apiRef={superChef}
                                                            scrollContainerClassName="scrollmenu"

                                                        >
                                                            {
                                                                superChefList.map((item, index) => {
                                                                    return <>
                                                                        <div>
                                                                            <div
                                                                                id="161:169"
                                                                                className=""

                                                                                style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 5, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 22.5, "marginRight": 22.5 }}


                                                                            >

                                                                                <div>
                                                                                    <div
                                                                                        id="161:170"
                                                                                        className="hvr-grow"

                                                                                        style={{ "height": 165, "width": 136, "borderRadius": null, "backgroundImage": `url(${item.chefImg})`, "backgroundSize": "cover", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 2.5 }}


                                                                                    >



                                                                                    </div>
                                                                                </div><span
                                                                                    id="161:171"
                                                                                    className=""


                                                                                    style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(123,119,119,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "justified", "marginTop": 2.5, "marginBottom": 0 }}



                                                                                >
                                                                                    {item.name}

                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div
                                                                                id="161:173"
                                                                                className="hvr-grow"

                                                                                style={{ "height": 160, "width": 161, "borderRadius": null, "backgroundImage": `url(${item.foodImage})`, "backgroundSize": "cover", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 15, "marginTop": 40 }}


                                                                            >



                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                })
                                                            }

                                                        </ScrollMenu>
                                                    </div>
                                                    {/* <div>
                                                        <div
                                                            id="161:169"
                                                            className=""

                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 5, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 22.5, "marginRight": 22.5 }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="161:170"
                                                                    className="hvr-grow"

                                                                    style={{ "height": 165, "width": 136, "borderRadius": null, "backgroundImage": "url()", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 2.5 }}


                                                                >



                                                                </div>
                                                            </div><span
                                                                id="161:171"
                                                                className=""


                                                                style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(123,119,119,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "justified", "marginTop": 2.5, "marginBottom": 0 }}



                                                            >
                                                                Chef Name

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="161:404"
                                                            className=""

                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 5, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 22.5, "marginRight": 22.5 }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="161:405"
                                                                    className="hvr-grow"

                                                                    style={{ "height": 165, "width": 136, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/8013/8429/5c0525108388859452eb72e0b18944e5?Expires=1668384000&Signature=Bn3-u6JvrLTvehh13Yp-8Ga4BuMfgsbkY65YqMmQI0Nc5JwCcQE8h9Ke5LNiiml0kVjaW5X4tqynaKcMuKShXOBJffjHvhZuz6RQnaaBS7M0QSD7jc4JhH~7HSZXfnk0EcFmugnc2dWHLDzxFafXegUZBx3nKlRjWzDLETZeZhWwa8NYBzQHbByANQjwCPc6wSwzj~Sfi5LNP52fN1V6dIL~SNqyeQy5-yDFCcAT1iPGTeQSjHswo3Z9USTAf6jx4xYt2BqduqkY4JCQMUfWqnNMudew4nSCwzXUhGTiZbMovaME6oMwsigOANFqpmxKgfsty4UcMUP39YCbruoguw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 2.5 }}


                                                                >



                                                                </div>
                                                            </div><span
                                                                id="161:406"
                                                                className=""


                                                                style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(123,119,119,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "justified", "marginTop": 2.5, "marginBottom": 0 }}



                                                            >
                                                                Chef Name

                                                            </span>

                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="161:407"
                                                            className=""

                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 5, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 22.5, "marginRight": 22.5 }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="161:408"
                                                                    className="hvr-grow"

                                                                    style={{ "height": 165, "width": 136, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/8013/8429/5c0525108388859452eb72e0b18944e5?Expires=1668384000&Signature=Bn3-u6JvrLTvehh13Yp-8Ga4BuMfgsbkY65YqMmQI0Nc5JwCcQE8h9Ke5LNiiml0kVjaW5X4tqynaKcMuKShXOBJffjHvhZuz6RQnaaBS7M0QSD7jc4JhH~7HSZXfnk0EcFmugnc2dWHLDzxFafXegUZBx3nKlRjWzDLETZeZhWwa8NYBzQHbByANQjwCPc6wSwzj~Sfi5LNP52fN1V6dIL~SNqyeQy5-yDFCcAT1iPGTeQSjHswo3Z9USTAf6jx4xYt2BqduqkY4JCQMUfWqnNMudew4nSCwzXUhGTiZbMovaME6oMwsigOANFqpmxKgfsty4UcMUP39YCbruoguw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 2.5 }}


                                                                >



                                                                </div>
                                                            </div><span
                                                                id="161:409"
                                                                className=""


                                                                style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(123,119,119,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "justified", "marginTop": 2.5, "marginBottom": 0 }}



                                                            >
                                                                Chef Name

                                                            </span>

                                                        </div>
                                                    </div>
                                                    */}
                                                    <div>
                                                        <div
                                                            id="161:172"
                                                            className=""
                                                            onClick={() => { goToNext(superChef) }}
                                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-172.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 22.5, "marginRight": 0, "marginBottom": 150 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="161:413"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 30, "marginBottom": 30 }}


                                >

                                    {/* <div>
                                        <div
                                            id="161:173"
                                            className="hvr-grow"

                                            style={{ "height": 160, "width": 161, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/6125/0595/0d3ee258261f4dff7bcbcc355f55c682?Expires=1668384000&Signature=FRzN1g0guaVx1FJdYJLjANRTVnbWfyN-3hLkl6QMJloZNQgCupjlAw3uO3DogD5CPKbsVSM73SFNoOdNzQqlOqUYTlU2ykDfvhPhSPL6S86nbTEpF4kWH3JJBm1Z5Gy5xn9haLxjDVYkmvaopZGD-DEYb2NeKlVqUSRSjhhra4er229PhmYjEEQXf2ARxssr6q3RDZh0oZJQvZTZAPYNuxsQRBQeozBOBzEhbId6CZGjf2lsCDsiU5W~oDoVHVRXA65zW8cM1pRVQhA5e9m0qD4hVduuf6urFly3D0UFyBLlQ-dOX3xiuCczrS-zCqcmXl5Pa6px1pGAAtS0jwB1WQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 15 }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="161:411"
                                            className="hvr-grow"

                                            style={{ "height": 160, "width": 161, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/6125/0595/0d3ee258261f4dff7bcbcc355f55c682?Expires=1668384000&Signature=FRzN1g0guaVx1FJdYJLjANRTVnbWfyN-3hLkl6QMJloZNQgCupjlAw3uO3DogD5CPKbsVSM73SFNoOdNzQqlOqUYTlU2ykDfvhPhSPL6S86nbTEpF4kWH3JJBm1Z5Gy5xn9haLxjDVYkmvaopZGD-DEYb2NeKlVqUSRSjhhra4er229PhmYjEEQXf2ARxssr6q3RDZh0oZJQvZTZAPYNuxsQRBQeozBOBzEhbId6CZGjf2lsCDsiU5W~oDoVHVRXA65zW8cM1pRVQhA5e9m0qD4hVduuf6urFly3D0UFyBLlQ-dOX3xiuCczrS-zCqcmXl5Pa6px1pGAAtS0jwB1WQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 15, "marginRight": 15 }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="161:412"
                                            className="hvr-grow"

                                            style={{ "height": 160, "width": 161, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/6125/0595/0d3ee258261f4dff7bcbcc355f55c682?Expires=1668384000&Signature=FRzN1g0guaVx1FJdYJLjANRTVnbWfyN-3hLkl6QMJloZNQgCupjlAw3uO3DogD5CPKbsVSM73SFNoOdNzQqlOqUYTlU2ykDfvhPhSPL6S86nbTEpF4kWH3JJBm1Z5Gy5xn9haLxjDVYkmvaopZGD-DEYb2NeKlVqUSRSjhhra4er229PhmYjEEQXf2ARxssr6q3RDZh0oZJQvZTZAPYNuxsQRBQeozBOBzEhbId6CZGjf2lsCDsiU5W~oDoVHVRXA65zW8cM1pRVQhA5e9m0qD4hVduuf6urFly3D0UFyBLlQ-dOX3xiuCczrS-zCqcmXl5Pa6px1pGAAtS0jwB1WQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 15, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div> */}

                                </div>
                            </div><div>
                                <div
                                    id="161:174"
                                    className="hvr-grow-shadow"

                                    style={{ "height": 62, "width": 332, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 30, "marginBottom": 0 }}


                                >

                                    <span
                                        id="161:175"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25.5px", "textAlign": "center" }}



                                    >
                                        Download Our App Now

                                    </span>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        id="Make a wish"
                    >
                        <div
                            id="164:430"
                            className=""

                            style={{ "width": 900, "backgroundColor": "rgba(244,244,244,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 60, "paddingBottom": 60 }}


                        >

                            <span
                                id="161:177"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15 }}



                            >
                                Make a wish

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("164:429", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="164:429"
                                        className="hidden"

                                        style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 0 }}


                                    >

                                        <div>
                                            <div
                                                id="164:422"
                                                className=""

                                                style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 10 }}


                                            >

                                                <div>
                                                    <div
                                                        id="164:423"
                                                        className=""

                                                        style={{ "width": 513, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                                    >

                                                        <span
                                                            id="163:417"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            Have you been craving to eat your favourite dish from long??

                                                        </span>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="164:424"
                                                        className=""

                                                        style={{ "width": 266, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                                    >

                                                        <span
                                                            id="163:418"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            You don’t know how to make it?

                                                        </span>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="164:425"
                                                        className=""

                                                        style={{ "width": 522, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginBottom": 0 }}


                                                    >

                                                        <span
                                                            id="163:419"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            You are not getting the desired taste when you do it yourself??

                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div><div>
                                            <div
                                                id="164:428"
                                                className=""

                                                style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 10, "marginBottom": 0 }}


                                            >

                                                <div>
                                                    <div
                                                        id="164:426"
                                                        className=""

                                                        style={{ "width": 389, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                                    >

                                                        <span
                                                            id="164:420"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            Here is the solution to satisfy your tastebuds...

                                                        </span>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="164:427"
                                                        className=""

                                                        style={{ "width": 626, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginBottom": 0 }}


                                                    >

                                                        <span
                                                            id="164:421"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            You can make a wish and our expert chefs from our list would prepare it and deliver it to you.

                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="161:191"
                            className=""

                            style={{ "width": 900, "backgroundColor": "rgba(244,244,244,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 20, "paddingBottom": 50 }}


                        >

                            <span
                                id="161:192"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15 }}



                            >
                                Chef near by User Journey

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("161:193", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="161:193"
                                        className="hidden"

                                        style={{ "height": 1414, "width": 433.03759765625, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-193.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 15, "marginBottom": 0 }}


                                    >



                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div>
                    <div
                        id="How it works"
                    >
                        <div
                            id="161:194"
                            className=""

                            style={{ "width": 900, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 60, "paddingBottom": 60 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("161:195", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="161:195"
                                    className="hidden"


                                    style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 25, display: 'inline-block' }}



                                >
                                    How make a wish works

                                </span>
                            </InView>
                            <div style={{ "height": 330, "width": 570, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/make+a+wish.png)", display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundSize: 'cover' }}>
                                <div style={{}}
                                    onClick={() => { setHowMakeWishvid(true); HeaderAction(false) }}
                                >
                                    <img style={{ height: 130, width: 130, cursor: 'pointer' }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/play+button.png'} />
                                </div>
                            </div>
                            {
                                howMakeWishVid == true ?
                                    <div style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', top: 0, zIndex: 5000 }}>
                                        <div style={{ display: 'flex' }}>
                                            <video
                                                id="90:132"



                                                style={{
                                                    "height": 330, "width": 570,
                                                    "flexDirection": "column", "alignItems": "center", "justifyContent": "center",
                                                    "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10,
                                                    "paddingTop": 10, "paddingBottom": 10, "marginTop": 30, "marginBottom": 0, backgroundColor: 'gray'
                                                }}
                                                autoPlay
                                                controls

                                            >


                                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/makeAwish.mp4" type="video/mp4" />
                                                <div>
                                                    <div
                                                        id="90:131"



                                                        style={{ "height": 144, "width": 150, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/6620/f4ff/cad63dd24161ccfcd705204c8cf5309b?Expires=1667779200&Signature=AQCfaAEtxv0l0zQ99xQH8-wOd~SiRaSMkHVxj97Ye2Kg7nulZLfPkiMVVhtrzEYWVvvdzYrkNsSwqOLycIadd7PVAlovV55El~nhyRNbpKTeV5dn-DTnhATXXEGO~OphfB-Us0IXAIQkbxe40wURWlhFk16Wjlx2rwEVJrYxsYPaBdpZq3Zkc76FYarcoapaSBQybxYWDyiO7UOhwhSL5h0PN-ujSdtmyFsaPtcvZAj3hRr4JrDxxLIEW2a3JdNR2lFRXwk3Eo5pDraLShDDgXnY499Kf30ZNRHMirlgukKmi8dsEXXEMPKOvye4p9RnZ0R-E-dYW-i7GnlU4EL24g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                    >



                                                    </div>
                                                </div>

                                            </video>
                                            <div onClick={() => { setHowMakeWishvid(false); HeaderAction(true) }} style={{ marginLeft: '-45px', marginTop: 15, cursor: 'pointer' }}>
                                                <img style={{ width: 20, height: 20 }} src={Cross} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {/* <video
                                id="161:196"

                                className=""




                                width="570"

                                controls>
                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/makeAwish.mp4" type="video/mp4" />
                                Your browser does not support HTML video.
                            </video> */}

                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', "backgroundColor": "rgba(246,240,230,1)", paddingRight: 20, paddingLeft: 20, alignItems: 'center', }}>
                        <div>
                            <div
                                id="161:203"
                                className=""

                                style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": '45px' }}


                            >
                                <div
                                    onClick={() => { goToPrev(chefHSref) }}
                                    style={{ marginLeft: '160px', zIndex: 10000, marginTop: '60px' }}>
                                    <img style={{ "height": 57, "width": 27, }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-203.png'} />
                                </div>


                            </div>
                            <div style={{ height: '310px', marginTop: 420 }}></div>
                        </div>
                        <div style={{ width: 715 }}>
                            <ScrollMenu
                                apiRef={chefHSref}
                                scrollContainerClassName="scrollmenu"

                            >
                                {
                                    cheflist.map((item, index) => {
                                        return <>
                                            <div
                                                id="167:493"
                                                className=""

                                                style={{ "width": 715, "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 60, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 60, "paddingBottom": 100 }}


                                            >

                                                <div>
                                                    <div
                                                        id="167:492"
                                                        className=""

                                                        style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 30 }}


                                                    >

                                                        <span
                                                            id="161:199"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15 }}



                                                        >
                                                            Meet the Chef

                                                        </span><span
                                                            id="161:200"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 15, "marginBottom": 15 }}



                                                        >
                                                            {item.name}

                                                        </span><div>
                                                            <div
                                                                id="161:202"
                                                                className=""

                                                                style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 90, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 0 }}


                                                            >


                                                                <div style={{ width: 230 }}>



                                                                    <div>
                                                                        <div
                                                                            id="161:204"
                                                                            className=""

                                                                            style={{ "height": 280, "width": 240, "borderRadius": null, "backgroundImage": `url(${item.image})`, "backgroundSize": "cover", "backgroundRepeat": "no-repeat", "display": "flex", }}


                                                                        >



                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="167:491"
                                                        className=""

                                                        style={{ "width": 700, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 130, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 30, "marginBottom": 30 }}


                                                    >

                                                        <div>
                                                            <div
                                                                id="167:452"
                                                                className=""

                                                                style={{ "width": 599, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 20 }}


                                                            >

                                                                <span
                                                                    id="161:207"
                                                                    className=""


                                                                    style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                                >
                                                                    {item.desciption}

                                                                </span>

                                                            </div>
                                                        </div><div>
                                                            <div
                                                                id="167:444"
                                                                className=""

                                                                style={{ "width": 600, height: '250px', "borderRadius": 27, "backgroundColor": "rgba(231,231,231,1)", "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 25, "paddingLeft": 15, "paddingRight": 15, "paddingTop": 30, "paddingBottom": 30, "marginTop": 20, "marginBottom": 0, zIndex: 100 }}


                                                            >

                                                                <span
                                                                    id="161:210"
                                                                    className=""


                                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 12.5 }}



                                                                >
                                                                    Siginature dishes

                                                                </span><div>
                                                                    <div
                                                                        id="167:443"
                                                                        className=""

                                                                        style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 15, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 12.5, "marginBottom": 0 }}


                                                                    >

                                                                        <div>
                                                                            <div
                                                                                id="161:212"
                                                                                className=""

                                                                                style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 7.5 }}


                                                                            >

                                                                                <div>
                                                                                    <div
                                                                                        id="161:214"
                                                                                        className="hvr-grow"

                                                                                        style={{ "height": 192, "width": 193.6695556640625, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-214.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0 }}


                                                                                    >



                                                                                    </div>
                                                                                </div><span
                                                                                    id="161:215"
                                                                                    className=""


                                                                                    style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "justified", "marginBottom": 0 }}



                                                                                >
                                                                                    feugiat

                                                                                </span>

                                                                            </div>
                                                                        </div><div>
                                                                            <div
                                                                                id="167:437"
                                                                                className=""

                                                                                style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 7.5, "marginRight": 7.5 }}


                                                                            >

                                                                                <div>
                                                                                    <div
                                                                                        id="167:438"
                                                                                        className="hvr-grow"

                                                                                        style={{ "height": 192, "width": 193.6695556640625, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/167-438.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0 }}


                                                                                    >



                                                                                    </div>
                                                                                </div><span
                                                                                    id="167:439"
                                                                                    className=""


                                                                                    style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "justified", "marginBottom": 0 }}



                                                                                >
                                                                                    feugiat

                                                                                </span>

                                                                            </div>
                                                                        </div><div>
                                                                            <div
                                                                                id="167:440"
                                                                                className=""

                                                                                style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 7.5, "marginRight": 0 }}


                                                                            >

                                                                                <div>
                                                                                    <div
                                                                                        id="167:441"
                                                                                        className="hvr-grow"

                                                                                        style={{ "height": 192, "width": 193.669677734375, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/167-441.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0 }}


                                                                                    >



                                                                                    </div>
                                                                                </div><span
                                                                                    id="167:442"
                                                                                    className=""


                                                                                    style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "justified", "marginBottom": 0 }}



                                                                                >
                                                                                    feugiat

                                                                                </span>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {/* <div>
                                                    <div
                                                        id="161:224"
                                                        className="hvr-grow-shadow"

                                                        style={{ "height": 48, "width": 321, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 30, "marginBottom": 0 }}


                                                    >

                                                        <span
                                                            id="161:225"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left" }}



                                                        >
                                                            Become a Chef

                                                        </span>

                                                    </div>
                                                </div> */}
                                            </div>

                                        </>
                                    })
                                }
                            </ScrollMenu>
                            <div style={{ marginLeft: '200px' }}>
                                <div
                                    id="161:224"
                                    className="hvr-grow-shadow"

                                    style={{ "height": 48, "width": 321, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": -65, "marginBottom": 140 }}


                                >

                                    <span
                                        id="161:225"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left" }}



                                    >
                                        Become a Chef

                                    </span>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                id="161:205"
                                className=""
                                onClick={() => { goToNext(chefHSref) }}
                                style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 45, "marginRight": 0 }}


                            >
                                <div
                                    onClick={() => { goToNext(chefHSref) }}
                                    style={{ marginLeft: '-160px', zIndex: 10000, marginTop: '60px' }}>
                                    <img style={{ "height": 57, "width": 27, }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-205.png'} />
                                </div>


                            </div>
                            <div style={{ height: '310px', marginTop: 420 }}></div>
                            {/* <div style={{width:100, backgroundColor:"rgba(231,231,231,1)", height:'310px', marginTop:420}}> */}

                            {/* </div> */}
                        </div>
                    </div>

                    <div
                        id="Pertner with us"
                    >
                        <div
                            id="161:226"
                            className=""

                            style={{ "width": 900, "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 50, "paddingBottom": 50 }}


                        >

                            <span
                                id="161:227"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 25 }}



                            >
                                Is Cooking you Hoby

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("161:228", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="161:228"
                                        className="hidden"

                                        style={{ "height": 192, "width": 399, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-228.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 25, "marginBottom": 25 }}


                                    >



                                    </div>
                                </InView>
                            </div><div>
                                <div
                                    id="167:453"
                                    className=""

                                    style={{ "width": 451, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 25 }}


                                >

                                    <span
                                        id="161:230"
                                        className=""


                                        style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left" }}



                                    >
                                        {isCookingYourHobby}

                                    </span>

                                </div>
                            </div><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("161:231", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="161:231"
                                        className="hidden"

                                        style={{ "height": 48, "width": 321, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 25, "marginBottom": 0 }}


                                    >

                                        <span
                                            id="161:232"
                                            className=""


                                            style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left" }}



                                        >
                                            Join Now

                                        </span>

                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div>
                    <div
                        id="Pre Order"
                    >
                        <div
                            id="161:233"
                            className=""

                            style={{ "width": 900, "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 60, "paddingBottom": 90 }}


                        >

                            <span
                                id="161:234"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 25 }}



                            >
                                Pre order

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("161:235", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="161:235"
                                        className="hidden"

                                        style={{ "height": 192, "width": 235, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-235.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 25, "marginBottom": 25 }}


                                    >



                                    </div>
                                </InView>
                            </div><div>
                                <div
                                    id="167:454"
                                    className=""

                                    style={{ "width": 542, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="167:451"
                                            className=""

                                            style={{ "width": 542, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                        >
                                            <InView
                                                triggerOnce={triggerOnce}
                                                delay={revealDelay}

                                                onChange={(inView, entry) => {
                                                    updateVisibility("161:237", inView)
                                                }}

                                                threshold={revealThreshold}

                                            >
                                                <span
                                                    id="161:237"
                                                    className="hidden"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", display: 'inline-block' }}



                                                >
                                                    Would you like to surprise your special one with their favourite food? Not able to cook many items? Not able to plan your office work and party menu?? <br /><br />Here we are....<br /> We will let you pre-order your favourite food and plan for special day a week ahead.

                                                </span>
                                            </InView>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="161:238"
                            className=""

                            style={{ "width": 900, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 70, "paddingBottom": 70 }}


                        >

                            <div
                                id="About us"
                            >
                                <div
                                    id="161:239"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                >

                                    <span
                                        id="161:240"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15 }}



                                    >
                                        About

                                    </span><div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("161:241", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="161:241"
                                                className="hidden"

                                                style={{ "height": 212, "width": 144, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-241.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 15, "marginBottom": 0 }}


                                            >



                                            </div>
                                        </InView>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="167:455"
                                    className=""

                                    style={{ "width": 609, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                >

                                    <span
                                        id="161:243"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                    >
                                        I am a foodie by heart and techie by profession. I love cooking and
                                        experimenting with new recipes. I also love to explore the world of food
                                        through my travels.
                                        <br></br>
                                        <br></br>
                                        I started this company because I wanted to bring the authentic homemade
                                        food to your door step. We are a team of passionate chefs who want to share
                                        their culinary skills with you.
                                    </span>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="161:244"
                            className=""

                            style={{ "width": 900, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 30, "paddingBottom": 30 }}


                        >

                            <div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("161:245", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="161:245"
                                        className="hidden"

                                        style={{ "height": 633, "width": 530, "borderRadius": 56, "backgroundColor": "rgba(224,66,52,1)", "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 70, "paddingLeft": 50, "paddingRight": 50, "paddingTop": 0, "paddingBottom": 0 }}


                                    >

                                        <span
                                            id="161:246"
                                            className=""


                                            style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 35 }}



                                        >
                                            Why Chefs near by ?

                                        </span><div>
                                            <div
                                                id="161:247"
                                                className=""

                                                style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 35, "marginBottom": 35 }}


                                            >

                                                <span
                                                    id="161:248"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                >
                                                    Support local chefs

                                                </span><span
                                                    id="161:250"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                                >
                                                    Choose a local chef of your choice and help local chef and their business to grow.

                                                </span>

                                            </div>
                                        </div><div>
                                            <div
                                                id="161:251"
                                                className=""

                                                style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 35, "marginBottom": 35 }}


                                            >

                                                <span
                                                    id="161:252"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                >
                                                    Enjoy homemade food

                                                </span><span
                                                    id="161:254"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                                >
                                                    Enjoy authentic homemade food from different cuisines especially made for you at reasonable price.

                                                </span>

                                            </div>
                                        </div><div>
                                            <div
                                                id="161:255"
                                                className=""

                                                style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 35, "marginBottom": 0 }}


                                            >

                                                <span
                                                    id="161:256"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                >
                                                    Get customized food

                                                </span><span
                                                    id="161:258"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                                >
                                                    Make a wish and get the food delivered as per your choice.

                                                </span>

                                            </div>
                                        </div>

                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="161:259"
                            className=""

                            style={{ "width": 900, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 70, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 35, "paddingBottom": 65 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("161:260", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="161:260"
                                    className="hidden"


                                    style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 35, display: 'inline-block' }}



                                >
                                    Our customer feedbacks

                                </span>
                            </InView>
                            <div>
                                <div
                                    id="161:261"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 35, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="161:262"
                                            className=""

                                            style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 70, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="161:263"
                                                    className=""
                                                    onClick={() => { goToPrev(feedbackHSref) }}
                                                    style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-263.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 35 }}


                                                >



                                                </div>
                                            </div><div

                                                style={{
                                                    width: 380, padding: 10
                                                }}  >



                                                <ScrollMenu
                                                    apiRef={feedbackHSref}
                                                    scrollContainerClassName="scrollmenu"

                                                >

                                                    {customerFeedback.map((item, index) => {

                                                        return <div style={{
                                                            marginRight: 50
                                                        }}  >

                                                            <div>
                                                                <div
                                                                    id="161:264"
                                                                    className=""

                                                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 10, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, }}


                                                                >

                                                                    <span
                                                                        id="161:265"
                                                                        className=""


                                                                        style={{ "fontFamily": "Playfair Display", "fontSize": 96, "fontWeight": 400, "color": "rgba(224,224,224,1)", "letterSpacing": 0, "lineHeight": "100px", "textAlign": "left", "marginTop": 0 }}



                                                                    >
                                                                        “

                                                                    </span><div>
                                                                        <div
                                                                            id="167:456"
                                                                            className=""

                                                                            style={{ "width": 336, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                                                        >

                                                                            <span
                                                                                id="161:267"
                                                                                className=""


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                                            >
                                                                                {item.feedback}

                                                                            </span>

                                                                        </div>
                                                                    </div><div>
                                                                        <div
                                                                            id="161:269"
                                                                            className=""

                                                                            style={{ "width": 336, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-end", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 75, "paddingBottom": 8, "marginBottom": 0 }}


                                                                        >

                                                                            <div>
                                                                                <div
                                                                                    id="161:270"
                                                                                    className=""

                                                                                    style={{ "height": 46, "width": 48, "borderRadius": null, "backgroundImage": `url(${item.profilePic})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 5 }}


                                                                                >



                                                                                </div>
                                                                            </div><div>
                                                                                <div
                                                                                    id="161:271"
                                                                                    className=""

                                                                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 2, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 5, "marginRight": 0 }}


                                                                                >

                                                                                    <span
                                                                                        id="161:272"
                                                                                        className=""


                                                                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "right", "marginTop": 0, "marginBottom": 1 }}



                                                                                    >
                                                                                        {item.name}

                                                                                    </span><span
                                                                                        id="161:273"
                                                                                        className=""


                                                                                        style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "right", "marginTop": 1, "marginBottom": 0 }}



                                                                                    >
                                                                                        {item.place}

                                                                                    </span>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    })}
                                                </ScrollMenu>
                                            </div><div>
                                                <div
                                                    id="161:268"
                                                    className=""
                                                    onClick={() => { goToNext(feedbackHSref) }}
                                                    style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-268.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 35, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="167:461"
                            className=""

                            style={{ "width": 900, "backgroundColor": "rgba(246,241,236,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 80, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 120, "paddingBottom": 120 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("161:275", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="161:275"
                                    className="hidden"


                                    style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "center", "marginTop": 0, "marginBottom": 40, display: 'inline-block' }}



                                >
                                    Let it be social

                                </span>
                            </InView>
                            <div>
                                <div
                                    id="167:460"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 60, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 40, "marginBottom": 40 }}


                                >

                                    <div

                                        style={{
                                            width: 800, padding: 10
                                        }}  >



                                        <ScrollMenu
                                            apiRef={socialHSref}
                                            scrollContainerClassName="scrollmenu"

                                        >

                                            {socialList.map((item, index) => {

                                                return <div style={{
                                                    marginRight: 50
                                                }}  >

                                                    <div>
                                                        <div
                                                            id="167:459"
                                                            className="hvr-grow"

                                                            style={{ "height": 314, "width": 314, "borderRadius": null, "backgroundImage": `url(${item.url})`, "backgroundSize": "cover", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>

                                            })}
                                        </ScrollMenu>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="161:279"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 300, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 40, "marginBottom": 0 }}


                                >

                                    <span
                                        id="161:280"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 300, "color": "rgba(38,50,56,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginLeft": 0, "marginRight": 150 }}



                                    >
                                        #chefsnearby

                                    </span><div>
                                        <div
                                            id="161:281"
                                            className=""

                                            style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 60, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 150, "marginRight": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="161:282"
                                                    className=""
                                                    onClick={() => { goToPrev(socialHSref) }}
                                                    style={{ "height": 88.828125, "width": 46, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-282.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 30 }}


                                                >



                                                </div>
                                            </div><div>
                                                <div
                                                    id="161:283"
                                                    className=""
                                                    onClick={() => { goToNext(socialHSref) }}
                                                    style={{ "height": 88.828125, "width": 46, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-283.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 30, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="167:464"
                            className=""

                            style={{ "width": 900, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 50, "paddingBottom": 50 }}


                        >

                            <div>
                                <div
                                    id="161:285"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 20 }}


                                >

                                    <div
                                        id="Contact us"
                                    >
                                        <div
                                            id="167:463"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                        >

                                            <span
                                                id="161:286"
                                                className=""


                                                style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 5 }}



                                            >
                                                Contact us

                                            </span><div>
                                                <InView
                                                    triggerOnce={triggerOnce}
                                                    delay={revealDelay}

                                                    onChange={(inView, entry) => {
                                                        updateVisibility("167:462", inView)
                                                    }}

                                                    threshold={revealThreshold}

                                                >
                                                    <div
                                                        id="167:462"
                                                        className="hidden"

                                                        style={{ "height": 334, "width": 661, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/167-462.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 5, "marginBottom": 0 }}


                                                    >



                                                    </div>
                                                </InView>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="161:288"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 35, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 20, "marginBottom": 0 }}


                                >

                                    <input
                                        id="161:289"

                                        className=""
                                        style={{ "height": 36, "width": 585, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 25, "paddingRight": 25, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 0, "marginBottom": 17.5, "cursor": "default", "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(142,142,142,1)", "fontWeight": 300, "textAlign": "left" }}


                                        onChange={(event) => { contactUsform("name", event.target.value) }}

                                        placeholder="Name"

                                    >

                                    </input><input
                                        id="161:291"

                                        className=""
                                        style={{ "height": 36, "width": 585, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 25, "paddingRight": 25, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5, "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(142,142,142,1)", "fontWeight": 300, "textAlign": "left" }}


                                        onChange={(event) => { contactUsform("email", event.target.value) }}

                                        placeholder="Email"

                                    >

                                    </input><input
                                        id="161:293"

                                        className=""
                                        style={{ "height": 36, "width": 585, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 25, "paddingRight": 25, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5, "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(142,142,142,1)", "fontWeight": 300, "textAlign": "left" }}


                                        onChange={(event) => { contactUsform("phone", event.target.value) }}

                                        placeholder="Phone"

                                    >

                                    </input> <textarea
                                        id="161:295"

                                        className=""
                                        style={{ "height": 128, "width": 615, "borderRadius": 8, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 15, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5, "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(142,142,142,1)", "fontWeight": 300, "textAlign": "left" }}


                                        onChange={(event) => { contactUsform("message", event.target.value) }}

                                        placeholder="Message"

                                    >

                                    </textarea><div>
                                        <div
                                            id="161:297"
                                            className="hvr-grow-shadow"
                                            onClick={() => { submitContactUs(); alertActive == true ? setAlert(true) : setAlert(false); sucessAlertActive == true ? setSucessAlert(true) : setSucessAlert(false) }}
                                            style={{ "height": 36, "width": 615, "borderRadius": 8, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 17.5, "marginBottom": 0 }}


                                        >

                                            <span
                                                id="161:298"
                                                className=""


                                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "15px", "textAlign": "left" }}



                                            >
                                                SUBMIT

                                            </span>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div
                        id="FAQ"
                    >
                        <div
                            id="167:487"
                            className=""

                            style={{ "width": 900, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 20, "paddingBottom": 80 }}


                        >

                            <div>
                                <div
                                    id="161:300"
                                    className=""

                                    style={{ "height": 38, "width": 415, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                >

                                    <span
                                        id="161:301"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 30, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "center" }}



                                    >
                                        Frequently asked questions

                                    </span>

                                </div>
                            </div>

                            <div>

                                <div
                                    id="167:486"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 35, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                >
                                    {
                                        frequentlyAskedQuestion.map((item, index) => {
                                            return <>
                                                <Accordion sx={{ boxShadow: 'none' }} style={{ "width": 592, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 0, "marginBottom": 17.5 }}
                                                    expanded={expanded === 'panel' + String(index)} onChange={handleChange('panel' + String(index))}>
                                                    <AccordionSummary
                                                        expandIcon={<div style={{ "height": 17, "width": 23, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)" }}></div>}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                        <span

                                                            className=""


                                                            style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 80 }}

                                                        >
                                                            {item.question}

                                                        </span>
                                                        {/* <Typography sx={{ fontSize flexShrink: 0 }}>
                                                    What are the requirements for being a chef ?
                                                </Typography> */}

                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <p

                                                            className=""


                                                            style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "lineHeight": "30px", "marginLeft": 0, textAlign: 'left' }}



                                                        >
                                                            {item.answer}

                                                        </p>
                                                        {/* <Typography>
                                                    Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
                                                    Aliquam eget maximus est, id dignissim quam.
                                                </Typography> */}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </>
                                        })
                                    }




                                    {/* <div>
                                        <div
                                            id="161:302"
                                            className="hvr-grow"

                                            style={{ "height": 66, "width": 592, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 160, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 0, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="161:303"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 80 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="161:304"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 80, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="167:465"
                                            className="hvr-grow"

                                            style={{ "height": 66, "width": 592, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 160, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="167:466"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 80 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="167:467"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 80, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="167:468"
                                            className="hvr-grow"

                                            style={{ "height": 66, "width": 592, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 160, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="167:469"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 80 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="167:470"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 80, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="167:471"
                                            className="hvr-grow"

                                            style={{ "height": 66, "width": 592, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 160, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="167:472"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 80 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="167:473"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 80, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="167:474"
                                            className="hvr-grow"

                                            style={{ "height": 66, "width": 592, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 160, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="167:475"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 80 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="167:476"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 80, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="167:477"
                                            className="hvr-grow"

                                            style={{ "height": 66, "width": 592, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 160, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="167:478"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 80 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="167:479"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 80, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="167:480"
                                            className="hvr-grow"

                                            style={{ "height": 66, "width": 592, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 160, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="167:481"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 80 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="167:482"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 80, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="167:483"
                                            className="hvr-grow"

                                            style={{ "height": 66, "width": 592, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 160, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 0 }}


                                        >

                                            <span
                                                id="167:484"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 80 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="167:485"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 80, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div> */}

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="167:489"
                            className=""

                            style={{ "width": 900, "backgroundColor": "rgba(184,50,38,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 80, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 80, "paddingBottom": 80 }}


                        >

                            <div>
                                <div
                                    id="161:328"
                                    className=""

                                    style={{ "height": 149, "width": 101, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-328.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 40 }}


                                >



                                </div>
                            </div><div>
                                <div
                                    id="161:329"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 80, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 40, "marginBottom": 40 }}


                                >

                                    <div>
                                        <div
                                            id="161:330"
                                            className=""

                                            style={{ "width": 201, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 40 }}


                                        >
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    // alert(percent)
                                                    setactive("Favourite Dish")
                                                    var element = document.getElementById("Favourite Dish");
                                                    var headerOffset = 206;
                                                    var elementPosition = element.getBoundingClientRect().top;
                                                    elementPosition = elementPosition * props.percent / 100
                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                    // offsetPosition = offsetPosition * percent / 100
                                                    // alert(elementPosition)
                                                    window.scrollTo({
                                                        top: offsetPosition,
                                                        behavior: "smooth"
                                                    });
                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                }}
                                            >
                                                <span
                                                    id="161:331"
                                                    className="hvr-grow"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 0, "marginBottom": 10 }}



                                                >
                                                    Favourite Dish

                                                </span>
                                            </div>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    // alert(percent)
                                                    setactive("Super Chef")
                                                    var element = document.getElementById("Super Chef");
                                                    var headerOffset = 106;
                                                    var elementPosition = element.getBoundingClientRect().top;
                                                    elementPosition = elementPosition * props.percent / 100
                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                    // offsetPosition = offsetPosition * percent / 100
                                                    // alert(elementPosition)
                                                    window.scrollTo({
                                                        top: offsetPosition,
                                                        behavior: "smooth"
                                                    });
                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                }}
                                            >
                                                <span
                                                    id="161:332"
                                                    className="hvr-grow"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                >
                                                    Super Chef

                                                </span>
                                            </div>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    // alert(percent)
                                                    setactive("Make a wish")
                                                    var element = document.getElementById("Make a wish");
                                                    var headerOffset = 106;
                                                    var elementPosition = element.getBoundingClientRect().top;
                                                    elementPosition = elementPosition * props.percent / 100
                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                    // offsetPosition = offsetPosition * percent / 100
                                                    // alert(elementPosition)
                                                    window.scrollTo({
                                                        top: offsetPosition,
                                                        behavior: "smooth"
                                                    });
                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                }}
                                            >
                                                <span
                                                    id="161:333"
                                                    className="hvr-grow"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                >
                                                    Make a wish

                                                </span>
                                            </div>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    // alert(percent)
                                                    setactive("Pre Order")
                                                    var element = document.getElementById("Pre Order");
                                                    var headerOffset = 106;
                                                    var elementPosition = element.getBoundingClientRect().top;
                                                    elementPosition = elementPosition * props.percent / 100
                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                    // offsetPosition = offsetPosition * percent / 100
                                                    // alert(elementPosition)
                                                    window.scrollTo({
                                                        top: offsetPosition,
                                                        behavior: "smooth"
                                                    });
                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                }}
                                            >
                                                <span
                                                    id="161:334"
                                                    className="hvr-grow"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                >
                                                    Pre Order

                                                </span>
                                            </div>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    // alert(percent)
                                                    setactive("How it works")
                                                    var element = document.getElementById("How it works");
                                                    var headerOffset = 106;
                                                    var elementPosition = element.getBoundingClientRect().top;
                                                    elementPosition = elementPosition * props.percent / 100
                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                    // offsetPosition = offsetPosition * percent / 100
                                                    // alert(elementPosition)
                                                    window.scrollTo({
                                                        top: offsetPosition,
                                                        behavior: "smooth"
                                                    });
                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                }}
                                            >
                                                <span
                                                    id="161:335"
                                                    className="hvr-grow"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                >
                                                    How it works

                                                </span>
                                            </div>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    // alert(percent)
                                                    setactive("About us")
                                                    var element = document.getElementById("About us");
                                                    var headerOffset = 106;
                                                    var elementPosition = element.getBoundingClientRect().top;
                                                    elementPosition = elementPosition * props.percent / 100
                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                    // offsetPosition = offsetPosition * percent / 100
                                                    // alert(elementPosition)
                                                    window.scrollTo({
                                                        top: offsetPosition,
                                                        behavior: "smooth"
                                                    });
                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                }}
                                            >
                                                <span
                                                    id="161:336"
                                                    className="hvr-grow"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 0 }}



                                                >
                                                    About us

                                                </span>
                                            </div>
                                        </div>
                                    </div><div>
                                        <div
                                            id="161:337"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 40, "marginRight": 0 }}


                                        >
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { navigateToTerms(); }}
                                            >
                                                <span
                                                    id="161:338"
                                                    className="hvr-grow"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 0, "marginBottom": 10 }}



                                                >
                                                    Terms & Conditions

                                                </span>
                                            </div>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { navigateToPrivacy(); }}
                                            >
                                                <span
                                                    id="161:339"
                                                    className="hvr-grow"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                >
                                                    Privacy Policy

                                                </span>
                                            </div>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    // alert(percent)
                                                    setactive("Pertner with us")
                                                    var element = document.getElementById("Pertner with us");
                                                    var headerOffset = 106;
                                                    var elementPosition = element.getBoundingClientRect().top;
                                                    elementPosition = elementPosition * props.percent / 100
                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                    // offsetPosition = offsetPosition * percent / 100
                                                    // alert(elementPosition)
                                                    window.scrollTo({
                                                        top: offsetPosition,
                                                        behavior: "smooth"
                                                    });
                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                }}
                                            >
                                                <span
                                                    id="161:340"
                                                    className="hvr-grow"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                >
                                                    Partner with us

                                                </span>
                                            </div>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    // alert(percent)
                                                    setactive("FAQ")
                                                    var element = document.getElementById("FAQ");
                                                    var headerOffset = 106;
                                                    var elementPosition = element.getBoundingClientRect().top;
                                                    elementPosition = elementPosition * props.percent / 100
                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                    // offsetPosition = offsetPosition * percent / 100
                                                    // alert(elementPosition)
                                                    window.scrollTo({
                                                        top: offsetPosition,
                                                        behavior: "smooth"
                                                    });
                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                }}
                                            >
                                                <span
                                                    id="161:341"
                                                    className="hvr-grow"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                >
                                                    FAQ

                                                </span>
                                            </div>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    // alert(percent)
                                                    setactive("Contact us")
                                                    var element = document.getElementById("Contact us");
                                                    var headerOffset = 106;
                                                    var elementPosition = element.getBoundingClientRect().top;
                                                    elementPosition = elementPosition * props.percent / 100
                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                    // offsetPosition = offsetPosition * percent / 100
                                                    // alert(elementPosition)
                                                    window.scrollTo({
                                                        top: offsetPosition,
                                                        behavior: "smooth"
                                                    });
                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                }}
                                            >
                                                <span
                                                    id="161:342"
                                                    className="hvr-grow"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 0 }}



                                                >
                                                    Contact us

                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="167:488"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 40, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="161:344"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 25, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 15 }}


                                        >

                                            <span
                                                id="161:345"
                                                className=""


                                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 12.5 }}



                                            >
                                                Connect with us

                                            </span><div>
                                                <div
                                                    id="161:346"
                                                    className=""

                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 12.5, "marginBottom": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="161:347"
                                                            className="hvr-grow"
                                                            onClick={() => { window.open("https://www.facebook.com/profile.php?id=100090737110736&mibextid=ZbWKwL"); }}
                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-347.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 10 }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="161:348"
                                                            className="hvr-grow"
                                                            onClick={() => { window.open("https://twitter.com/chefs_nearby"); }}
                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/99-962.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 10 }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="161:349"
                                                            className="hvr-grow"
                                                            onClick={() => { window.open("https://instagram.com/chefs.nearby?igshid=ZDdkNTZiNTM="); }}
                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-349.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 0 }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div><div>

                                    </div><div>
                                        <div
                                            id="161:350"
                                            className=""

                                            style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 0 }}


                                        >

                                            <div>
                                                <div style={{
                                                    backgroundColor: "#fff", position: "relative", width: 120, height: 45,
                                                    marginRight: 40, marginTop: 5
                                                }}>
                                                    <div
                                                        id="161:351"
                                                        className="hvr-grow-shadow"

                                                        style={{
                                                            "height": 52, "width": 176, "borderRadius": null,
                                                            "backgroundImage": `url(${AppStore})`, "backgroundSize": "contain",
                                                            "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": -5,
                                                            "marginRight": 15, position: 'absolute', top: -5
                                                        }}


                                                    >



                                                    </div>
                                                </div>

                                            </div><div>
                                                <div
                                                    id="161:352"
                                                    className="hvr-grow-shadow"

                                                    style={{ "height": 52, "width": 174, "borderRadius": null, "backgroundImage": `url(${Google_play})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 15, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="161:357"
                            className=""

                            style={{ "height": 227, "width": 880, "backgroundColor": "rgba(112,32,25,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 50, "paddingBottom": 50, "marginBottom": 0 }}


                        >

                            <div>
                                <div
                                    id="167:490"
                                    className=""

                                    style={{ "width": 600, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                >

                                    <span
                                        id="161:359"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "center" }}



                                    >
                                        By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy and Content Policies. All trademarks are properties of their respective owners. 2021 © Chefs near by . All rights reserved

                                    </span>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            {
                alert == true ?
                    <div style={{ position: 'fixed', bottom: 80, width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Alert sx={{ width: '90%' }} onClose={() => { setAlert(false) }} variant="filled" severity="error">{alertmessage}</Alert>
                    </div>
                    :
                    null
            }
            {
                sucessAlert == true ?
                    <div style={{ position: 'fixed', bottom: 80, width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Alert sx={{ width: '90%' }} onClose={() => { setSucessAlert(false) }} variant="filled" severity="success">{successmessage}</Alert>
                    </div>
                    :
                    null
            }

        </>
    )
}

export default View2