import { alertActive, alertmessage, cheflist, contactUs, customerFeedback, frequentlyAskedQuestion, isCookingYourHobby, OrderFromOurKitchenSchedule, socialList, submitContactUs, successmessage, sucessAlertActive, superChefList, topNav, topProcessList } from "../DataLayer/data"
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useRef, useState } from "react";
import '../hover.css';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { InView, useInView } from 'react-intersection-observer';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert'
import AppStore from '../Image/App_store.png'
import Google_play from '../Image/Google_play.png'
import Instagram from '../Image/Instagram-Negative.png'
import Twitter from '../Image/Twitter-Negative.png'
import Facebook from '../Image/Vector.png'
import Cross from '../Image/close.png'
import { Routes, Route, useNavigate } from 'react-router-dom';

var revealEffectConfig = {
    // "155:1948": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "155:1954": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "155:1963": [
    //     "animate__animated",
    //     "animate__rollIn"
    // ],
    // "158:2175": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "155:1987": [
    //     "animate__animated",
    //     "animate__fadeInBottomRight"
    // ],
    // "155:1989": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "156:1993": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "156:2025": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "156:2026": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "156:2032": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "156:2033": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "156:2037": [
    //     "animate__animated",
    //     "animate__flip"
    // ],
    // "156:2055": [
    //     "animate__animated",
    //     "animate__rollIn"
    // ],
    // "156:2057": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "156:2076": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "157:2087": [
    //     "animate__animated",
    //     "animate__rollIn"
    // ]
}

const View4 = (props) => {

    const navigate = useNavigate();

    const navigateToPrivacy = () => {
        // 👇️ navigate to /contacts
        window.open(
            "/privacy", "_blank");
    };

    const navigateToTerms = () => {
        // 👇️ navigate to /contacts
        window.open(
            "/terms", "_blank");
    };

    const [active, setactive] = useState("Favourite Dish")
    const [drawer, setDrawer] = useState(false)
    const [triggerOnce, settriggerOnce] = useState(false)
    const [revealDelay, setrevealDelay] = useState(300)
    const [revealThreshold, setrevealThreshold] = useState(0)
    const [alert, setAlert] = useState(false)
    const [sucessAlert, setSucessAlert] = useState(false)
    const socialHSref = useRef()
    const feedbackHSref = useRef()
    const chefHSref = useRef()
    const superChefHSref = useRef()
    const kitchenScheduleHSref = useRef()
    const topProgressHSref = useRef()



    const [howKitchenWorkPopup, setHowKitchenWorkPopup] = useState(false)
    const [howMakeWishVid, setHowMakeWishvid] = useState(false)
    const [header, setHeader] = useState(true)

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const updateVisibility = (id, visible) => {
        var el = document.getElementById(id)
        // alert(visible)


        if (visible) {
            el.classList.add("animate__animated")

            el.classList.add(revealEffectConfig[id][1])
            el.classList.remove("hidden")

        } else {
            el.classList.remove("animate__animated")

            el.classList.remove(revealEffectConfig[id][1])
            el.classList.add("hidden")



        }
    }

    const goToNext = (scrollRef) => {
        let nextItem = scrollRef.current.getNextItem()
        if (scrollRef.current.isLastItemVisible) {

            nextItem = scrollRef.current.getItemByIndex(0)
        }
        scrollRef.current.scrollToItem(
            nextItem,
            // OR if you not sure about id for first item
            // scrollRef.current.getItemById(scrollRef.current.items.toItems()[nextItem.index]),
            "smooth",
            "start"
        );
    }

    const goToPrev = (scrollRef) => {
        var prevItem = scrollRef.current.getPrevItem()

        scrollRef.current.scrollToItem(
            prevItem,
            // OR if you not sure about id for first item
            // scrollRef.current.getItemById(scrollRef.current.items.toItems()[nextItem.index]),
            "smooth",
            "start"
        );
    }

    const contactUsform = (id, val) => {
        contactUs[id] = val
    }

    const HeaderAction = (val) => {
        setHeader(val)

    }

    return (
        <>

            <div>
                <div
                    id="154:73"
                    className=""

                    style={{ "width": 400, "backgroundColor": "rgba(255,255,255,1)", "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                >

                    <div>
                        <div
                            id="155:1934"
                            className=""

                            style={{
                                "width": 400, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 20, "paddingBottom": 20, "marginTop": 0,
                                position: header ? 'fixed' : null, backgroundColor: "#fff", zIndex: drawer ? 0 : 100000, borderBottom: '2px solid #ccc',
                            }}


                        >

                            <div>
                                <div
                                    id="155:1928"
                                    className=""

                                    style={{ "height": 60, "width": 156, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1928.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 15 }}


                                >



                                </div>
                            </div><div>
                                <div
                                    id="155:1933"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 15, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 15, "marginRight": 0 }}


                                >

                                    <div>
                                        <div
                                            id="155:1929"
                                            className=""
                                            onClick={() => { window.open("https://www.facebook.com/profile.php?id=100090737110736&mibextid=ZbWKwL"); }}
                                            style={{ "height": 28, "width": 28, "borderRadius": null, "backgroundImage": `url(${Facebook})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 7.5, "cursor": "pointer" }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="155:1930"
                                            className=""
                                            onClick={() => { window.open("https://twitter.com/chefs_nearby"); }}
                                            style={{ "height": 28, "width": 28, "borderRadius": null, "backgroundImage": `url(${Twitter})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 7.5, "cursor": "pointer" }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="155:1931"
                                            className=""
                                            onClick={() => { window.open("https://instagram.com/chefs.nearby?igshid=ZDdkNTZiNTM="); }}
                                            style={{ "height": 28, "width": 28, "borderRadius": null, "backgroundImage": `url(${Instagram})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 7.5, "cursor": "pointer" }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="155:1932"
                                            className=""
                                            onClick={() => { setDrawer(true) }}
                                            style={{ "height": 44, "width": 44, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1932.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 0, "cursor": "pointer" }}


                                        >



                                        </div>
                                        <Drawer
                                            anchor={'right'}
                                            open={drawer}
                                            onClose={() => { setDrawer(false) }}
                                        >
                                            <div>
                                                <Box
                                                    sx={{ width: 250 }}
                                                    role="presentation"
                                                // onClick={props?.item?.toggleDrawer}
                                                // onKeyDown={props?.item?.toggleDrawer}
                                                >
                                                    <div style={{ textAlign: "center" }}>
                                                        <img style={{ width: 150, height: 60, marginTop: 20, marginBottom: 20 }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1928.png'} />
                                                    </div>
                                                    <Divider />
                                                    <List>
                                                        {topNav.map((item, index) => (
                                                            <div
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    // alert(percent)
                                                                    setactive(item)
                                                                    var element = document.getElementById(item);
                                                                    var headerOffset = 180;
                                                                    var elementPosition = element.getBoundingClientRect().top;
                                                                    elementPosition = elementPosition * props.percent / 100
                                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                                    // offsetPosition = offsetPosition * percent / 100
                                                                    // alert(elementPosition)
                                                                    window.scrollTo({
                                                                        top: offsetPosition,
                                                                        behavior: "smooth"
                                                                    });
                                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
                                                                    setDrawer(false)
                                                                }}
                                                            >
                                                                <a style={{ textDecoration: "none", color: "#000", fontWeight: "bold" }}>
                                                                    <ListItem button key={item}>
                                                                        <ListItemText primary={item} />
                                                                    </ListItem>
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </List>

                                                </Box>
                                            </div>
                                        </Drawer>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="155:1937"
                            className=""

                            style={{ "width": 400, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 30, marginTop: '120px' }}


                        >

                            <div>
                                <div
                                    id="155:1935"
                                    className=""

                                    style={{ "height": 50, "width": 140, "borderRadius": null, "backgroundImage": `url(${Google_play})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 10, "cursor": "pointer" }}


                                >



                                </div>
                            </div><div>
                                <div
                                    id="155:1936"
                                    className=""

                                    style={{ "height": 50, "width": 140, "borderRadius": null, "backgroundImage": `url(${AppStore})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 0, "cursor": "pointer" }}


                                >



                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="155:1947"
                            className=""

                            style={{ "width": 400, "backgroundColor": "rgba(224,66,52,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 20, "paddingBottom": 20 }}


                        >

                            <div id="Favourite Dish">
                                <div
                                    id="242:203"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 30, "paddingLeft": 50, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 10 }}


                                >

                                    <div

                                        style={{
                                            width: 230, padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'
                                        }}  >

                                        {
                                            topProcessList.map((item, index) => {
                                                return (
                                                    <div style={{
                                                        display: 'flex', flexDirection: 'column', alignItems: 'center'
                                                    }}  >

                                                        <div>
                                                            <div
                                                                id="242:202"
                                                                className=""

                                                                style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 15, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 15, "cursor": "default" }}


                                                            >

                                                                <div>
                                                                    <div
                                                                        id="155:1939"
                                                                        className=""

                                                                        style={{ "height": 197, "width": 215, "borderRadius": null, "backgroundImage": `url(${item.image})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 7.5, "cursor": "default" }}


                                                                    >



                                                                    </div>
                                                                </div><span
                                                                    id="155:1941"
                                                                    className=""


                                                                    style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 7.5, "marginBottom": 0, "cursor": "default" }}



                                                                >
                                                                    {item.text}

                                                                </span>

                                                            </div>
                                                        </div>
                                                        {
                                                            index == 2 ? null :


                                                                <div
                                                                    id="155:1940"
                                                                    className=""
                                                                    // onClick={() => { goToNext(topProgressHSref) }}
                                                                    style={{ "height": 34, "width": 20, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1940.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginRight": 20, "cursor": "pointer", marginTop: '20px', marginBottom: '20px', rotate: '90deg', }}


                                                                >
                                                                </div>
                                                        }

                                                    </div>
                                                )
                                            })
                                        }


                                        {/* <ScrollMenu
                                            apiRef={topProgressHSref}
                                            scrollContainerClassName="scrollmenu"

                                        >

                                            {topProcessList.map((item, index) => {

                                                return <div style={{
                                                    // marginRight: 60
                                                }}  >

                                                    <div>
                                                        <div
                                                            id="242:202"
                                                            className=""

                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 15, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 15, "cursor": "default" }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="155:1939"
                                                                    className=""

                                                                    style={{ "height": 197, "width": 215, "borderRadius": null, "backgroundImage": `url(${item.image})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 7.5, "cursor": "default" }}


                                                                >



                                                                </div>
                                                            </div><span
                                                                id="155:1941"
                                                                className=""


                                                                style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 7.5, "marginBottom": 0, "cursor": "default" }}



                                                            >
                                                                {item.text}

                                                            </span>

                                                        </div>
                                                    </div>

                                                </div>

                                            })}
                                        </ScrollMenu> */}
                                    </div><div>
                                        {/* <div
                                            id="155:1940"
                                            className=""
                                            onClick={() => { goToNext(topProgressHSref) }}
                                            style={{ "height": 34, "width": 20, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1940.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 15, "marginRight": 0, "cursor": "pointer" }}


                                        >



                                        </div> */}
                                    </div>

                                </div>
                            </div>{/* <div>
                                <div
                                    id="155:1945"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 10, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="155:1942"
                                            className=""

                                            style={{ "height": 17, "width": 17, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1942.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 10 }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="155:1943"
                                            className=""

                                            style={{ "height": 17, "width": 17, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1943.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 10 }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="155:1944"
                                            className=""

                                            style={{ "height": 17, "width": 17, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1944.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div> 

                                </div>
                            </div>*/}

                        </div>
                    </div><div>
                        <div
                            id="155:1953"
                            className=""

                            style={{ "width": 400, "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 35, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 40, "paddingBottom": 40 }}


                        >

                            <div>
                                <div
                                    id="155:1958"
                                    className=""

                                    style={{ "width": 277, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 17.5 }}


                                >
                                    <InView
                                        triggerOnce={triggerOnce}
                                        delay={revealDelay}

                                        onChange={(inView, entry) => {
                                            updateVisibility("155:1948", inView)
                                        }}

                                        threshold={revealThreshold}

                                    >
                                        <span
                                            id="155:1948"
                                            className="hidden"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "26.5px", "textAlign": "center", display: 'inline-block' }}



                                        >
                                            Order from Our Kitchen schedule

                                        </span>
                                    </InView>
                                </div>
                            </div>

                            <div

                            >
                                <div
                                    id="242:206"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 35, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 17.5, "marginBottom": 0 }}


                                >

                                    <div

                                        style={{
                                            width: 280, padding: 0,
                                        }}  >

                                        {
                                            OrderFromOurKitchenSchedule.map((item, index) => {
                                                return (
                                                    <div style={{
                                                        marginBottom: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center'
                                                    }}  >

                                                        <div>
                                                            <div
                                                                id="242:205"
                                                                className=""

                                                                style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 35, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 17.5, "cursor": "default" }}


                                                            >

                                                                <div>
                                                                    <div
                                                                        id="155:1949"
                                                                        className=""

                                                                        style={{ "height": 212, "width": 215, "borderRadius": null, "backgroundImage": `url(${item.image})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 17.5, "cursor": "default" }}


                                                                    >



                                                                    </div>
                                                                </div><div>
                                                                    <div
                                                                        id="155:1959"
                                                                        className=""

                                                                        style={{ "width": 265, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 0, "cursor": "default" }}


                                                                    >

                                                                        <span
                                                                            id="155:1951"
                                                                            className=""


                                                                            style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(74,74,74,1)", "letterSpacing": 0, "lineHeight": "34px", "textAlign": "center", "cursor": "default" }}



                                                                        >
                                                                            {item.desc}

                                                                        </span>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {
                                                            index != 3 ?

                                                                <div>
                                                                    <div
                                                                        id="90:116"



                                                                        style={{ "height": 23, "width": 124, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-116.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", rotate: '90deg', marginTop: 50, marginLeft: 250, marginBottom: '20px' }}


                                                                    >



                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                )
                                            })
                                        }

                                        {/* <ScrollMenu
                                            apiRef={kitchenScheduleHSref}
                                            scrollContainerClassName="scrollmenu"

                                        >

                                            {OrderFromOurKitchenSchedule.map((item, index) => {

                                                return <div style={{
                                                    // marginRight: 70
                                                }}  >

                                                    <div>
                                                        <div
                                                            id="242:205"
                                                            className=""

                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 35, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 17.5, "cursor": "default" }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="155:1949"
                                                                    className=""

                                                                    style={{ "height": 212, "width": 215, "borderRadius": null, "backgroundImage": `url(${item.image})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 17.5, "cursor": "default" }}


                                                                >



                                                                </div>
                                                            </div><div>
                                                                <div
                                                                    id="155:1959"
                                                                    className=""

                                                                    style={{ "height": 136, "width": 265, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 17.5, "marginBottom": 0, "cursor": "default" }}


                                                                >

                                                                    <span
                                                                        id="155:1951"
                                                                        className=""


                                                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(74,74,74,1)", "letterSpacing": 0, "lineHeight": "34px", "textAlign": "center", "cursor": "default" }}



                                                                    >
                                                                        {item.desc}

                                                                    </span>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            })}
                                        </ScrollMenu> */}
                                    </div>
                                    {/* <div>
                                        <div
                                            id="242:204"
                                            className=""

                                            style={{ "height": 212, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 17.5, "marginRight": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="155:1950"
                                                    className=""
                                                    onClick={() => { goToNext(kitchenScheduleHSref) }}
                                                    style={{ "height": 64, "width": 37, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1950.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div> */}

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="155:1957"
                            className=""

                            style={{ "width": 400, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 15, "paddingBottom": 40 }}


                        >

                            <div>
                                <div
                                    id="155:1960"
                                    className=""

                                    style={{ "width": 333, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                >
                                    <InView
                                        triggerOnce={triggerOnce}
                                        delay={revealDelay}

                                        onChange={(inView, entry) => {
                                            updateVisibility("155:1954", inView)
                                        }}

                                        threshold={revealThreshold}

                                    >
                                        <span
                                            id="155:1954"
                                            className="hidden"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", display: 'inline-block' }}



                                        >
                                            How Kitchen schedule works

                                        </span>
                                    </InView>
                                </div>
                            </div>
                            {/* <div>

                            </div> */}
                            <div style={{ "height": 224, "width": 399, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/kitchen+shedule.png)", display: 'flex', alignItems: 'center', justifyContent: 'center', objectFit: "fill", backgroundSize: 'cover' }}>
                                <div style={{}}
                                    onClick={() => { setHowKitchenWorkPopup(true); HeaderAction(false) }}
                                >
                                    <img style={{ height: 82, width: 82, cursor: 'pointer' }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/play+button.png'} />
                                </div>
                            </div>
                            {
                                howKitchenWorkPopup == true ?
                                    <div style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', top: 0, zIndex: 5000 }}>
                                        <div style={{ display: 'flex' }}>
                                            <video
                                                id="155:1956"

                                                className=""


                                                style={{ backgroundColor: 'gray' }}

                                                width="379"

                                                controls>
                                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/kitchenSchedule.mp4" type="video/mp4" />
                                                Your browser does not support HTML video.
                                            </video>
                                            <div onClick={() => { setHowKitchenWorkPopup(false); HeaderAction(true) }} style={{ marginLeft: '-20px', marginTop: -20, cursor: 'pointer' }}>
                                                <img style={{ width: 20, height: 20 }} src={Cross} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {/* <video
                                id="155:1956"

                                className=""




                                width="379"

                                controls>
                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/kitchenSchedule.mp4" type="video/mp4" />
                                Your browser does not support HTML video.
                            </video> */}

                        </div>
                    </div><div id="Super Chef">
                        <div
                            id="155:1975"
                            className=""

                            style={{ "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 30, "paddingBottom": 55 }}


                        >

                            <span
                                id="155:1961"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15 }}



                            >
                                Super Chef

                            </span><div>
                                <div
                                    id="155:1974"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 25, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 15 }}


                                >

                                    <div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("155:1963", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="155:1963"
                                                className="hidden"

                                                style={{ "height": 305, "width": 399, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1963.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 12.5 }}


                                            >



                                            </div>
                                        </InView>
                                    </div><div>
                                        <div
                                            id="242:208"
                                            className=""

                                            style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 12.5, "marginBottom": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="155:1966"
                                                    className=""
                                                    onClick={() => { goToPrev(superChefHSref) }}
                                                    style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1966.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 25, "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div><div

                                                style={{
                                                    width: 200, padding: 10
                                                }}  >



                                                <ScrollMenu
                                                    apiRef={superChefHSref}
                                                    scrollContainerClassName="scrollmenu"


                                                >

                                                    {superChefList.map((item, index) => {

                                                        return <div style={{
                                                            // marginRight: 50
                                                        }}  >

                                                            <div>
                                                                <div
                                                                    id="242:207"
                                                                    className=""

                                                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 15, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 25, "marginRight": 25, "cursor": "default" }}


                                                                >

                                                                    <div>
                                                                        <div
                                                                            id="155:1968"
                                                                            className=""

                                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 5, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 7.5, "cursor": "default" }}


                                                                        >

                                                                            <div>
                                                                                <div
                                                                                    id="155:1964"
                                                                                    className=""

                                                                                    style={{ "height": 165, "width": 136, "borderRadius": null, "backgroundImage": `url(${item.chefImg})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 2.5, "cursor": "default" }}


                                                                                >



                                                                                </div>
                                                                            </div><span
                                                                                id="155:1965"
                                                                                className=""


                                                                                style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(123,119,119,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "justified", "marginTop": 2.5, "marginBottom": 0, "cursor": "default" }}



                                                                            >
                                                                                {item.name}

                                                                            </span>

                                                                        </div>
                                                                    </div><div>
                                                                        <div
                                                                            id="155:1970"
                                                                            className=""

                                                                            style={{ "height": 160, "width": 161, "borderRadius": null, "backgroundImage": `url(${item.foodImage})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 7.5, "marginBottom": 0, "cursor": "default" }}


                                                                        >



                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    })}
                                                </ScrollMenu>
                                            </div><div>
                                                <div
                                                    id="155:1967"
                                                    className=""
                                                    onClick={() => { goToNext(superChefHSref) }}
                                                    style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1967.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 25, "marginRight": 0, "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="155:1972"
                                    className=""

                                    style={{ "height": 62, "width": 332, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 15, "marginBottom": 0, "cursor": "pointer" }}


                                >

                                    <span
                                        id="155:1971"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25.5px", "textAlign": "center" }}



                                    >
                                        Download Our <br />App Now

                                    </span>

                                </div>
                            </div>

                        </div>
                    </div><div id="Make a wish">
                        <div
                            id="155:1979"
                            className=""

                            style={{ "width": 400, "backgroundColor": "rgba(244,244,244,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 40, "paddingBottom": 40 }}


                        >

                            <span
                                id="155:1976"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15 }}



                            >
                                Make a wish

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("158:2175", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="158:2175"
                                        className="hidden"

                                        style={{ "height": 279, "width": 304, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 0 }}


                                    >

                                        <div>
                                            <div
                                                id="158:2171"
                                                className=""

                                                style={{ "width": 304, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 5, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 10 }}


                                            >

                                                <div>
                                                    <div
                                                        id="155:1978"
                                                        className=""

                                                        style={{ "width": 304, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 2.5 }}


                                                    >

                                                        <span
                                                            id="155:1977"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            Have you been craving to eat your favourite dish from long??

                                                        </span>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="158:2166"
                                                        className=""

                                                        style={{ "width": 304, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 2.5, "marginBottom": 2.5 }}


                                                    >

                                                        <span
                                                            id="158:2167"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            You don’t know how to make it?

                                                        </span>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="158:2168"
                                                        className=""

                                                        style={{ "width": 304, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 2.5, "marginBottom": 0 }}


                                                    >

                                                        <span
                                                            id="158:2169"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            You are not getting the desired taste when you do it yourself??

                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div><div>
                                            <div
                                                id="158:2174"
                                                className=""

                                                style={{ "width": 304, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 5, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 10, "marginBottom": 0 }}


                                            >

                                                <div>
                                                    <div
                                                        id="161:73"
                                                        className=""

                                                        style={{ "width": 304, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 2.5 }}


                                                    >

                                                        <span
                                                            id="149:185"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            Here is the solution to satisfy your tastebuds...

                                                        </span>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="161:74"
                                                        className=""

                                                        style={{ "width": 304, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 2.5, "marginBottom": 0 }}


                                                    >

                                                        <span
                                                            id="158:2173"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            You can make a wish and our expert chefs from our list would prepare it and deliver it to you.

                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="155:1988"
                            className=""

                            style={{ "width": 400, "backgroundColor": "rgba(244,244,244,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 20, "paddingBottom": 50 }}


                        >

                            <span
                                id="155:1980"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15 }}



                            >
                                Chef near by User Journey

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        // updateVisibility("155:1987", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="155:1987"
                                        // className="hidden"

                                        style={{ "height": 1120, "width": 343, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/155-1987.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 15, "marginBottom": 0 }}


                                    >



                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div id="How it works">
                        <div
                            id="155:1992"
                            className=""

                            style={{ "width": 400, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 35, "paddingBottom": 45 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("155:1989", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="155:1989"
                                    className="hidden"


                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15, display: 'inline-block' }}



                                >
                                    How make a wish works

                                </span>
                            </InView>
                            <div style={{ "height": 224, "width": 399, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/make+a+wish.png)", display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundSize: 'cover' }}>
                                <div style={{}}
                                    onClick={() => { setHowMakeWishvid(true); HeaderAction(false) }}
                                >
                                    <img style={{ height: 55, width: 55, cursor: 'pointer' }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/play+button.png'} />
                                </div>
                            </div>
                            {
                                howMakeWishVid == true ?
                                    <div style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', top: 0, zIndex: 5000 }}>
                                        <div style={{ display: 'flex' }}>
                                            <video
                                                id="155:1991"

                                                className=""

                                                style={{ backgroundColor: 'gray' }}


                                                width="380"

                                                controls>
                                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/makeAwish.mp4" type="video/mp4" />
                                                Your browser does not support HTML video.
                                            </video>
                                            <div onClick={() => { setHowMakeWishvid(false); HeaderAction(true) }} style={{ marginLeft: '-20px', marginTop: -20, cursor: 'pointer' }}>
                                                <img style={{ width: 20, height: 20 }} src={Cross} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {/* <video
                                id="155:1991"

                                className=""




                                width="380"

                                controls>
                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/makeAwish.mp4" type="video/mp4" />
                                Your browser does not support HTML video.
                            </video> */}

                        </div>
                    </div><div>
                        <div
                            id="242:215"
                            className=""

                            style={{ "width": 400, "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 50, "paddingBottom": 50 }}


                        >

                            <div>
                                <div
                                    id="242:214"
                                    className=""

                                    style={{ "height": 738, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 200, "paddingBottom": 10, "marginLeft": 0 }}


                                >

                                    <div>
                                        <div
                                            id="156:1995"
                                            className=""
                                            onClick={() => { goToPrev(chefHSref) }}
                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/156-1995.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "cursor": "pointer", marginLeft: '40px', marginRight: '-10px' }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="242:212"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 45, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="242:211"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 22.5 }}


                                        >
                                            <InView
                                                triggerOnce={triggerOnce}
                                                delay={revealDelay}

                                                onChange={(inView, entry) => {
                                                    updateVisibility("156:1993", inView)
                                                }}

                                                threshold={revealThreshold}

                                            >
                                                <span
                                                    id="156:1993"
                                                    className="hidden"


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 10, display: 'inline-block' }}



                                                >
                                                    Meet the Chef

                                                </span>
                                            </InView>
                                            <div

                                                style={{
                                                    width: 310, padding: 10
                                                }}  >



                                                <ScrollMenu
                                                    apiRef={chefHSref}
                                                    scrollContainerClassName="scrollmenu"


                                                >

                                                    {cheflist.map((item, index) => {

                                                        return <div style={{
                                                            marginRight: 10
                                                        }}  >

                                                            <div>
                                                                <div
                                                                    id="242:209"
                                                                    className=""

                                                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 10, "marginBottom": 0, "cursor": "default" }}


                                                                >

                                                                    <span
                                                                        id="156:1994"
                                                                        className=""


                                                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15, "cursor": "default" }}



                                                                    >
                                                                        {item.name}

                                                                    </span><div>
                                                                        <div
                                                                            id="156:1996"
                                                                            className=""

                                                                            style={{ "height": 280, "width": 247, "borderRadius": null, "backgroundImage": `url(${item.image})`, "backgroundSize": 'cover', "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 15, "marginBottom": 15, "cursor": "default", marginLeft: '-20px' }}


                                                                        >



                                                                        </div>
                                                                    </div><div>
                                                                        <div
                                                                            id="156:2000"
                                                                            className=""

                                                                            style={{ height: '250px', "width": 293, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 15, "cursor": "default" }}


                                                                        >

                                                                            <span
                                                                                id="156:1999"
                                                                                className=""


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "cursor": "default" }}



                                                                            >
                                                                                {item.desciption}

                                                                            </span>

                                                                        </div>
                                                                    </div><div>
                                                                        <div
                                                                            id="156:2019"
                                                                            className=""

                                                                            style={{ "width": 290, "borderRadius": 27, "backgroundColor": "rgba(231,231,231,1)", "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 15, "paddingRight": 5, "paddingTop": 5, "paddingBottom": 5, "marginTop": 15, "marginBottom": 0, "cursor": "default" }}


                                                                        >

                                                                            <span
                                                                                id="156:2003"
                                                                                className=""


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 5, "cursor": "default" }}



                                                                            >
                                                                                Siginature dishes

                                                                            </span><div>
                                                                                <div
                                                                                    id="156:2017"
                                                                                    className=""

                                                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0, "cursor": "default" }}


                                                                                >

                                                                                    <div>
                                                                                        <div
                                                                                            id="156:2008"
                                                                                            className=""

                                                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "cursor": "default" }}


                                                                                        >

                                                                                            <div>
                                                                                                <div
                                                                                                    id="156:2012"
                                                                                                    className=""

                                                                                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "cursor": "default" }}


                                                                                                >

                                                                                                    <div>
                                                                                                        <div
                                                                                                            id="156:2004"
                                                                                                            className=""

                                                                                                            style={{ "height": 97.1552734375, "width": 98, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/156-2015.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "cursor": "default" }}


                                                                                                        >



                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div><span
                                                                                                id="156:2007"
                                                                                                className=""


                                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "justified", "marginBottom": 0, "cursor": "default" }}



                                                                                            >
                                                                                                feugiat

                                                                                            </span>

                                                                                        </div>
                                                                                    </div><div>
                                                                                        <div
                                                                                            id="156:2010"
                                                                                            className=""

                                                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "cursor": "default" }}


                                                                                        >

                                                                                            <div>
                                                                                                <div
                                                                                                    id="156:2011"
                                                                                                    className=""

                                                                                                    style={{ "height": 93, "width": 94, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "cursor": "default" }}


                                                                                                >

                                                                                                    <div>
                                                                                                        <div
                                                                                                            id="156:2006"
                                                                                                            className=""

                                                                                                            style={{ "height": 89, "width": 88, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/156-2006.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "cursor": "default" }}


                                                                                                        >



                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div><span
                                                                                                id="156:2009"
                                                                                                className=""


                                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "justified", "marginBottom": 0, "cursor": "default" }}



                                                                                            >
                                                                                                feugiat

                                                                                            </span>

                                                                                        </div>
                                                                                    </div><div>
                                                                                        <div
                                                                                            id="156:2013"
                                                                                            className=""

                                                                                            style={{ "height": 120, "width": 103, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginRight": 0, "cursor": "default" }}


                                                                                        >

                                                                                            <div>
                                                                                                <div
                                                                                                    id="156:2014"
                                                                                                    className=""

                                                                                                    style={{ "height": 88, "width": 88, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "cursor": "default" }}


                                                                                                >

                                                                                                    <div>
                                                                                                        <div
                                                                                                            id="156:2015"
                                                                                                            className=""

                                                                                                            style={{ "height": 95, "width": 93, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/156-2015.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "cursor": "default" }}


                                                                                                        >



                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div><span
                                                                                                id="156:2016"
                                                                                                className=""


                                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "justified", "marginBottom": 0, "cursor": "default" }}



                                                                                            >
                                                                                                feugiat

                                                                                            </span>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    })}
                                                </ScrollMenu>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="156:2022"
                                            className=""

                                            style={{ "height": 48, "width": 297, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 22.5, "marginBottom": 0, "cursor": "pointer" }}


                                        >

                                            <span
                                                id="156:2021"
                                                className=""


                                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left" }}



                                            >
                                                Become a Chef

                                            </span>

                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="242:213"
                                    className=""

                                    style={{ "height": 738, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 200, "paddingBottom": 10, "marginRight": 0, marginLeft: '0px', marginRight: '50px' }}


                                >

                                    <div>
                                        <div
                                            id="156:1997"
                                            className=""
                                            onClick={() => { goToNext(chefHSref) }}
                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/156-1997.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "cursor": "pointer", marginLeft: '-30px' }}


                                        >




                                        </div>
                                        {/* <div style={{width:'65px', marginTop:'453px', backgroundColor: "rgba(231,231,231,1)", height:'170px', marginLeft:'-35px'}}>
                                                
                                            </div> */}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div id="Pertner with us">
                        <div
                            id="156:2030"
                            className=""

                            style={{ "width": 400, "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 50, "paddingBottom": 50 }}


                        >

                            <span
                                id="156:2024"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 20 }}



                            >
                                Is Cooking you Hoby

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("156:2025", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="156:2025"
                                        className="hidden"

                                        style={{ "height": 192, "width": 399, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/156-2025.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 20, "marginBottom": 20 }}


                                    >



                                    </div>
                                </InView>
                            </div><div>
                                <div
                                    id="156:2027"
                                    className=""

                                    style={{ "width": 311, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 20, "marginBottom": 20 }}


                                >
                                    <InView
                                        triggerOnce={triggerOnce}
                                        delay={revealDelay}

                                        onChange={(inView, entry) => {
                                            updateVisibility("156:2026", inView)
                                        }}

                                        threshold={revealThreshold}

                                    >
                                        <span
                                            id="156:2026"
                                            className="hidden"


                                            style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", display: 'inline-block' }}



                                        >
                                            {isCookingYourHobby}

                                        </span>
                                    </InView>
                                </div>
                            </div><div>
                                <div
                                    id="156:2029"
                                    className=""

                                    style={{ "height": 48, "width": 321, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 20, "marginBottom": 0, "cursor": "pointer" }}


                                >

                                    <span
                                        id="156:2028"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left" }}



                                    >
                                        Join Now

                                    </span>

                                </div>
                            </div>

                        </div>
                    </div><div id="Pre Order">
                        <div
                            id="156:2035"
                            className=""

                            style={{ "width": 400, "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 30, "paddingBottom": 60 }}


                        >

                            <span
                                id="156:2031"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 25 }}



                            >
                                Pre order

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("156:1993", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="156:2032"
                                        className=""

                                        style={{ "height": 192, "width": 235, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/156-2032.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 25, "marginBottom": 25 }}


                                    >



                                    </div>
                                </InView>
                            </div><div>
                                <div
                                    id="156:2034"
                                    className=""

                                    style={{ "width": 325, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                >
                                    <InView
                                        triggerOnce={triggerOnce}
                                        delay={revealDelay}

                                        onChange={(inView, entry) => {
                                            updateVisibility("156:2033", inView)
                                        }}

                                        threshold={revealThreshold}

                                    >
                                        <span
                                            id="156:2033"
                                            className="hidden"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", display: 'inline-block' }}



                                        >
                                            Would you like to surprise your special one with their favourite food? Not able to cook many items? Not able to plan your office work and party menu??<br /><br /> Here we are.... <br />We will let you pre-order your favourite food and plan for special day a week ahead.

                                        </span>
                                    </InView>
                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="156:2041"
                            className=""

                            style={{ "width": 400, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 50, "paddingBottom": 50 }}


                        >

                            <div id="About us">
                                <div
                                    id="156:2040"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                >

                                    <span
                                        id="156:2036"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15 }}



                                    >
                                        About

                                    </span><div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("156:2037", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="156:2037"
                                                className="hidden"

                                                style={{ "height": 212, "width": 144, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/156-2037.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 15, "marginBottom": 0 }}


                                            >



                                            </div>
                                        </InView>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="156:2039"
                                    className=""

                                    style={{ "width": 296, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                >

                                    <span
                                        id="156:2038"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                    >
                                        I am a foodie by heart and techie by profession. I love cooking and
                                        experimenting with new recipes. I also love to explore the world of food
                                        through my travels.
                                        <br></br>
                                        <br></br>
                                        I started this company because I wanted to bring the authentic homemade
                                        food to your door step. We are a team of passionate chefs who want to share
                                        their culinary skills with you.
                                    </span>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="156:2056"
                            className=""

                            style={{ "width": 380, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 30, "paddingBottom": 30 }}


                        >

                            <div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("156:2055", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="156:2055"
                                        className="hidden"

                                        style={{ "height": 633, "width": 340, "borderRadius": 56, "backgroundColor": "rgba(224,66,52,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                    >

                                        <span
                                            id="156:2042"
                                            className=""


                                            style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 25 }}



                                        >
                                            Why Chefs near by ?

                                        </span><div>
                                            <div
                                                id="156:2046"
                                                className=""

                                                style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 25 }}


                                            >

                                                <span
                                                    id="156:2043"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                >
                                                    Support local chefs

                                                </span><div>
                                                    <div
                                                        id="156:2045"
                                                        className=""

                                                        style={{ "height": 75, "width": 239, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                    >

                                                        <span
                                                            id="156:2044"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            Choose a local chef of your choice and help local chef and their business to grow.

                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div><div>
                                            <div
                                                id="156:2050"
                                                className=""

                                                style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 25 }}


                                            >

                                                <span
                                                    id="156:2047"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                >
                                                    Enjoy homemade food

                                                </span><div>
                                                    <div
                                                        id="156:2049"
                                                        className=""

                                                        style={{ "height": 75, "width": 239, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                    >

                                                        <span
                                                            id="156:2048"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            Enjoy authentic homemade food from different cuisines especially made for you at reasonable price.

                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div><div>
                                            <div
                                                id="156:2054"
                                                className=""

                                                style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                            >

                                                <span
                                                    id="156:2051"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                >
                                                    Get customized food

                                                </span><div>
                                                    <div
                                                        id="156:2053"
                                                        className=""

                                                        style={{ "width": 239, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 5, "marginBottom": 0 }}


                                                    >

                                                        <span
                                                            id="156:2052"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                        >
                                                            Make a wish and get the food delivered as per your choice.

                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="156:2075"
                            className=""

                            style={{ "width": 400, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 35, "paddingBottom": 65 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("156:2057", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="156:2057"
                                    className="hidden"


                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 25, display: 'inline-block' }}



                                >
                                    Our customer feedbacks

                                </span>
                            </InView>
                            <div>
                                <div
                                    id="242:217"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="156:2059"
                                            className=""
                                            onClick={() => { goToPrev(feedbackHSref) }}
                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/156-2059.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 15, "cursor": "pointer" }}


                                        >



                                        </div>
                                    </div><div

                                        style={{
                                            width: 290, padding: 10
                                        }}  >



                                        <ScrollMenu
                                            apiRef={feedbackHSref}
                                            scrollContainerClassName="scrollmenu"

                                        >

                                            {customerFeedback.map((item, index) => {

                                                return <div style={{
                                                    marginRight: 10
                                                }}  >

                                                    <div>
                                                        <div
                                                            id="242:216"
                                                            className=""

                                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 15, "marginRight": 15, "cursor": "default" }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="156:2071"
                                                                    className=""

                                                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 10, "cursor": "default" }}


                                                                >

                                                                    <span
                                                                        id="156:2058"
                                                                        className=""


                                                                        style={{ "fontFamily": "Playfair Display", "fontSize": 96, "fontWeight": 400, "color": "rgba(224,224,224,1)", "letterSpacing": 0, "lineHeight": "100px", "textAlign": "left", "marginTop": 0, "cursor": "default" }}



                                                                    >
                                                                        “

                                                                    </span><div>
                                                                        <div
                                                                            id="156:2061"
                                                                            className=""

                                                                            style={{ "width": 249, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginBottom": 0, "cursor": "default" }}


                                                                        >

                                                                            <span
                                                                                id="156:2060"
                                                                                className=""


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "cursor": "default" }}



                                                                            >
                                                                                {item.feedback}

                                                                            </span>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div><div>
                                                                <div
                                                                    id="156:2069"
                                                                    className=""

                                                                    style={{ "width": 249, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-end", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 8, "paddingBottom": 8, "marginTop": 10, "marginBottom": 0, "cursor": "default" }}


                                                                >

                                                                    <div>
                                                                        <div
                                                                            id="156:2065"
                                                                            className=""

                                                                            style={{ "height": 46, "width": 48, "borderRadius": null, "backgroundImage": `url(${item.profilePic})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 5, "cursor": "default" }}


                                                                        >



                                                                        </div>
                                                                    </div><div>
                                                                        <div
                                                                            id="156:2068"
                                                                            className=""

                                                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 2, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 5, "marginRight": 0, "cursor": "default" }}


                                                                        >

                                                                            <span
                                                                                id="156:2066"
                                                                                className=""


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "right", "marginTop": 0, "marginBottom": 1, "cursor": "default" }}



                                                                            >
                                                                                {item.name}

                                                                            </span><span
                                                                                id="156:2067"
                                                                                className=""


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "right", "marginTop": 1, "marginBottom": 0, "cursor": "default" }}



                                                                            >
                                                                                {item.place}

                                                                            </span>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            })}
                                        </ScrollMenu>
                                    </div><div>
                                        <div
                                            id="156:2062"
                                            className=""
                                            onClick={() => { goToNext(feedbackHSref) }}
                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/156-2062.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 15, "marginRight": 0, "cursor": "pointer" }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="157:2085"
                            className=""

                            style={{ "width": 400, "backgroundColor": "rgba(246,241,236,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 55, "paddingBottom": 55 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("156:2076", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="156:2076"
                                    className="hidden"


                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "center", "marginTop": 0, "marginBottom": 25, display: 'inline-block' }}



                                >
                                    Let it be social

                                </span>
                            </InView>
                            <div

                                style={{
                                    width: 380, padding: 10
                                }}  >



                                <ScrollMenu
                                    apiRef={socialHSref}
                                    scrollContainerClassName="scrollmenu"

                                >

                                    {socialList.map((item, index) => {

                                        return <div style={{
                                            // marginRight: 50
                                        }}  >

                                            <div>
                                                <div
                                                    id="156:2079"
                                                    className=""

                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 56, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 25, "cursor": "default" }}


                                                >

                                                    <div>
                                                        <div
                                                            id="156:2077"
                                                            className=""

                                                            style={{ "height": 197, "width": 200, "borderRadius": null, "backgroundImage": `url(${item.url})`, "backgroundSize": "cover", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 20, "cursor": "default" }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="156:2078"
                                                            className=""

                                                            style={{ "height": 197, "width": 58, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/156-2078.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 28, "marginRight": 0, "cursor": "default" }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    })}
                                </ScrollMenu>
                            </div><div>
                                <div
                                    id="157:2084"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 100, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                >

                                    <span
                                        id="157:2080"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 300, "color": "rgba(38,50,56,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginLeft": 0, "marginRight": 50 }}



                                    >
                                        #chefsnearby

                                    </span><div>
                                        <div
                                            id="157:2083"
                                            className=""

                                            style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 60, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 50, "marginRight": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="157:2081"
                                                    className=""
                                                    onClick={() => { goToPrev(socialHSref) }}
                                                    style={{ "height": 56, "width": 29, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2081.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 30, "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div><div>
                                                <div
                                                    id="157:2082"
                                                    className=""
                                                    onClick={() => { goToNext(socialHSref) }}
                                                    style={{ "height": 56, "width": 29, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2082.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 30, "marginRight": 0, "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="157:2101"
                            className=""

                            style={{ "width": 400, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 40, "paddingBottom": 40 }}


                        >

                            <div id="Contact us">
                                <div
                                    id="157:2100"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                >

                                    <span
                                        id="157:2086"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 5 }}



                                    >
                                        Contact us

                                    </span><div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("157:2087", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="157:2087"
                                                className="hidden"

                                                style={{ "height": 334, "width": 399, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2087.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 5, "marginBottom": 0 }}


                                            >



                                            </div>
                                        </InView>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="157:2099"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 35, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginBottom": 0 }}


                                >

                                    <input
                                        id="157:2089"

                                        className=""
                                        style={{ "height": 36, "width": 285, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 0, "marginBottom": 17.5, "cursor": "default", "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(0,0,0,1)", "fontWeight": 500, "textAlign": "left" }}

                                        onChange={(event) => { contactUsform("name", event.target.value) }}


                                        placeholder="Name"

                                    >

                                    </input><input
                                        id="157:2091"

                                        className=""
                                        style={{ "height": 36, "width": 285, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5, "cursor": "default", "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(0,0,0,1)", "fontWeight": 500, "textAlign": "left" }}


                                        onChange={(event) => { contactUsform("email", event.target.value) }}

                                        placeholder="Email"

                                    >

                                    </input><input
                                        id="157:2093"

                                        className=""
                                        style={{ "height": 36, "width": 285, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5, "cursor": "default", "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(0,0,0,1)", "fontWeight": 500, "textAlign": "left" }}

                                        onChange={(event) => { contactUsform("phone", event.target.value) }}


                                        placeholder="Phone"

                                    >

                                    </input> <textarea
                                        id="157:2095"

                                        className=""
                                        style={{ "height": 128, "width": 285, "borderRadius": 8, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 15, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5, "cursor": "default", "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(0,0,0,1)", "fontWeight": 500, "textAlign": "left" }}

                                        onChange={(event) => { contactUsform("message", event.target.value) }}


                                        placeholder="Message"

                                    >

                                    </textarea><div>
                                        <div
                                            id="157:2098"
                                            className=""
                                            onClick={() => { submitContactUs(); alertActive == true ? setAlert(true) : setAlert(false); sucessAlertActive == true ? setSucessAlert(true) : setSucessAlert(false) }}
                                            style={{ "height": 36, "width": 285, "borderRadius": 8, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 17.5, "marginBottom": 0, "cursor": "pointer" }}


                                        >

                                            <span
                                                id="157:2097"
                                                className=""


                                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "15px", "textAlign": "left" }}



                                            >
                                                SUBMIT

                                            </span>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="157:2127"
                            className=""

                            style={{ "width": 400, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 35, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 20, "paddingBottom": 80 }}


                        >

                            <div id="FAQ">
                                <div
                                    id="158:2163"
                                    className=""

                                    style={{ "height": 60, "width": 198, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 17.5 }}


                                >

                                    <span
                                        id="157:2102"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "center" }}



                                    >
                                        Frequently asked questions

                                    </span>

                                </div>
                            </div><div>

                                {
                                    frequentlyAskedQuestion.map((item, index) => {
                                        return <>
                                            <Accordion sx={{ boxShadow: 'none' }} style={{ "width": 319, "paddingLeft": 15, "paddingRight": 15, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}
                                                expanded={expanded === 'panel' + String(index)} onChange={handleChange('panel' + String(index))}>
                                                <AccordionSummary
                                                    expandIcon={<div style={{ "height": 17, "width": 23, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)" }}></div>}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <span

                                                        className=""


                                                        style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 7.5 }}

                                                    >
                                                        {item.question}

                                                    </span>
                                                    {/* <Typography sx={{ fontSize flexShrink: 0 }}>
                                                    What are the requirements for being a chef ?
                                                </Typography> */}

                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <p

                                                        className=""


                                                        style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "lineHeight": "30px", "marginLeft": 0, textAlign: 'left' }}



                                                    >
                                                        {item.answer}

                                                    </p>
                                                    {/* <Typography>
                                                    Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
                                                    Aliquam eget maximus est, id dignissim quam.
                                                </Typography> */}
                                                </AccordionDetails>
                                            </Accordion>
                                        </>
                                    })
                                }
                                {/* <div
                                    id="157:2105"
                                    className=""

                                    style={{ "height": 66, "width": 319, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 15, "paddingLeft": 15, "paddingRight": 15, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                >
                                    

                                    <span
                                        id="157:2103"
                                        className=""


                                        style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 7.5 }}



                                    >
                                        What are the requirements for being a chef ?

                                    </span><div>
                                        <div
                                            id="157:2104"
                                            className=""

                                            style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2104.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="157:2106"
                                    className=""

                                    style={{ "height": 82, "width": 319, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 15, "paddingLeft": 15, "paddingRight": 15, "paddingTop": 0, "paddingBottom": 0, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                >

                                    <span
                                        id="157:2107"
                                        className=""


                                        style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 7.5 }}



                                    >
                                        What are the requirements for being a chef ?

                                    </span><div>
                                        <div
                                            id="157:2108"
                                            className=""

                                            style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2104.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="157:2109"
                                    className=""

                                    style={{ "height": 82, "width": 319, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 15, "paddingLeft": 15, "paddingRight": 15, "paddingTop": 0, "paddingBottom": 0, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                >

                                    <span
                                        id="157:2110"
                                        className=""


                                        style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 7.5 }}



                                    >
                                        What are the requirements for being a chef ?

                                    </span><div>
                                        <div
                                            id="157:2111"
                                            className=""

                                            style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2104.png", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="157:2112"
                                    className=""

                                    style={{ "height": 82, "width": 319, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 15, "paddingLeft": 15, "paddingRight": 15, "paddingTop": 0, "paddingBottom": 0, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                >

                                    <span
                                        id="157:2113"
                                        className=""


                                        style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 7.5 }}



                                    >
                                        What are the requirements for being a chef ?

                                    </span><div>
                                        <div
                                            id="157:2114"
                                            className=""

                                            style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2104.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="157:2115"
                                    className=""

                                    style={{ "height": 82, "width": 319, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 15, "paddingLeft": 15, "paddingRight": 15, "paddingTop": 0, "paddingBottom": 0, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                >

                                    <span
                                        id="157:2116"
                                        className=""


                                        style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 7.5 }}



                                    >
                                        What are the requirements for being a chef ?

                                    </span><div>
                                        <div
                                            id="157:2117"
                                            className=""

                                            style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2104.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="157:2118"
                                    className=""

                                    style={{ "height": 82, "width": 319, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 15, "paddingLeft": 15, "paddingRight": 15, "paddingTop": 0, "paddingBottom": 0, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                >

                                    <span
                                        id="157:2119"
                                        className=""


                                        style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 7.5 }}



                                    >
                                        What are the requirements for being a chef ?

                                    </span><div>
                                        <div
                                            id="157:2120"
                                            className=""

                                            style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2104.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="157:2121"
                                    className=""

                                    style={{ "height": 82, "width": 319, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 15, "paddingLeft": 15, "paddingRight": 15, "paddingTop": 0, "paddingBottom": 0, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                >

                                    <span
                                        id="157:2122"
                                        className=""


                                        style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 7.5 }}



                                    >
                                        What are the requirements for being a chef ?

                                    </span><div>
                                        <div
                                            id="157:2123"
                                            className=""

                                            style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2104.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="157:2124"
                                    className=""

                                    style={{ "height": 82, "width": 319, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 15, "paddingLeft": 15, "paddingRight": 15, "paddingTop": 0, "paddingBottom": 0, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 0 }}


                                >

                                    <span
                                        id="157:2125"
                                        className=""


                                        style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 7.5 }}



                                    >
                                        What are the requirements for being a chef ?

                                    </span><div>
                                        <div
                                            id="157:2126"
                                            className=""

                                            style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2104.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div>

                                </div> */}
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="157:2159"
                            className=""

                            style={{ "width": 400, "backgroundColor": "rgba(184,50,38,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 100, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 80, "paddingBottom": 50 }}


                        >

                            <div>
                                <div
                                    id="157:2158"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 50 }}


                                >

                                    <div>
                                        <div
                                            id="157:2128"
                                            className=""

                                            style={{ "height": 149, "width": 101, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2128.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 20 }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="157:2155"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 40, "paddingTop": 0, "paddingBottom": 0, "marginTop": 20, "marginBottom": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="157:2135"
                                                    className=""

                                                    style={{ "width": 201, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 0 }}


                                                >
                                                    <div
                                                        style={{ cursor: 'pointer', "marginBottom": 20 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Favourite Dish")
                                                            var element = document.getElementById("Favourite Dish");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                        }}
                                                    >
                                                        <span
                                                            id="157:2129"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 0, "marginBottom": 20 }}



                                                        >
                                                            Favourite Dish

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginBottom": 20 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Super Chef")
                                                            var element = document.getElementById("Super Chef");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
                                                        }}
                                                    >
                                                        <span
                                                            id="157:2130"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 20 }}



                                                        >
                                                            Super Chef

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginBottom": 20 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Make a wish")
                                                            var element = document.getElementById("Make a wish");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
                                                        }}
                                                    >
                                                        <span
                                                            id="157:2131"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 20 }}



                                                        >
                                                            Make a wish

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginBottom": 20 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Pre Order")
                                                            var element = document.getElementById("Pre Order");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
                                                        }}
                                                    >
                                                        <span
                                                            id="157:2132"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 20 }}



                                                        >
                                                            Pre Order

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginBottom": 20 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("How it works")
                                                            var element = document.getElementById("How it works");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
                                                        }}
                                                    >
                                                        <span
                                                            id="157:2133"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 20 }}



                                                        >
                                                            How it works

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginBottom": 10 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("About us")
                                                            var element = document.getElementById("About us");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
                                                        }}
                                                    >
                                                        <span
                                                            id="157:2134"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 0 }}



                                                        >
                                                            About us

                                                        </span>
                                                    </div>
                                                </div>
                                            </div><div>
                                                <div
                                                    id="157:2141"
                                                    className=""

                                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 20, "marginBottom": 0 }}


                                                >
                                                    <div
                                                        style={{ cursor: 'pointer', "marginBottom": 20 }}
                                                        onClick={() => { navigateToTerms(); }}

                                                    >
                                                        <span
                                                            id="157:2136"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 0, "marginBottom": 10 }}



                                                        >
                                                            Terms & Conditions

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginBottom": 20, }}
                                                        onClick={() => { navigateToPrivacy(); }}
                                                    >
                                                        <span
                                                            id="157:2137"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                        >
                                                            Privacy Policy

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginBottom": 20 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Pertner with us")
                                                            var element = document.getElementById("Pertner with us");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
                                                        }}
                                                    >

                                                        <span
                                                            id="157:2138"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                        >
                                                            Partner with us

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginBottom": 20 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("FAQ")
                                                            var element = document.getElementById("FAQ");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
                                                        }}
                                                    >
                                                        <span
                                                            id="157:2139"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                        >
                                                            FAQ

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginBottom": 20 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Contact us")
                                                            var element = document.getElementById("Contact us");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
                                                        }}
                                                    >
                                                        <span
                                                            id="157:2140"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 0 }}



                                                        >
                                                            Contact us

                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="157:2157"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="157:2156"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 25, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                        >

                                            <span
                                                id="157:2142"
                                                className=""


                                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 12.5 }}



                                            >
                                                Connect with us

                                            </span><div>
                                                <div
                                                    id="157:2146"
                                                    className=""

                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 12.5, "marginBottom": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="157:2143"
                                                            className=""
                                                            onClick={() => { window.open("https://www.facebook.com/profile.php?id=100090737110736&mibextid=ZbWKwL"); }}
                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2143.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 10, "cursor": "pointer" }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="157:2144"
                                                            className=""
                                                            onClick={() => { window.open("https://twitter.com/chefs_nearby"); }}
                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2144.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 10, "cursor": "pointer" }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="157:2145"
                                                            className=""
                                                            onClick={() => { window.open("https://instagram.com/chefs.nearby?igshid=ZDdkNTZiNTM="); }}
                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view4/157-2145.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 0, "cursor": "pointer" }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="157:2149"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 25 }}


                                        >

                                            <div>
                                                <div
                                                    id="157:2147"
                                                    className=""

                                                    style={{ "height": 52, "width": 176, "borderRadius": null, "backgroundImage": `url(${Google_play})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 15, "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div><div style={{ backgroundColor: "#fff", height: 45, width: 120, marginTop: 15, position: 'relative' }}>
                                                <div
                                                    id="157:2148"
                                                    className=""

                                                    style={{
                                                        "height": 52, "width": 174, "borderRadius": null,
                                                        "backgroundImage": `url(${AppStore})`, "backgroundSize": "contain",
                                                        "backgroundRepeat": "no-repeat", "display": "flex",
                                                        "marginBottom": 0, "cursor": "pointer", position: "absolute", top: -5, marginLeft: -5
                                                    }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="157:2162"
                            className=""

                            style={{ "width": 380, "backgroundColor": "rgba(112,32,25,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 50, "paddingBottom": 50, "marginBottom": 0 }}


                        >

                            <div>
                                <div
                                    id="157:2161"
                                    className=""

                                    style={{ "width": 241, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                >

                                    <span
                                        id="157:2160"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "center" }}



                                    >
                                        By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy and Content Policies. <br />All trademarks are properties of their respective owners. 2021 © Chefs near by . All rights reserved

                                    </span>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            {
                alert == true ?
                    <div style={{ position: 'fixed', bottom: 80, width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Alert sx={{ width: '90%' }} onClose={() => { setAlert(false) }} variant="filled" severity="error">{alertmessage}</Alert>
                    </div>
                    :
                    null
            }
            {
                sucessAlert == true ?
                    <div style={{ position: 'fixed', bottom: 80, width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Alert sx={{ width: '90%' }} onClose={() => { setSucessAlert(false) }} variant="filled" severity="success">{successmessage}</Alert>
                    </div>
                    :
                    null
            }

        </>
    )
}

export default View4