import { alertActive, alertmessage, cheflist, contactUs, customerFeedback, frequentlyAskedQuestion, isCookingYourHobby, OrderFromOurKitchenSchedule, socialList, submitContactUs, successmessage, sucessAlertActive, superChefList, topNav, topProcessList } from "../DataLayer/data"
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useRef, useState } from "react";
import '../hover.css';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { InView, useInView } from 'react-intersection-observer';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert'
import AppStore from '../Image/App_store.png'
import Google_play from '../Image/Google_play.png'
import Instagram from '../Image/Instagram-Negative.png'
import Twitter from '../Image/Twitter-Negative.png'
import Facebook from '../Image/Vector.png'
import Cross from '../Image/close.png'
import { Routes, Route, useNavigate } from 'react-router-dom';

var revealEffectConfig = {
    // "173:145": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "173:153": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "173:360": [
    //     "animate__animated",
    //     "animate__rollIn"
    // ],
    // "173:172": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "173:187": [
    //     "animate__animated",
    //     "animate__fadeInBottomRight"
    // ],
    // "173:189": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "173:377": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "173:222": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "173:224": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "173:229": [
    //     "animate__animated",
    //     "animate__zoomIn"
    // ],
    // "173:235": [
    //     "animate__animated",
    //     "animate__flip"
    // ],
    // "173:239": [
    //     "animate__animated",
    //     "animate__rollIn"
    // ],
    // "173:254": [
    //     "animate__animated",
    //     "animate__fadeInLeft"
    // ],
    // "173:269": [
    //     "animate__animated",
    //     "animate__fadeInRight"
    // ],
    // "174:415": [
    //     "animate__animated",
    //     "animate__rollIn"
    // ]
}
const View3 = (props) => {

    const navigate = useNavigate();

    const navigateToPrivacy = () => {
        // 👇️ navigate to /contacts
        window.open(
            "/privacy", "_blank");
    };

    const navigateToTerms = () => {
        // 👇️ navigate to /contacts
        window.open(
            "/terms", "_blank");
    };

    const [active, setactive] = useState("Favourite Dish")
    const [drawer, setDrawer] = useState(false)
    const [triggerOnce, settriggerOnce] = useState(false)
    const [revealDelay, setrevealDelay] = useState(300)
    const [revealThreshold, setrevealThreshold] = useState(0)
    const [alert, setAlert] = useState(false)
    const [sucessAlert, setSucessAlert] = useState(false)

    const socialHSref = useRef()
    const feedbackHSref = useRef()
    const chefHSref = useRef()
    const superChefHSref = useRef()
    const kitchenScheduleHSref = useRef()
    const topProgressHSref = useRef()

    const [howKitchenWorkPopup, setHowKitchenWorkPopup] = useState(false)
    const [howMakeWishVid, setHowMakeWishvid] = useState(false)
    const [header, setHeader] = useState(true)

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const updateVisibility = (id, visible) => {
        var el = document.getElementById(id)
        // alert(visible)


        if (visible) {
            el.classList.add("animate__animated")

            el.classList.add(revealEffectConfig[id][1])
            el.classList.remove("hidden")

        } else {
            el.classList.remove("animate__animated")

            el.classList.remove(revealEffectConfig[id][1])
            el.classList.add("hidden")



        }
    }

    const goToNext = (scrollRef) => {
        var nextItem = scrollRef.current.getNextItem()
        // alert(JSON.stringify(nextItem))
        if (scrollRef.current.isLastItemVisible) {

            nextItem = scrollRef.current.getItemByIndex(0)
        }
        scrollRef.current.scrollToItem(
            nextItem,
            // OR if you not sure about id for first item
            // scrollRef.current.getItemById(scrollRef.current.items.toItems()[nextItem.index]),
            "smooth",
            "start"
        );
    }

    const goToPrev = (scrollRef) => {
        var prevItem = scrollRef.current.getPrevItem()

        scrollRef.current.scrollToItem(
            prevItem,
            // OR if you not sure about id for first item
            // scrollRef.current.getItemById(scrollRef.current.items.toItems()[nextItem.index]),
            "smooth",
            "start"
        );
    }

    const contactUsform = (id, val) => {
        contactUs[id] = val
    }

    const HeaderAction = (val) => {
        setHeader(val)

    }


    return (
        <>

            <div>
                <div
                    id="173:123"
                    className=""

                    style={{ "height": 12943, "width": 625, "backgroundColor": "rgba(255,255,255,1)", "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                >

                    <div>
                        <div
                            id="173:356"
                            className=""

                            style={{
                                "width": 625, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 160, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 30, "paddingBottom": 30, "marginTop": 0,
                                position: header ? 'fixed' : null, backgroundColor: "#fff", zIndex: drawer ? 0 : 100000, borderBottom: '2px solid #ccc',
                            }}


                        >

                            <div>
                                <div
                                    id="173:125"
                                    className=""

                                    style={{ "height": 66.5390625, "width": 173, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-125.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 80 }}


                                >



                                </div>
                            </div><div>
                                <div
                                    id="173:355"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 60, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 80, "marginRight": 0 }}


                                >

                                    <div>
                                        <div
                                            id="173:354"
                                            className=""

                                            style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 15, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 30 }}


                                        >

                                            <div>
                                                <div
                                                    id="173:127"
                                                    className=""
                                                    onClick={() => { window.open("https://www.facebook.com/profile.php?id=100090737110736&mibextid=ZbWKwL"); }}
                                                    style={{ "height": 28, "width": 28, "borderRadius": null, "backgroundImage": `url(${Facebook})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 7.5, "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div><div>
                                                <div
                                                    id="173:128"
                                                    className=""
                                                    onClick={() => { window.open("https://twitter.com/chefs_nearby"); }}
                                                    style={{ "height": 28, "width": 28, "borderRadius": null, "backgroundImage": `url(${Twitter})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 7.5, "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div><div>
                                                <div
                                                    id="173:129"
                                                    className=""
                                                    onClick={() => { window.open("https://instagram.com/chefs.nearby?igshid=ZDdkNTZiNTM="); }}
                                                    style={{ "height": 28, "width": 28, "borderRadius": null, "backgroundImage": `url(${Instagram})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 7.5, "marginRight": 0, "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="173:130"
                                            className=""
                                            onClick={() => { setDrawer(true) }}
                                            style={{ "height": 44, "width": 44, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-130.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 30, "marginRight": 0, "cursor": "pointer" }}


                                        >



                                        </div>
                                        <Drawer
                                            anchor={'right'}
                                            open={drawer}
                                            onClose={() => { setDrawer(false) }}
                                        >
                                            <div>
                                                <Box
                                                    sx={{ width: 250 }}
                                                    role="presentation"
                                                // onClick={props?.item?.toggleDrawer}
                                                // onKeyDown={props?.item?.toggleDrawer}
                                                >
                                                    <div style={{ textAlign: "center" }}>
                                                        <img style={{ width: 150, height: 60, marginTop: 20, marginBottom: 20 }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-125.png'} />
                                                    </div>
                                                    <Divider />
                                                    <List>
                                                        {topNav.map((item, index) => (
                                                            <div
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    // alert(percent)
                                                                    setactive(item)
                                                                    var element = document.getElementById(item);
                                                                    var headerOffset = 180;
                                                                    var elementPosition = element.getBoundingClientRect().top;
                                                                    elementPosition = elementPosition * props.percent / 100
                                                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                                    // offsetPosition = offsetPosition * percent / 100
                                                                    // alert(elementPosition)
                                                                    window.scrollTo({
                                                                        top: offsetPosition,
                                                                        behavior: "smooth"
                                                                    });
                                                                    // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
                                                                    setDrawer(false)
                                                                }}
                                                            >
                                                                <a style={{ textDecoration: "none", color: "#000", fontWeight: "bold" }}>
                                                                    <ListItem button key={item}>
                                                                        <ListItemText primary={item} />
                                                                    </ListItem>
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </List>

                                                </Box>
                                            </div>
                                        </Drawer>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div >
                        <div
                            id="173:131"
                            className=""

                            style={{ "width": 625, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 40, marginTop: '150px' }}


                        >

                            <div>
                                <div
                                    id="173:132"
                                    className=""

                                    style={{ "height": 50, "width": 140, "borderRadius": null, "backgroundImage": `url(${Google_play})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 10, "cursor": "pointer" }}


                                >



                                </div>
                            </div><div >
                                <div
                                    id="173:133"
                                    className=""

                                    style={{ "height": 50, "width": 140, "borderRadius": null, "backgroundImage": `url(${AppStore})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 0, "cursor": "pointer" }}


                                >



                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="231:196"
                            className=""

                            style={{ "height": 313, "width": 625, "backgroundColor": "rgba(224,66,52,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                        >

                            <div
                                id="Favourite Dish"
                            >
                                <div
                                    id="231:195"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 10 }}


                                >

                                    <div

                                        style={{
                                            width: 550, padding: 0, display: 'flex', alignItems: 'center'
                                        }}  >



                                        {/* <ScrollMenu
                                            apiRef={topProgressHSref}
                                            scrollContainerClassName="scrollmenu"


                                        > */}

                                        {topProcessList.map((item, index) => {

                                            return <div style={{
                                                // marginRight: 50
                                            }}  >

                                                <div style={{ display: 'flex' }}>
                                                    <div
                                                        id="231:194"
                                                        className=""

                                                        style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 15, "cursor": "default" }}


                                                    >

                                                        <div >
                                                            <div
                                                                id="173:136"
                                                                className=""

                                                                style={{ "height": 148, "width": 148, "borderRadius": null, "backgroundImage": `url(${item.image})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 5, "cursor": "default" }}


                                                            >



                                                            </div>
                                                        </div><span
                                                            id="173:138"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0, "cursor": "default" }}



                                                        >
                                                            {item.text}

                                                        </span>

                                                    </div>
                                                    {
                                                        index == 2 ? null :

                                                            <div>
                                                                <div
                                                                    id="173:137"
                                                                    className=""

                                                                    style={{ "height": 30, "width": 18, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-137.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 10, "cursor": "pointer", marginTop: 70 }}


                                                                >



                                                                </div>
                                                            </div>
                                                    }
                                                </div>

                                            </div>

                                        })}
                                        {/* </ScrollMenu> */}
                                    </div>

                                </div>
                            </div><div>
                                {/* <div
                                    id="173:139"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 10, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="173:140"
                                            className=""

                                            style={{ "height": 17, "width": 17, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-140.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 10 }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="173:141"
                                            className=""

                                            style={{ "height": 17, "width": 17, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-141.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 10 }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="173:142"
                                            className=""

                                            style={{ "height": 17, "width": 17, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-142.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 0 }}


                                        >



                                        </div>
                                    </div>

                                </div> */}
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="173:143"
                            className=""

                            style={{ "width": 625, "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 35, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 40, "paddingBottom": 60 }}


                        >

                            <div>
                                <div
                                    id="173:144"
                                    className=""

                                    style={{ "width": 277, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 17.5 }}


                                >
                                    <InView
                                        triggerOnce={triggerOnce}
                                        delay={revealDelay}

                                        onChange={(inView, entry) => {
                                            updateVisibility("173:145", inView)
                                        }}

                                        threshold={revealThreshold}

                                    >
                                        <span
                                            id="173:145"
                                            className="hidden"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "26.5px", "textAlign": "center", display: 'inline-block' }}



                                        >
                                            Order from Our Kitchen schedule

                                        </span>
                                    </InView>
                                </div>
                            </div><div>
                                <div
                                    id="235:193"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 17.5, "marginBottom": 0 }}


                                >
                                    <div>
                                        <div
                                            id="161:400"
                                            className=""
                                            onClick={() => { goToPrev(kitchenScheduleHSref) }}
                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-168.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 15 }}


                                        >



                                        </div>
                                    </div>

                                    <div

                                        style={{
                                            width: 500, padding: 10
                                        }}  >



                                        <ScrollMenu
                                            apiRef={kitchenScheduleHSref}
                                            scrollContainerClassName="scrollmenu"


                                        >

                                            {OrderFromOurKitchenSchedule.map((item, index) => {

                                                return <div style={{
                                                    marginRight: 20, marginLeft: '-15px'
                                                }}  >

                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div
                                                            id="235:192"
                                                            className=""

                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "cursor": "default", marginLeft: 0 }}


                                                        >

                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div
                                                                    id="173:147"
                                                                    className=""

                                                                    style={{ "height": 241, "width": 220, "borderRadius": null, "backgroundImage": `url(${item.image})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 0, "cursor": "default", marginLeft: '0px' }}


                                                                >



                                                                </div>

                                                            </div>

                                                            <div>
                                                                <div
                                                                    id="173:358"
                                                                    className=""

                                                                    style={{ "width": 220, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 0, "cursor": "default" }}


                                                                >

                                                                    <span
                                                                        id="173:150"
                                                                        className=""


                                                                        style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 600, "color": "rgba(74,74,74,1)", "letterSpacing": 0, "lineHeight": "34px", "textAlign": "center", "cursor": "default" }}



                                                                    >
                                                                        {item.desc}

                                                                    </span>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        {
                                                            index == 0 && <div
                                                                id="90:111"



                                                                style={{ "height": 44, "width": 94, marginLeft: '-15px', "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-111.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginRight": -6, marginBottom: '100px' }}


                                                            >



                                                            </div>
                                                        }
                                                        {
                                                            index == 1 && <div
                                                                id="90:111"



                                                                style={{ "height": 44, "width": 94, marginLeft: '-15px', "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-116.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginRight": -6, marginBottom: '100px' }}


                                                            >



                                                            </div>
                                                        }
                                                        {
                                                            index == 2 && <div
                                                                id="90:111"



                                                                style={{ "height": 44, "width": 94, marginLeft: '-15px', "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/90-121.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginRight": -6, marginBottom: '100px' }}


                                                            >



                                                            </div>
                                                        }


                                                    </div>

                                                </div>

                                            })}
                                        </ScrollMenu>
                                    </div><div>
                                        <div
                                            id="173:148"
                                            className=""
                                            onClick={() => { goToNext(kitchenScheduleHSref) }}
                                            style={{ "height": 64, "width": 37, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-148.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginRight": 0, "cursor": "pointer" }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="173:151"
                            className=""

                            style={{ "width": 625, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 15, "paddingBottom": 40 }}


                        >

                            <div>
                                <div
                                    id="173:152"
                                    className=""

                                    style={{ "width": 333, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                >
                                    <InView
                                        triggerOnce={triggerOnce}
                                        delay={revealDelay}

                                        onChange={(inView, entry) => {
                                            updateVisibility("173:153", inView)
                                        }}

                                        threshold={revealThreshold}

                                    >
                                        <span
                                            id="173:153"
                                            className="hidden"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", display: 'inline-block' }}



                                        >
                                            How Kitchen schedule works

                                        </span>
                                    </InView>
                                </div>
                            </div>
                            <div style={{ "height": 330, "width": 570, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/kitchen+shedule.png)", display: 'flex', alignItems: 'center', justifyContent: 'center', objectFit: "fill", backgroundSize: 'cover' }}>
                                <div style={{}}
                                    onClick={() => { setHowKitchenWorkPopup(true); HeaderAction(false) }}
                                >
                                    <img style={{ height: 130, width: 130, cursor: 'pointer' }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/play+button.png'} />
                                </div>
                            </div>
                            {
                                howKitchenWorkPopup == true ?
                                    <div style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', top: 0, zIndex: 5000 }}>
                                        <div style={{ display: 'flex' }}>
                                            <video
                                                id="90:132"



                                                style={{
                                                    "height": 'auto', "width": 570,
                                                    "flexDirection": "column", "alignItems": "center", "justifyContent": "center",
                                                    "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10,
                                                    "paddingTop": 10, "paddingBottom": 10, "marginTop": 30, "marginBottom": 0, backgroundColor: 'grey', marginLeft: '-25px'
                                                }}
                                                autoPlay
                                                controls

                                            >


                                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/kitchenSchedule.mp4" type="video/mp4" />
                                                Your browser does not support HTML video.
                                                <div>
                                                    <div
                                                        id="90:131"



                                                        style={{ "height": 144, "width": 150, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/6620/f4ff/cad63dd24161ccfcd705204c8cf5309b?Expires=1667779200&Signature=AQCfaAEtxv0l0zQ99xQH8-wOd~SiRaSMkHVxj97Ye2Kg7nulZLfPkiMVVhtrzEYWVvvdzYrkNsSwqOLycIadd7PVAlovV55El~nhyRNbpKTeV5dn-DTnhATXXEGO~OphfB-Us0IXAIQkbxe40wURWlhFk16Wjlx2rwEVJrYxsYPaBdpZq3Zkc76FYarcoapaSBQybxYWDyiO7UOhwhSL5h0PN-ujSdtmyFsaPtcvZAj3hRr4JrDxxLIEW2a3JdNR2lFRXwk3Eo5pDraLShDDgXnY499Kf30ZNRHMirlgukKmi8dsEXXEMPKOvye4p9RnZ0R-E-dYW-i7GnlU4EL24g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                    >



                                                    </div>
                                                </div>

                                            </video>
                                            <div onClick={() => { setHowKitchenWorkPopup(false); HeaderAction(true) }} style={{ marginLeft: '-45px', marginTop: 15, cursor: 'pointer' }}>
                                                <img style={{ width: 20, height: 20 }} src={Cross} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {/* <video
                                id="173:154"

                                className=""




                                width="570"

                                controls>
                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/kitchenSchedule.mp4" type="video/mp4" />
                                Your browser does not support HTML video.
                            </video> */}

                        </div>
                    </div><div>
                        <div
                            id="173:363"
                            className=""

                            style={{ "width": 625, "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 40, "paddingBottom": 40 }}


                        >

                            <div id="Super Chef">
                                <div
                                    id="173:362"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                >

                                    <span
                                        id="173:157"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15 }}



                                    >
                                        Super Chef

                                    </span><div>
                                        <div
                                            id="173:361"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 0 }}


                                        >

                                            <div>
                                                <InView
                                                    triggerOnce={triggerOnce}
                                                    delay={revealDelay}

                                                    onChange={(inView, entry) => {
                                                        updateVisibility("173:360", inView)
                                                    }}

                                                    threshold={revealThreshold}

                                                >
                                                    <div
                                                        id="173:360"
                                                        className="hidden"

                                                        style={{ "height": 305, "width": 508, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-360.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 15 }}


                                                    >



                                                    </div>
                                                </InView>
                                            </div><div>
                                                <div
                                                    id="236:195"
                                                    className=""

                                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="173:162"
                                                            className=""
                                                            onClick={() => { goToPrev(superChefHSref) }}
                                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-162.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 40, "cursor": "pointer" }}


                                                        >



                                                        </div>
                                                    </div><div

                                                        style={{
                                                            width: 150, padding: 10
                                                        }}  >



                                                        <ScrollMenu
                                                            apiRef={superChefHSref}
                                                            scrollContainerClassName="scrollmenu"

                                                        >

                                                            {superChefList.map((item, index) => {

                                                                return <div style={{

                                                                }}  >

                                                                    <div>
                                                                        <div
                                                                            id="236:194"
                                                                            className=""

                                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "cursor": "default" }}


                                                                        >

                                                                            <div>
                                                                                <div
                                                                                    id="173:163"
                                                                                    className=""

                                                                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 5, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 10, "cursor": "default" }}


                                                                                >

                                                                                    <div>
                                                                                        <div
                                                                                            id="173:164"
                                                                                            className=""

                                                                                            style={{ "height": 165, "width": 150, "borderRadius": null, "backgroundImage": `url(${item.chefImg})`, "backgroundSize": "cover", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 2.5, "cursor": "default" }}


                                                                                        >



                                                                                        </div>
                                                                                    </div><span
                                                                                        id="173:165"
                                                                                        className=""


                                                                                        style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(123,119,119,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "justified", "marginTop": 2.5, "marginBottom": 0, "cursor": "default" }}



                                                                                    >
                                                                                        {item.name}

                                                                                    </span>

                                                                                </div>
                                                                            </div><div>
                                                                                <div
                                                                                    id="173:167"
                                                                                    className=""

                                                                                    style={{ "height": 150, "width": 150, "borderRadius": null, "backgroundImage": `url(${item.foodImage})`, "backgroundSize": "cover", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 10, "marginBottom": 0, "cursor": "default" }}


                                                                                >



                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            })}
                                                        </ScrollMenu>
                                                    </div><div>
                                                        <div
                                                            id="173:166"
                                                            className=""
                                                            onClick={() => { goToNext(superChefHSref) }}
                                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-166.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 40, "marginRight": 0, "cursor": "pointer" }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="173:168"
                                    className=""

                                    style={{ "height": 62, "width": 499, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 25, "marginBottom": 0, "cursor": "pointer" }}


                                >

                                    <span
                                        id="173:169"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25.5px", "textAlign": "center" }}



                                    >
                                        Download Our App Now

                                    </span>

                                </div>
                            </div>

                        </div>
                    </div><div id="Make a wish">
                        <div
                            id="173:170"
                            className=""

                            style={{ "width": 625, "backgroundColor": "rgba(244,244,244,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 40, "paddingBottom": 40 }}


                        >

                            <span
                                id="173:171"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15 }}



                            >
                                Make a wish

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("173:172", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="173:172"
                                        className="hidden"

                                        style={{ "height": 196, "width": 413, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 0 }}


                                    >

                                        <div>
                                            <div
                                                id="173:173"
                                                className=""

                                                style={{ "width": 413, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 10 }}


                                            >

                                                <div>
                                                    <div
                                                        id="173:364"
                                                        className=""

                                                        style={{ "width": 399, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                                    >

                                                        <span
                                                            id="173:175"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": 'left' }}



                                                        >
                                                            Have you been craving to eat your favourite dish from long??

                                                        </span>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="173:365"
                                                        className=""

                                                        style={{ "width": 211, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                                    >

                                                        <span
                                                            id="173:177"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": 'left' }}



                                                        >
                                                            You don’t know how to make it?

                                                        </span>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="173:366"
                                                        className=""

                                                        style={{ "width": 410, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginBottom": 0 }}


                                                    >

                                                        <span
                                                            id="173:179"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": 'left' }}



                                                        >
                                                            You are not getting the desired taste when you do it yourself??

                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div><div>
                                            <div
                                                id="173:180"
                                                className=""

                                                style={{ "width": 413, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 10, "marginBottom": 0 }}


                                            >

                                                <div>
                                                    <div
                                                        id="173:367"
                                                        className=""

                                                        style={{ "width": 306, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                                    >

                                                        <span
                                                            id="173:182"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": 'left' }}



                                                        >
                                                            Here is the solution to satisfy your tastebuds...

                                                        </span>

                                                    </div>
                                                </div><div>
                                                    <div
                                                        id="173:368"
                                                        className=""

                                                        style={{ "width": 406, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginBottom": 0 }}


                                                    >

                                                        <span
                                                            id="173:184"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": 'left' }}



                                                        >
                                                            You can make a wish and our expert chefs from our list would prepare it and deliver it to you.

                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="173:185"
                            className=""

                            style={{ "height": 1445, "width": 625, "backgroundColor": "rgba(244,244,244,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 20, "paddingBottom": 50 }}


                        >

                            <span
                                id="173:186"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 20 }}



                            >
                                Chef near by User Journey

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("173:187", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="173:187"
                                        className="hidden"

                                        style={{ "height": 1404.08203125, "width": 430, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-187.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 20, "marginBottom": 0 }}


                                    >



                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div id="How it works">
                        <div
                            id="173:188"
                            className=""

                            style={{ "width": 625, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 35, "paddingBottom": 70 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("173:189", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="173:189"
                                    className="hidden"


                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15, display: 'inline-block' }}



                                >
                                    How make a wish works

                                </span>
                            </InView>
                            <div style={{ "height": 330, "width": 570, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/make+a+wish.png)", display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundSize: 'cover' }}>
                                <div style={{}}
                                    onClick={() => { setHowMakeWishvid(true); HeaderAction(false) }}
                                >
                                    <img style={{ height: 130, width: 130, cursor: 'pointer' }} src={'https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view1/play+button.png'} />
                                </div>
                            </div>
                            {
                                howMakeWishVid == true ?
                                    <div style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', top: 0, zIndex: 5000 }}>
                                        <div style={{ display: 'flex' }}>
                                            <video
                                                id="90:132"



                                                style={{
                                                    "height": 330, "width": 570,
                                                    "flexDirection": "column", "alignItems": "center", "justifyContent": "center",
                                                    "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10,
                                                    "paddingTop": 10, "paddingBottom": 10, "marginTop": 30, "marginBottom": 0, backgroundColor: 'grey', marginLeft: '-20px'
                                                }}
                                                autoPlay
                                                controls

                                            >


                                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/makeAwish.mp4" type="video/mp4" />
                                                <div>
                                                    <div
                                                        id="90:131"



                                                        style={{ "height": 144, "width": 150, "borderRadius": null, "backgroundImage": "url(https://s3-alpha-sig.figma.com/img/6620/f4ff/cad63dd24161ccfcd705204c8cf5309b?Expires=1667779200&Signature=AQCfaAEtxv0l0zQ99xQH8-wOd~SiRaSMkHVxj97Ye2Kg7nulZLfPkiMVVhtrzEYWVvvdzYrkNsSwqOLycIadd7PVAlovV55El~nhyRNbpKTeV5dn-DTnhATXXEGO~OphfB-Us0IXAIQkbxe40wURWlhFk16Wjlx2rwEVJrYxsYPaBdpZq3Zkc76FYarcoapaSBQybxYWDyiO7UOhwhSL5h0PN-ujSdtmyFsaPtcvZAj3hRr4JrDxxLIEW2a3JdNR2lFRXwk3Eo5pDraLShDDgXnY499Kf30ZNRHMirlgukKmi8dsEXXEMPKOvye4p9RnZ0R-E-dYW-i7GnlU4EL24g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                                    >



                                                    </div>
                                                </div>

                                            </video>
                                            <div onClick={() => { setHowMakeWishvid(false); HeaderAction(true) }} style={{ marginLeft: '-45px', marginTop: 15, cursor: 'pointer' }}>
                                                <img style={{ width: 20, height: 20 }} src={Cross} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {/* <video
                                id="173:190"

                                className=""




                                width="605"

                                controls>
                                <source src="https://chefs-nearby.s3.eu-west-2.amazonaws.com/videos/makeAwish.mp4" type="video/mp4" />
                                Your browser does not support HTML video.
                            </video> */}

                        </div>
                    </div><div>
                        <div
                            id="244:221"
                            className=""

                            style={{ "width": 625, "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 40, "paddingBottom": 40 }}


                        >

                            <div>
                                <div
                                    id="244:219"
                                    className=""

                                    style={{ "height": 728, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 200, "paddingBottom": 10, "marginLeft": 0 }}


                                >

                                    <div>
                                        <div
                                            id="173:380"
                                            className=""
                                            onClick={() => { goToPrev(chefHSref) }}
                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-380.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex" }}


                                        >



                                        </div>
                                        {/* <div style={{height:'300', marginTop:'400'}}></div> */}
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="244:218"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                >
                                    <InView
                                        triggerOnce={triggerOnce}
                                        delay={revealDelay}

                                        onChange={(inView, entry) => {
                                            updateVisibility("173:377", inView)
                                        }}

                                        threshold={revealThreshold}

                                    >
                                        <span
                                            id="173:377"
                                            className="hidden"


                                            style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 10, display: "inline-block" }}



                                        >
                                            Meet the Chef

                                        </span>
                                    </InView>
                                    <div

                                        style={{
                                            width: 500, padding: 10
                                        }}  >



                                        <ScrollMenu
                                            apiRef={chefHSref}
                                            scrollContainerClassName="scrollmenu"

                                        >

                                            {cheflist.map((item, index) => {

                                                return <div style={{
                                                    marginLeft: 5
                                                }}  >

                                                    <div>
                                                        <div
                                                            id="241:199"
                                                            className=""

                                                            style={{ "width": 496, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 25, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 10, "marginBottom": 0, "cursor": "default" }}


                                                        >

                                                            <span
                                                                id="173:378"
                                                                className=""


                                                                style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 12.5, "cursor": "default" }}



                                                            >
                                                                {item.name}

                                                            </span><div>
                                                                <div
                                                                    id="173:381"
                                                                    className=""

                                                                    style={{ "height": 280, "width": 247, "borderRadius": null, "backgroundImage": `url(${item.image})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 12.5, "marginBottom": 12.5, "cursor": "default", "marginLeft": 50 }}


                                                                >



                                                                </div>
                                                            </div><div>
                                                                <div
                                                                    id="173:383"
                                                                    className=""

                                                                    style={{ "width": 484, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 12.5, "marginBottom": 12.5, "cursor": "default" }}


                                                                >

                                                                    <div>
                                                                        <div
                                                                            id="174:449"
                                                                            className=""

                                                                            style={{ "width": 480, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 20, "cursor": "default" }}


                                                                        >

                                                                            <span
                                                                                id="173:385"
                                                                                className=""


                                                                                style={{ height: '150px', "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": 'left', "cursor": "default" }}



                                                                            >
                                                                                {item.desciption}

                                                                            </span>

                                                                        </div>
                                                                    </div><div>
                                                                        <div
                                                                            id="173:386"
                                                                            className=""

                                                                            style={{ "height": 185, "width": 457, "borderRadius": 27, "backgroundColor": "rgba(231,231,231,1)", "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 25, "paddingLeft": 15, "paddingRight": 15, "paddingTop": 30, "paddingBottom": 30, "marginTop": 20, "marginBottom": 0, "cursor": "default" }}


                                                                        >

                                                                            <span
                                                                                id="173:387"
                                                                                className=""


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 12.5, "cursor": "default" }}



                                                                            >
                                                                                Siginature dishes

                                                                            </span><div>
                                                                                <div
                                                                                    id="173:388"
                                                                                    className=""

                                                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 12.5, "marginBottom": 0, "cursor": "default" }}


                                                                                >

                                                                                    <div>
                                                                                        <div
                                                                                            id="173:389"
                                                                                            className=""

                                                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "cursor": "default" }}


                                                                                        >

                                                                                            <div>
                                                                                                <div
                                                                                                    id="173:390"
                                                                                                    className=""

                                                                                                    style={{ "height": 152.671875, "width": 154, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-390.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "cursor": "default" }}


                                                                                                >



                                                                                                </div>
                                                                                            </div><span
                                                                                                id="173:391"
                                                                                                className=""


                                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "justified", "marginBottom": 0, "cursor": "default" }}



                                                                                            >
                                                                                                feugiat

                                                                                            </span>

                                                                                        </div>
                                                                                    </div><div>
                                                                                        <div
                                                                                            id="173:392"
                                                                                            className=""

                                                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "cursor": "default" }}


                                                                                        >

                                                                                            <div>
                                                                                                <div
                                                                                                    id="173:393"
                                                                                                    className=""

                                                                                                    style={{ "height": 152.671875, "width": 154, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-393.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "cursor": "default" }}


                                                                                                >



                                                                                                </div>
                                                                                            </div><span
                                                                                                id="173:394"
                                                                                                className=""


                                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "justified", "marginBottom": 0, "cursor": "default" }}



                                                                                            >
                                                                                                feugiat

                                                                                            </span>

                                                                                        </div>
                                                                                    </div><div>
                                                                                        <div
                                                                                            id="173:395"
                                                                                            className=""

                                                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginRight": 0, "cursor": "default" }}


                                                                                        >

                                                                                            <div>
                                                                                                <div
                                                                                                    id="173:396"
                                                                                                    className=""

                                                                                                    style={{ "height": 152.671875, "width": 154, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-396.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "cursor": "default" }}


                                                                                                >



                                                                                                </div>
                                                                                            </div><span
                                                                                                id="173:397"
                                                                                                className=""


                                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 12, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "justified", "marginBottom": 0, "cursor": "default" }}



                                                                                            >
                                                                                                feugiat

                                                                                            </span>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            {/* <div>
                                                                <div
                                                                    id="173:398"
                                                                    className=""

                                                                    style={{ "height": 48, "width": 475, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 12.5, "marginBottom": 0, "cursor": "pointer" }}


                                                                >

                                                                    <span
                                                                        id="173:399"
                                                                        className=""


                                                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "cursor": "default" }}



                                                                    >
                                                                        Become a Chef

                                                                    </span>

                                                                </div>
                                                            </div> */}

                                                        </div>
                                                    </div>

                                                </div>

                                            })}
                                        </ScrollMenu>
                                        <div style={{ marginLeft: '10px' }}>
                                            <div
                                                id="173:398"
                                                className=""

                                                style={{ "height": 48, "width": 475, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 12.5, "marginBottom": 0, "cursor": "pointer" }}


                                            >

                                                <span
                                                    id="173:399"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "cursor": "default" }}



                                                >
                                                    Become a Chef

                                                </span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="244:220"
                                    className=""

                                    style={{ "height": 728, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 200, "paddingBottom": 10, "marginRight": 0 }}


                                >

                                    <div>
                                        <div
                                            id="173:382"
                                            className=""
                                            onClick={() => { goToNext(chefHSref) }}
                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-382.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", }}


                                        >



                                        </div>
                                        {/* <div style={{height:'240px', marginTop:'355px', backgroundColor: "rgba(231,231,231,1)", width:'75px', marginLeft:'-35px'}}></div> */}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div id="Pertner with us">
                        <div
                            id="173:220"
                            className=""

                            style={{ "width": 625, "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 50, "paddingBottom": 50 }}


                        >

                            <span
                                id="173:221"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 25 }}



                            >
                                Is Cooking you Hoby

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("173:222", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="173:222"
                                        className="hidden"

                                        style={{ "height": 192, "width": 513, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-222.png)", "backgroundSize": 'cover', "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 25, "marginBottom": 25, }}


                                    >



                                    </div>
                                </InView>
                            </div><div>
                                <div
                                    id="174:448"
                                    className=""

                                    style={{ "width": 484, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 25 }}


                                >
                                    <InView
                                        triggerOnce={triggerOnce}
                                        delay={revealDelay}

                                        onChange={(inView, entry) => {
                                            updateVisibility("173:224", inView)
                                        }}

                                        threshold={revealThreshold}

                                    >
                                        <span
                                            id="173:224"
                                            className="hidden"


                                            style={{ "fontFamily": "Roboto", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": 'left', display: 'inline-block' }}



                                        >
                                            {isCookingYourHobby}

                                        </span>
                                    </InView>
                                </div>
                            </div><div>
                                <div
                                    id="173:225"
                                    className=""

                                    style={{ "height": 48, "width": 321, "borderRadius": 10, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 25, "marginBottom": 0, "cursor": "pointer" }}


                                >

                                    <span
                                        id="173:226"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left" }}



                                    >
                                        Join Now

                                    </span>

                                </div>
                            </div>

                        </div>
                    </div><div id="Pre Order">
                        <div
                            id="173:409"
                            className=""

                            style={{ "width": 625, "backgroundColor": "rgba(246,240,230,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 60, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 40, "paddingBottom": 40 }}


                        >

                            <span
                                id="173:228"
                                className=""


                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 30 }}



                            >
                                Pre order

                            </span><div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("173:229", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="173:229"
                                        className="hidden"

                                        style={{ "height": 192, "width": 235, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/1723-229.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 30, "marginBottom": 30 }}


                                    >



                                    </div>
                                </InView>
                            </div><div>
                                <div
                                    id="173:408"
                                    className=""

                                    style={{ "width": 512, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 30, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="173:404"
                                            className=""

                                            style={{ "width": 512, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 10 }}


                                        >

                                            <span
                                                id="173:401"
                                                className=""


                                                style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": 'left' }}



                                            >
                                                Would you like to surprise your special one with their favourite food? Not able to cook many items? Not able to plan your office work and party menu??

                                            </span>

                                        </div>
                                    </div><div>
                                        <div
                                            id="173:407"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 10, "marginBottom": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="173:405"
                                                    className=""

                                                    style={{ "width": 98, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                                >

                                                    <span
                                                        id="173:402"
                                                        className=""


                                                        style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                    >
                                                        Here we are....

                                                    </span>

                                                </div>
                                            </div><div>
                                                <div
                                                    id="173:406"
                                                    className=""

                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginBottom": 0 }}


                                                >

                                                    <span
                                                        id="173:403"
                                                        className=""


                                                        style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                                    >
                                                        We will let you pre-order your favourite food and plan for special day a week ahead.

                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="173:232"
                            className=""

                            style={{ "width": 625, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 50, "paddingBottom": 50 }}


                        >

                            <div id="About us">
                                <div
                                    id="173:233"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                >

                                    <span
                                        id="173:234"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 15 }}



                                    >
                                        About

                                    </span><div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("173:235", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="173:235"
                                                className="hidden"

                                                style={{ "height": 212, "width": 144, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-235.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 15, "marginBottom": 0 }}


                                            >



                                            </div>
                                        </InView>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="174:447"
                                    className=""

                                    style={{ "width": 506, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                >

                                    <span
                                        id="173:237"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left" }}



                                    >
                                        I am a foodie by heart and techie by profession. I love cooking and
                                        experimenting with new recipes. I also love to explore the world of food
                                        through my travels.
                                        <br></br>
                                        <br></br>
                                        I started this company because I wanted to bring the authentic homemade
                                        food to your door step. We are a team of passionate chefs who want to share
                                        their culinary skills with you.
                                    </span>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="173:238"
                            className=""

                            style={{ "width": 605, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 50, "paddingBottom": 100 }}


                        >

                            <div>
                                <InView
                                    triggerOnce={triggerOnce}
                                    delay={revealDelay}

                                    onChange={(inView, entry) => {
                                        updateVisibility("173:239", inView)
                                    }}

                                    threshold={revealThreshold}

                                >
                                    <div
                                        id="173:239"
                                        className="hidden"

                                        style={{ "height": 605, "width": 446, "borderRadius": 56, "backgroundColor": "rgba(224,66,52,1)", "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "center", "display": "flex", "itemSpacing": 50, "paddingLeft": 70, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                    >

                                        <span
                                            id="173:240"
                                            className=""


                                            style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 25 }}



                                        >
                                            Why Chefs near by ?

                                        </span><div>
                                            <div
                                                id="173:241"
                                                className=""

                                                style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 25 }}


                                            >

                                                <span
                                                    id="173:242"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                >
                                                    Support local chefs

                                                </span><span
                                                    id="173:244"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                                >
                                                    Choose a local chef of your choice and help local chef and their business to grow.

                                                </span>

                                            </div>
                                        </div><div>
                                            <div
                                                id="173:245"
                                                className=""

                                                style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 25 }}


                                            >

                                                <span
                                                    id="173:246"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                >
                                                    Enjoy homemade food

                                                </span><span
                                                    id="173:248"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                                >
                                                    Enjoy authentic homemade food from different cuisines especially made for you at reasonable price.

                                                </span>

                                            </div>
                                        </div><div>
                                            <div
                                                id="173:249"
                                                className=""

                                                style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 0 }}


                                            >

                                                <span
                                                    id="173:250"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "justified", "marginTop": 0, "marginBottom": 5 }}



                                                >
                                                    Get customized food

                                                </span><span
                                                    id="173:252"
                                                    className=""


                                                    style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 5, "marginBottom": 0 }}



                                                >
                                                    Make a wish and get the food delivered as per your choice.

                                                </span>

                                            </div>
                                        </div>

                                    </div>
                                </InView>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="173:253"
                            className=""

                            style={{ "width": 625, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 35, "paddingBottom": 85 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("173:254", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="173:254"
                                    className="hidden"


                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 20, display: 'inline-block' }}



                                >
                                    Our customer feedbacks

                                </span>
                            </InView>
                            <div>
                                <div
                                    id="237:197"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 80, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 20, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="173:257"
                                            className=""
                                            onClick={() => { goToPrev(feedbackHSref) }}
                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-257.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 40, "cursor": "pointer" }}


                                        >



                                        </div>
                                    </div><div

                                        style={{
                                            width: 430, padding: 10
                                        }}  >



                                        <ScrollMenu
                                            apiRef={feedbackHSref}
                                            scrollContainerClassName="scrollmenu"

                                        >

                                            {customerFeedback.map((item, index) => {

                                                return <div style={{
                                                    marginRight: 20
                                                }}  >

                                                    <div>
                                                        <div
                                                            id="237:196"
                                                            className=""

                                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 40, "marginRight": 40, "cursor": "default" }}


                                                        >

                                                            <div>
                                                                <div
                                                                    id="173:258"
                                                                    className=""

                                                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 20, "cursor": "default" }}


                                                                >

                                                                    <span
                                                                        id="173:259"
                                                                        className=""


                                                                        style={{ "fontFamily": "Playfair Display", "fontSize": 96, "fontWeight": 400, "color": "rgba(224,224,224,1)", "letterSpacing": 0, "lineHeight": "100px", "textAlign": "left", "marginTop": 0, "cursor": "default" }}



                                                                    >
                                                                        “

                                                                    </span><div>
                                                                        <div
                                                                            id="174:410"
                                                                            className=""

                                                                            style={{ "width": 349, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginBottom": 0, "cursor": "default" }}


                                                                        >

                                                                            <span
                                                                                id="173:261"
                                                                                className=""


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "cursor": "default" }}



                                                                            >
                                                                                {item.feedback}

                                                                            </span>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div><div>
                                                                <div
                                                                    id="173:263"
                                                                    className=""

                                                                    style={{ "width": 339, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-end", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 8, "paddingBottom": 8, "marginTop": 20, "marginBottom": 0, "cursor": "default" }}


                                                                >

                                                                    <div>
                                                                        <div
                                                                            id="173:264"
                                                                            className=""

                                                                            style={{ "height": 46, "width": 48, "borderRadius": null, "backgroundImage": `url(${item.profilePic})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 5, "cursor": "default" }}


                                                                        >



                                                                        </div>
                                                                    </div><div>
                                                                        <div
                                                                            id="173:265"
                                                                            className=""

                                                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 2, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 5, "marginRight": 0, "cursor": "default" }}


                                                                        >

                                                                            <span
                                                                                id="173:266"
                                                                                className=""


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 600, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "right", "marginTop": 0, "marginBottom": 1, "cursor": "default" }}



                                                                            >
                                                                                {item.name}

                                                                            </span><span
                                                                                id="173:267"
                                                                                className=""


                                                                                style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(37,37,37,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": "right", "marginTop": 1, "marginBottom": 0, "cursor": "default" }}



                                                                            >
                                                                                {item.place}

                                                                            </span>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            })}
                                        </ScrollMenu>
                                    </div><div>
                                        <div
                                            id="173:262"
                                            className=""
                                            onClick={() => { goToNext(feedbackHSref) }}
                                            style={{ "height": 57, "width": 27, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-262.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 40, "marginRight": 0, "cursor": "pointer" }}


                                        >



                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="174:414"
                            className=""

                            style={{ "width": 625, "backgroundColor": "rgba(246,241,236,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 60, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 60, "paddingBottom": 60 }}


                        >
                            <InView
                                triggerOnce={triggerOnce}
                                delay={revealDelay}

                                onChange={(inView, entry) => {
                                    updateVisibility("173:269", inView)
                                }}

                                threshold={revealThreshold}

                            >
                                <span
                                    id="173:269"
                                    className="hidden"


                                    style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "center", "marginTop": 0, "marginBottom": 30, display: 'inline-block' }}



                                >
                                    Let it be social

                                </span>
                            </InView>
                            <div

                                style={{
                                    width: 600, padding: 10
                                }}  >



                                <ScrollMenu
                                    apiRef={socialHSref}
                                    scrollContainerClassName="scrollmenu"

                                >

                                    {socialList.map((item, index) => {

                                        return <div style={{
                                            marginRight: 15
                                        }}  >

                                            <div>
                                                <div
                                                    id="174:413"
                                                    className=""

                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 50, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 30, "marginBottom": 30, "cursor": "default" }}


                                                >

                                                    <div>
                                                        <div
                                                            id="174:411"
                                                            className=""

                                                            style={{ "height": 241, "width": 314, "borderRadius": null, "backgroundImage": `url(${item.url})`, "backgroundSize": "cover", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 10, "cursor": "default" }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="174:412"
                                                            className=""

                                                            style={{ "height": 241, "width": 207, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/174-412.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 25, "marginRight": 0, "cursor": "default" }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    })}
                                </ScrollMenu>
                            </div><div>
                                <div
                                    id="173:273"
                                    className=""

                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 260, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 30, "marginBottom": 0 }}


                                >

                                    <span
                                        id="173:274"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 20, "fontWeight": 300, "color": "rgba(38,50,56,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginLeft": 0, "marginRight": 130 }}



                                    >
                                        #chefsnearby

                                    </span><div>
                                        <div
                                            id="173:275"
                                            className=""

                                            style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 60, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 130, "marginRight": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="173:276"
                                                    className=""
                                                    onClick={() => { goToPrev(socialHSref) }}
                                                    style={{ "height": 63.0859375, "width": 32.6700439453125, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-276.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 30, "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div><div>
                                                <div
                                                    id="173:277"
                                                    className=""
                                                    onClick={() => { goToNext(socialHSref) }}
                                                    style={{ "height": 63.0859375, "width": 32.6700439453125, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-277.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 30, "marginRight": 0, "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="174:418"
                            className=""

                            style={{ "width": 625, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 70, "paddingBottom": 70 }}


                        >

                            <div>
                                <div
                                    id="174:417"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 20 }}


                                >

                                    <div id="Contact us">
                                        <div
                                            id="173:279"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0 }}


                                        >

                                            <span
                                                id="173:280"
                                                className=""


                                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left" }}



                                            >
                                                Contact us

                                            </span>

                                        </div>
                                    </div><div>
                                        <InView
                                            triggerOnce={triggerOnce}
                                            delay={revealDelay}

                                            onChange={(inView, entry) => {
                                                updateVisibility("174:415", inView)
                                            }}

                                            threshold={revealThreshold}

                                        >
                                            <div
                                                id="174:415"
                                                className="hidden"

                                                style={{ "height": 334, "width": 501, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/174-415.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginBottom": 0 }}


                                            >



                                            </div>
                                        </InView>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="173:282"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 35, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 20, "marginBottom": 0 }}


                                >

                                    <input
                                        id="173:283"

                                        className=""
                                        style={{ "height": 36, "width": 502, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 0, "marginBottom": 17.5, "cursor": "default", "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(0,0,0,1)", "fontWeight": 500, "textAlign": "left" }}


                                        onChange={(event) => { contactUsform("name", event.target.value) }}

                                        placeholder="Name"

                                    >

                                    </input><input
                                        id="173:285"

                                        className=""
                                        style={{ "height": 36, "width": 502, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5, "cursor": "default", "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(0,0,0,1)", "fontWeight": 500, "textAlign": "left" }}


                                        onChange={(event) => { contactUsform("email", event.target.value) }}

                                        placeholder="Email"

                                    >

                                    </input><input
                                        id="173:287"

                                        className=""
                                        style={{ "height": 36, "width": 502, "borderRadius": 8, "flexDirection": "row", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5, "cursor": "default", "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(0,0,0,1)", "fontWeight": 500, "textAlign": "left" }}

                                        onChange={(event) => { contactUsform("phone", event.target.value) }}


                                        placeholder="Phone"

                                    >

                                    </input> <textarea
                                        id="173:289"
                                        onChange={(event) => { contactUsform("message", event.target.value) }}
                                        className=""
                                        style={{ "height": 128, "width": 502, "borderRadius": 8, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 15, "paddingBottom": 10, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5, "cursor": "default", "fontFamily": "Open Sans", "fontSize": 20, "color": "rgba(0,0,0,1)", "fontWeight": 500, "textAlign": "left" }}




                                        placeholder="Message"

                                    >

                                    </textarea><div>
                                        <div
                                            id="173:291"
                                            className=""
                                            onClick={() => { submitContactUs(); alertActive == true ? setAlert(true) : setAlert(false); sucessAlertActive == true ? setSucessAlert(true) : setSucessAlert(false) }}
                                            style={{ "height": 36, "width": 502, "borderRadius": 8, "backgroundColor": "rgba(232,80,91,1)", "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 10, "paddingBottom": 10, "marginTop": 17.5, "marginBottom": 0, "cursor": "pointer" }}


                                        >

                                            <span
                                                id="173:292"
                                                className=""


                                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 700, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "15px", "textAlign": "left" }}



                                            >
                                                SUBMIT

                                            </span>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="174:443"
                            className=""

                            style={{ "width": 625, "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 50, "paddingBottom": 50 }}


                        >

                            <div id="FAQ">
                                <div
                                    id="174:420"
                                    className=""

                                    style={{ "width": 212, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 15 }}


                                >

                                    <span
                                        id="173:295"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(232,80,91,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "center" }}



                                    >
                                        Frequently asked questions

                                    </span>

                                </div>
                            </div><div>
                                <div
                                    id="174:442"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 35, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 15, "marginBottom": 0 }}


                                >
                                    {
                                        frequentlyAskedQuestion.map((item, index) => {
                                            return <>
                                                <Accordion sx={{ boxShadow: 'none' }} style={{ "width": 482, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 15, "paddingBottom": 15, "borderColor": "rgba(143,143,143,1)", "borderWidth": 3, "borderStyle": "solid", "marginTop": 0, "marginBottom": 35 }}
                                                    expanded={expanded === 'panel' + String(index)} onChange={handleChange('panel' + String(index))}>
                                                    <AccordionSummary
                                                        expandIcon={<div style={{ "height": 17, "width": 23, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view2/161-304.png)" }}></div>}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                        <span

                                                            className=""


                                                            style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 20 }}

                                                        >
                                                            {item.question}

                                                        </span>


                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <p

                                                            className=""


                                                            style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "lineHeight": "30px", "marginLeft": 0, textAlign: 'left' }}



                                                        >
                                                            {item.answer}

                                                        </p>

                                                    </AccordionDetails>
                                                </Accordion>
                                            </>
                                        })
                                    }

                                    {/* <div>
                                        <div
                                            id="173:296"
                                            className=""

                                            style={{ "height": 66, "width": 482, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 40, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 0, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="173:297"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 20 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="173:298"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-298.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 20, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="174:421"
                                            className=""

                                            style={{ "height": 66, "width": 482, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 40, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="174:422"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 20 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="174:423"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-298.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 20, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="174:424"
                                            className=""

                                            style={{ "height": 66, "width": 482, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 40, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="174:425"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 20 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="174:426"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-298.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 20, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="174:427"
                                            className=""

                                            style={{ "height": 66, "width": 482, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 40, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="174:428"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 20 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="174:429"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-298.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 20, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="174:430"
                                            className=""

                                            style={{ "height": 66, "width": 482, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 40, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="174:431"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 20 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="174:432"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-298.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 20, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="174:433"
                                            className=""

                                            style={{ "height": 66, "width": 482, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 40, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="174:434"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 20 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="174:435"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-298.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 20, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="174:436"
                                            className=""

                                            style={{ "height": 66, "width": 482, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 40, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 17.5 }}


                                        >

                                            <span
                                                id="174:437"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 20 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="174:438"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-298.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 20, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="174:439"
                                            className=""

                                            style={{ "height": 66, "width": 482, "flexDirection": "row", "alignItems": "center", "justifyContent": "center", "display": "flex", "itemSpacing": 40, "paddingLeft": 20, "paddingRight": 20, "paddingTop": 8, "paddingBottom": 8, "borderColor": "rgba(143,143,143,1)", "borderWidth": 1, "borderStyle": "solid", "marginTop": 17.5, "marginBottom": 0 }}


                                        >

                                            <span
                                                id="174:440"
                                                className=""


                                                style={{ "fontFamily": "Montserrat", "fontSize": 18, "fontWeight": 400, "color": "rgba(0,0,0,1)", "letterSpacing": -0.27, "lineHeight": "30px", "textAlign": "left", "marginLeft": 0, "marginRight": 20 }}



                                            >
                                                What are the requirements for being a chef ?

                                            </span><div>
                                                <div
                                                    id="174:441"
                                                    className=""

                                                    style={{ "height": 17, "width": 23, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-298.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 20, "marginRight": 0 }}


                                                >



                                                </div>
                                            </div>

                                        </div> 
                                    </div>*/}

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="174:446"
                            className=""

                            style={{ "width": 625, "backgroundColor": "rgba(184,50,38,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 70, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 70, "paddingBottom": 70 }}


                        >

                            <div>
                                <div
                                    id="174:445"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 35 }}


                                >

                                    <div>
                                        <div
                                            id="173:322"
                                            className=""

                                            style={{ "height": 149, "width": 101, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-322.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 20 }}


                                        >



                                        </div>
                                    </div><div>
                                        <div
                                            id="173:323"
                                            className=""

                                            style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 40, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 20, "marginBottom": 0 }}


                                        >

                                            <div>
                                                <div
                                                    id="173:324"
                                                    className=""

                                                    style={{ "width": 201, "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 0, "marginRight": 20 }}


                                                >
                                                    <div
                                                        style={{ cursor: 'pointer', "marginTop": 10 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Favourite Dish")
                                                            var element = document.getElementById("Favourite Dish");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                        }}
                                                    >
                                                        <span
                                                            id="173:325"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 0, "marginBottom": 10 }}



                                                        >
                                                            Favourite Dish

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginTop": 10 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Super Chef")
                                                            var element = document.getElementById("Super Chef");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                        }}
                                                    >
                                                        <span
                                                            id="173:326"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                        >
                                                            Super Chef

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginTop": 10 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Make a wish")
                                                            var element = document.getElementById("Make a wish");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                        }}
                                                    >
                                                        <span
                                                            id="173:327"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                        >
                                                            Make a wish

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginTop": 10 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Pre Order")
                                                            var element = document.getElementById("Pre Order");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                        }}
                                                    >
                                                        <span
                                                            id="173:328"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                        >
                                                            Pre Order

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginTop": 10 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("How it works")
                                                            var element = document.getElementById("How it works");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                        }}
                                                    >
                                                        <span
                                                            id="173:329"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                        >
                                                            How it works

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginTop": 10 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("About us")
                                                            var element = document.getElementById("About us");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                        }}
                                                    >
                                                        <span
                                                            id="173:330"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 0 }}



                                                        >
                                                            About us

                                                        </span>
                                                    </div>
                                                </div>
                                            </div><div>
                                                <div
                                                    id="173:331"
                                                    className=""

                                                    style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginLeft": 20, "marginRight": 0 }}


                                                >
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => { navigateToTerms(); }}
                                                    >
                                                        <span
                                                            id="173:332"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 0, "marginBottom": 10 }}



                                                        >
                                                            Terms & Conditions

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            navigateToPrivacy();
                                                        }}
                                                    >
                                                        <span
                                                            id="173:333"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                        >
                                                            Privacy Policy

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Pertner with us")
                                                            var element = document.getElementById("Pertner with us");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                        }}
                                                    >
                                                        <span
                                                            id="173:334"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                        >
                                                            Partner with us

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginTop": 10 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("FAQ")
                                                            var element = document.getElementById("FAQ");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                        }}
                                                    >
                                                        <span
                                                            id="173:335"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 10 }}



                                                        >
                                                            FAQ

                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', "marginTop": 10 }}
                                                        onClick={() => {
                                                            // alert(percent)
                                                            setactive("Contact us")
                                                            var element = document.getElementById("Contact us");
                                                            var headerOffset = 180;
                                                            var elementPosition = element.getBoundingClientRect().top;
                                                            elementPosition = elementPosition * props.percent / 100
                                                            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                                            // offsetPosition = offsetPosition * percent / 100
                                                            // alert(elementPosition)
                                                            window.scrollTo({
                                                                top: offsetPosition,
                                                                behavior: "smooth"
                                                            });
                                                            // document.getElementById(item).scrollIntoView({ behavior: 'smooth' });

                                                        }}
                                                    >
                                                        <span
                                                            id="173:336"
                                                            className=""


                                                            style={{ "fontFamily": "Open Sans", "fontSize": 18, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "25px", "textAlign": "left", "marginTop": 10, "marginBottom": 0 }}



                                                        >
                                                            Contact us

                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div><div>
                                <div
                                    id="173:337"
                                    className=""

                                    style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 50, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 35, "marginBottom": 0 }}


                                >

                                    <div>
                                        <div
                                            id="173:338"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 25, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 0, "marginBottom": 25 }}


                                        >

                                            <span
                                                id="173:339"
                                                className=""


                                                style={{ "fontFamily": "Open Sans", "fontSize": 24, "fontWeight": 600, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "30px", "textAlign": "left", "marginTop": 0, "marginBottom": 12.5 }}



                                            >
                                                Connect with us

                                            </span><div>
                                                <div
                                                    id="173:340"
                                                    className=""

                                                    style={{ "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 20, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 12.5, "marginBottom": 0 }}


                                                >

                                                    <div>
                                                        <div
                                                            id="173:341"
                                                            className=""
                                                            onClick={() => { window.open("https://www.facebook.com/profile.php?id=100090737110736&mibextid=ZbWKwL"); }}
                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-341.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 0, "marginRight": 10, "cursor": "pointer" }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="173:342"
                                                            className=""
                                                            onClick={() => { window.open("https://twitter.com/chefs_nearby"); }}
                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-342.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 10, "cursor": "pointer" }}


                                                        >



                                                        </div>
                                                    </div><div>
                                                        <div
                                                            id="173:343"
                                                            className=""
                                                            onClick={() => { window.open("https://instagram.com/chefs.nearby?igshid=ZDdkNTZiNTM="); }}
                                                            style={{ "height": 31, "width": 31, "borderRadius": null, "backgroundImage": "url(https://chefs-nearby.s3.eu-west-2.amazonaws.com/cn_landing/view3/173-343.png)", "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginLeft": 10, "marginRight": 0, "cursor": "pointer" }}


                                                        >



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div><div>
                                        <div
                                            id="173:344"
                                            className=""

                                            style={{ "flexDirection": "column", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 30, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0, "marginTop": 25, "marginBottom": 25 }}


                                        >

                                            <div>
                                                <div
                                                    id="173:345"
                                                    className=""

                                                    style={{ "height": 52, "width": 176, "borderRadius": null, "backgroundImage": `url(${Google_play})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat", "display": "flex", "marginTop": 0, "marginBottom": 15, "cursor": "pointer" }}


                                                >



                                                </div>
                                            </div>
                                            <div style={{ backgroundColor: "#fff", height: 45, width: 120, marginTop: 15, position: 'relative' }}>
                                                <div
                                                    id="173:346"
                                                    className=""

                                                    style={{
                                                        "height": 52, "width": 174, "borderRadius": null,
                                                        "backgroundImage": `url(${AppStore})`, "backgroundSize": "contain",
                                                        "backgroundRepeat": "no-repeat", "display": "flex",
                                                        "marginBottom": 0, "cursor": "pointer", position: "absolute", top: -5, marginLeft: -5
                                                    }}


                                                >



                                                </div>
                                            </div>

                                        </div>
                                    </div><div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div><div>
                        <div
                            id="173:351"
                            className=""

                            style={{ "height": 84, "width": 605, "backgroundColor": "rgba(112,32,25,1)", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 10, "paddingRight": 10, "paddingTop": 50, "paddingBottom": 50, "marginBottom": 0 }}


                        >

                            <div>
                                <div
                                    id="174:444"
                                    className=""

                                    style={{ "width": 376, "flexDirection": "row", "alignItems": "flex-start", "justifyContent": "flex-start", "display": "flex", "itemSpacing": 10, "paddingLeft": 0, "paddingRight": 0, "paddingTop": 0, "paddingBottom": 0 }}


                                >

                                    <span
                                        id="173:353"
                                        className=""


                                        style={{ "fontFamily": "Open Sans", "fontSize": 14, "fontWeight": 400, "color": "rgba(255,255,255,1)", "letterSpacing": 0, "lineHeight": "20px", "textAlign": 'center' }}



                                    >
                                        By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy and Content Policies. All trademarks are properties of their respective owners. 2021 © Chefs near by . All rights reserved

                                    </span>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            {
                alert == true ?
                    <div style={{ position: 'fixed', bottom: 80, width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Alert sx={{ width: '90%' }} onClose={() => { setAlert(false) }} variant="filled" severity="error">{alertmessage}</Alert>
                    </div>
                    :
                    null
            }
            {
                sucessAlert == true ?
                    <div style={{ position: 'fixed', bottom: 80, width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Alert sx={{ width: '90%' }} onClose={() => { setSucessAlert(false); }} variant="filled" severity="success">{successmessage}</Alert>
                    </div>
                    :
                    null
            }

        </>
    )
}

export default View3