
import '../App.css';
import '../hover.css';
import View1 from '../Views/View1';
import View2 from '../Views/View2';
import View3 from '../Views/View3';
import { useEffect, useState } from 'react';
import View4 from '../Views/View4';

function Home() {
    const windowWidth = useWindowWide()
    const [percent, setpercent] = useState()
    useEffect(() => {
        const initialValue = document.body.style.zoom;

        var width = document.body.clientWidth

        var designWidth = 1440

        if (width >= 1000) {
            designWidth = 1440
        }

        if (width < 1000 && width >= 762) {
            designWidth = 900
        }
        if (width < 762 && width >= 512) {
            designWidth = 620
        }
        if (width < 512) {
            designWidth = 400
        }
        var percent = width * 100 / designWidth
        setpercent(percent)
        // alert(percent)
        // Change zoom level on mount
        document.body.style.zoom = percent + '%';

        return () => {
            // Restore default value
            document.body.style.zoom = initialValue;
        };
    }, [windowWidth]);
    useEffect(() => {
        // alert(windowWidth)
    }, [windowWidth])
    return (
        <div className="App">
            {/* <View1 /> */}
            {windowWidth >= 1000 && <View1 percent={percent} />}
            {windowWidth < 1000 && windowWidth >= 762 && <View2 percent={percent} />}
            {windowWidth < 762 && windowWidth >= 512 && <View3 percent={percent} />}
            {windowWidth < 512 && <View4 percent={percent} />}
        </div>
    );
}

export default Home;



export const useWindowWide = () => {
    const [width, setWidth] = useState(0)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)

        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [setWidth])

    return width
}